export default {
  isProd: Boolean(process.env.REACT_APP_IS_PROD) ?? false,
  baseUrl: process.env.REACT_APP_REQUEST_BASE_URL,
  vkApiId: process.env.REACT_APP_VK_API_ID,
  vkRedirectUrl: process.env.REACT_APP_VK_REDIRECT_URL,
  vkAuthUrl: process.env.REACT_APP_VK_AUTH_URL,
  socialVkUrl: process.env.REACT_APP_SOCIAL_VK_UPL,
  socialTelegramUrl: process.env.REACT_APP_SOCIAL_TELEGRAM_UPL,
  //
  soundUrl: process.env.REACT_APP_SOUND_URL,
  //
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY,
  pusherAppCluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  broadcastChatSupportChannel:
    process.env.REACT_APP_BROADCAST_CHAT_SUPPORT_CHANNEL,
  pusherHost: process.env.REACT_APP_PUSHER_HOST,
  pusherPort: process.env.REACT_APP_PUSHER_PORT,
  pusherAuthRout: process.env.REACT_APP_PUSHER_AUTH_ROUT,
};
