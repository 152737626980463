import React from "react";
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Typography,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import { TITLE_PAGE_CONTACTS } from "../../constants/titlesConstants";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { SocialIcon } from "react-social-icons";
import config from "../../config/config";

export default function ContactsPage() {
  const socialVkUrl = String(config.socialVkUrl);
  // const socialTelegramUrl = String(config.socialTelegramUrl);

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_CONTACTS} />
      </Container>
      <Container component="main" maxWidth="md">
        <Divider textAlign="left" sx={{ mt: "25px", mb: 1, fontSize: "18px" }}>
          {"Контакты"}
        </Divider>
        <Box sx={{ pl: 3 }}>
          <Box>{"info@easye.ru"}</Box>
        </Box>
        <Box sx={{ pl: 3, pt: 3 }}>
          {/* <SocialIcon
            network="vk"
            url={socialVkUrl}
            target="_blank"
            style={{ width: "30px", height: "30px", margin: "0 2px" }}
          /> */}
          {/* <SocialIcon
            network="telegram"
            url={socialTelegramUrl}
            target="_blank"
            style={{ width: "30px", height: "30px", margin: "0 2px" }}
          /> */}
        </Box>
        <Divider textAlign="left" sx={{ mt: 5, mb: 1, fontSize: 18 }}>
          {"Реквизиты"}
        </Divider>
        <Box sx={{ pl: 3 }}>
          <Box>{"ИП Гераськов Евгений Александрович"}</Box>
          <Box>{"ОГРНИП 320366800028340"}</Box>
          <Box>{"ИНН 510516020466 "}</Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
