import { createSlice } from "@reduxjs/toolkit";
import learningWord from "../../models/learningWord";

export interface wordFromTextStoreInterface {
  wordFromTextLoading: boolean;
  learningWordFromText: learningWord[];
}

export const initState: wordFromTextStoreInterface = {
  wordFromTextLoading: false,
  learningWordFromText: [],
};

export const wordFromTextReduceSlice = createSlice({
  name: "wordFromTextReduce",
  initialState: initState,
  reducers: {
    clearWordFromText: (state: wordFromTextStoreInterface) => {
      state.wordFromTextLoading = initState.wordFromTextLoading;
      state.learningWordFromText = initState.learningWordFromText;
    },
    setWordFromTextLoadingTrue: (state: wordFromTextStoreInterface) => {
      state.wordFromTextLoading = true;
    },
    setWordFromTextLoadingFalse: (state: wordFromTextStoreInterface) => {
      state.wordFromTextLoading = false;
    },
    setLearningWordFromText: (
      state: wordFromTextStoreInterface,
      action: { payload: learningWord[]; type: string },
    ) => {
      state.learningWordFromText = action.payload;
    },
  },
});

export const {
  clearWordFromText,
  setWordFromTextLoadingTrue,
  setWordFromTextLoadingFalse,
  setLearningWordFromText,
} = wordFromTextReduceSlice.actions;

export default wordFromTextReduceSlice.reducer;
