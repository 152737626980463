import React from "react";
import { Box } from "@mui/material";
import learningWordTranslate from "../../models/learningWordTranslate";

interface propsInterface {
  translates: learningWordTranslate[];
  selectValue: string | null;
  selectType: "good" | "error" | null;
}

const defaultProps: propsInterface = {
  translates: [],
  selectValue: null,
  selectType: null,
};

function TranslatesComponent(props: propsInterface) {
  const countTranslates = props.translates.length;

  function printTranslate(
    translate: string,
    isNotLast: boolean,
    index: number,
  ) {
    return (
      <Box component={"span"} key={index}>
        {props.selectType === "error" && translate === props.selectValue
          ? printTypeError(translate)
          : ""}
        {props.selectType === "error" && translate !== props.selectValue ? (
          <>{translate}</>
        ) : (
          ""
        )}
        {props.selectType === "good" && translate === props.selectValue
          ? printTypeGood(translate)
          : ""}
        {props.selectType === "good" && translate !== props.selectValue ? (
          <>{translate}</>
        ) : (
          ""
        )}
        {props.selectType === null ? <>{translate}</> : ""}
        {isNotLast === true ? ", " : ""}
      </Box>
    );
  }

  function printTypeError(translate: string) {
    return (
      <>
        <Box
          component={"span"}
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            backgroundColor: "#9c0000",
            color: "#fff",
            p: "0 5px",
          }}
        >
          {translate}
        </Box>
      </>
    );
  }

  function printTypeGood(translate: string) {
    return (
      <>
        <Box
          component={"span"}
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            backgroundColor: "#1b7505",
            color: "#fff",
            p: "0 5px",
          }}
        >
          {translate}
        </Box>
      </>
    );
  }

  return (
    <>
      {props.translates.map(
        (learningWordTranslate: learningWordTranslate, index: number) => {
          return printTranslate(
            learningWordTranslate.translate,
            countTranslates !== index + 1,
            index,
          );
        },
      )}
    </>
  );
}

TranslatesComponent.defaultProps = defaultProps;

export default TranslatesComponent;
