import axios, { responseInterface } from "../axios";
import { API_USER_SUBSCRIBE_DATA } from "../../constants/apiRoutConstants";
import userSubscribeDataResponse from "./userSubscribeDataResponse";
import {
  setCostDispatch,
  setEndPrimeTimeDispatch,
  setExtendToDispatch,
  setFreeAccessDispatch,
  setFreeWordCountDispatch,
  setPaymentsDispatch,
  setSubscribeAccessDispatch,
  setUserSubscribeDataLoadingFalseDispatch,
  setUserSubscribeDataLoadingTrueDispatch,
} from "../../redux/userSubscribeData/userSubscribeDataDispatcher";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import { setSubscribeCostDispatch } from "../../redux/app/appDispatcher";

export default function userSubscribeDataApi() {
  startRequest();

  axios
    .get(API_USER_SUBSCRIBE_DATA)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setUserSubscribeDataLoadingTrueDispatch();
};

const successful = (response: responseInterface<userSubscribeDataResponse>) => {
  setSubscribeCostDispatch(response.data.subscribeCost);
  setSubscribeAccessDispatch(response.data.subscribeAccess);
  setFreeAccessDispatch(response.data.freeAccess);
  setEndPrimeTimeDispatch(response.data.endPrimeTime);
  setFreeWordCountDispatch(response.data.freeWordCount);
  setCostDispatch(response.data.cost);
  setExtendToDispatch(response.data.extendTo);
  setPaymentsDispatch(response.data.payments);
  setUserSubscribeDataLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
