import {
  alertAppError,
  alertInTimeUpdate,
  alertSuccessUpdate,
} from "../../constants/alertConstants";
import { API_GROUP_PERSONAL_UPDATE } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import {
  alertSuccessDispatch,
  alertWarningDispatch,
} from "../../redux/alert/alertDispatcher";
import {
  setGroupPersonalUpdateCompleteDispatch,
  setGroupPersonalUpdateLoadingFalseDispatch,
  setGroupPersonalUpdateLoadingTrueDispatch,
} from "../../redux/groupPersonalUpdate/groupPersonalUpdateDispatcher";
import axios, { responseInterface } from "../axios";
import groupPersonalUpdateRequest from "./groupPersonalUpdateRequest";

export default function groupPersonalUpdateApi(
  data: groupPersonalUpdateRequest,
) {
  startRequest();

  axios
    .post(API_GROUP_PERSONAL_UPDATE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setGroupPersonalUpdateLoadingTrueDispatch();
  setGroupPersonalUpdateCompleteDispatch(null);
};

const successful = (response: responseInterface<null>) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertInTimeUpdate);
    setGroupPersonalUpdateCompleteDispatch(false);
    setGroupPersonalUpdateLoadingFalseDispatch();
    return;
  }

  setGroupPersonalUpdateCompleteDispatch(true);
  setGroupPersonalUpdateLoadingFalseDispatch();
  alertSuccessDispatch(alertSuccessUpdate);
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
