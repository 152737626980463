import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupInfo from "../../models/learningWordGroupInfo";

export interface groupInfoStoreInterface {
  learningWordGroupInfo: learningWordGroupInfo;
  learningWordGroupInfoLoading: boolean;
  learningWordGroupInfoAccess: boolean | null;
}

export const initGroupInfo: groupInfoStoreInterface = {
  learningWordGroupInfo: {
    user_learn: 0,
    count_word: 0,
  },
  learningWordGroupInfoLoading: true,
  learningWordGroupInfoAccess: null,
};

export const groupInfoReduceSlice = createSlice({
  name: "groupInfoReduce",
  initialState: initGroupInfo,
  reducers: {
    clearGroupInfo: (state: groupInfoStoreInterface) => {
      state.learningWordGroupInfo = {
        user_learn: 0,
        count_word: 0,
      };
      state.learningWordGroupInfoLoading = true;
      state.learningWordGroupInfoAccess = null;
    },
    setLearningWordGroupInfo: (
      state: groupInfoStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupInfo;
      },
    ) => {
      state.learningWordGroupInfo = action.payload;
    },
    setLearningWordGroupInfoLoadingTrue: (state: groupInfoStoreInterface) => {
      state.learningWordGroupInfoLoading = true;
    },
    setLearningWordGroupInfoLoadingFalse: (state: groupInfoStoreInterface) => {
      state.learningWordGroupInfoLoading = false;
    },
    setLearningWordGroupInfoAccessTrue: (state: groupInfoStoreInterface) => {
      state.learningWordGroupInfoAccess = true;
    },
    setLearningWordGroupInfoAccessFalse: (state: groupInfoStoreInterface) => {
      state.learningWordGroupInfoAccess = false;
    },
  },
});

export const {
  clearGroupInfo,
  setLearningWordGroupInfo,
  setLearningWordGroupInfoLoadingTrue,
  setLearningWordGroupInfoLoadingFalse,
  setLearningWordGroupInfoAccessTrue,
  setLearningWordGroupInfoAccessFalse,
} = groupInfoReduceSlice.actions;

export default groupInfoReduceSlice.reducer;
