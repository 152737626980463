import { signUpApi } from "../../api/signUp/signUpApi";
import signUpData from "../../api/signUp/signUpData";
import {
  isEmailIncorrectFormat,
  isEmailValueEmpty,
  isNameIncorrectFormat,
  isNameValueEmpty,
  isPasswordNotSame,
  isPasswordRepeatValueEmpty,
  isPasswordValueEmpty,
  isPrivacyPolicyCheckboxValueEmpty,
  isUserAgreementCheckboxValueEmpty,
} from "../formCheckService/formCheckService";
import { clearFormError } from "../formErrors/formErrorsService";

function isEmpty(
  name: string,
  email: string,
  password: string,
  passwordRepeat: string,
  userAgreementCheckbox: boolean,
  privacyPolicyCheckbox: boolean,
) {
  const nameEmpty = isNameValueEmpty(name);
  const emailEmpty = isEmailValueEmpty(email);
  const passwordEmpty = isPasswordValueEmpty(password);
  const passwordRepeatEmpty = isPasswordRepeatValueEmpty(passwordRepeat);
  const userAgreementCheckboxEmpty = isUserAgreementCheckboxValueEmpty(
    userAgreementCheckbox,
  );
  const privatePolicyCheckboxEmpty = isPrivacyPolicyCheckboxValueEmpty(
    privacyPolicyCheckbox,
  );

  return (
    nameEmpty ||
    emailEmpty ||
    passwordEmpty ||
    passwordRepeatEmpty ||
    userAgreementCheckboxEmpty ||
    privatePolicyCheckboxEmpty
  );
}

function isIncorrectFormat(name: string, email: string) {
  const nameIncorrectFormat = isNameIncorrectFormat(name);
  const emailIncorrectFormat = isEmailIncorrectFormat(email);

  return nameIncorrectFormat || emailIncorrectFormat;
}

export function signUpHandle(
  name: string,
  email: string,
  password: string,
  passwordRepeat: string,
  userAgreementCheckbox: boolean,
  privacyPolicyCheckbox: boolean,
  emailSubscribe: boolean,
) {
  clearFormError();

  if (
    isEmpty(
      name,
      email,
      password,
      passwordRepeat,
      userAgreementCheckbox,
      privacyPolicyCheckbox,
    )
  ) {
    return;
  }

  if (isIncorrectFormat(name, email)) {
    return;
  }

  if (isPasswordNotSame(password, passwordRepeat)) {
    return;
  }

  const data = signUpData(name, email, password, emailSubscribe);

  signUpApi(data);
}
