import groupPersonalUpdateRequest from "./groupPersonalUpdateRequest";

export default function groupPersonalUpdateData(
  learningWordGroupPersonalId: number,
  title: string,
  learningWordIds: number[],
): groupPersonalUpdateRequest {
  return {
    learningWordGroupPersonalId: learningWordGroupPersonalId,
    title: title,
    learningWordIds: learningWordIds,
  };
}
