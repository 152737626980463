import { storeInterface } from "../store";

const newLearningWord = (state: storeInterface) =>
  state.learnCheck.newLearningWord;
const learnCheckLoading = (state: storeInterface) =>
  state.learnCheck.learnCheckLoading;
const learnCheckAccess = (state: storeInterface) =>
  state.learnCheck.learnCheckAccess;
const success = (state: storeInterface) => state.learnCheck.success;
const complete = (state: storeInterface) => state.learnCheck.complete;
const progress = (state: storeInterface) => state.learnCheck.progress;

export default {
  newLearningWord,
  learnCheckLoading,
  learnCheckAccess,
  success,
  complete,
  progress,
};
