import { createSlice } from "@reduxjs/toolkit";
import chooseValueWord from "../../models/chooseValueWord";

export interface learnGroupPersonalNextStoreInterface {
  learnGroupPersonalNext: chooseValueWord | null;
  learnGroupPersonalNextLoading: boolean;
  learnGroupPersonalNextAccess: boolean;
}

const initStore: learnGroupPersonalNextStoreInterface = {
  learnGroupPersonalNext: null,
  learnGroupPersonalNextLoading: true,
  learnGroupPersonalNextAccess: true,
};

export const learnGroupPersonalNextReduceSlice = createSlice({
  name: "learnGroupPersonalNextReduce",
  initialState: initStore,
  reducers: {
    clearLearnGroupPersonalNext: (
      state: learnGroupPersonalNextStoreInterface,
    ) => {
      state.learnGroupPersonalNext = initStore.learnGroupPersonalNext;
      state.learnGroupPersonalNextLoading =
        initStore.learnGroupPersonalNextLoading;
      state.learnGroupPersonalNextAccess =
        initStore.learnGroupPersonalNextAccess;
    },
    setLearnGroupPersonalNextAccessTrue: (
      state: learnGroupPersonalNextStoreInterface,
    ) => {
      state.learnGroupPersonalNextAccess = true;
    },
    setLearnGroupPersonalNextAccessFalse: (
      state: learnGroupPersonalNextStoreInterface,
    ) => {
      state.learnGroupPersonalNextAccess = false;
    },
    setLearnGroupPersonalNext: (
      state: learnGroupPersonalNextStoreInterface,
      actions: { payload: chooseValueWord; type: string },
    ) => {
      state.learnGroupPersonalNext = actions.payload;
    },
    setLearnGroupPersonalNextLoadingTrue: (
      state: learnGroupPersonalNextStoreInterface,
    ) => {
      state.learnGroupPersonalNextLoading = true;
    },
    setLearnGroupPersonalNextLoadingFalse: (
      state: learnGroupPersonalNextStoreInterface,
    ) => {
      state.learnGroupPersonalNextLoading = false;
    },
  },
});

export const {
  clearLearnGroupPersonalNext,
  setLearnGroupPersonalNextAccessTrue,
  setLearnGroupPersonalNextAccessFalse,
  setLearnGroupPersonalNext,
  setLearnGroupPersonalNextLoadingTrue,
  setLearnGroupPersonalNextLoadingFalse,
} = learnGroupPersonalNextReduceSlice.actions;

export default learnGroupPersonalNextReduceSlice.reducer;
