import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import { setToken } from "../../redux/app/appReduce";
import signInRequest from "./signInRequest";
import { API_AUTH_EMAIL } from "../../constants/apiRoutConstants";
import signInResponse from "./signInResponse";
import { setMainFormError } from "../../redux/formErrors/formErrorsReduce";
import { codeTitles } from "../../services/formErrors/formErrorsService";
import {
  CODE_STATUS_UNAUTHORIZED,
  STATUS_UNAUTHORIZED,
} from "../../constants/errorCodes";
import {
  authLoadingFalse,
  authLoadingTrue,
} from "../../redux/userData/userDataReduce";

export const signInApi = (data: signInRequest) => {
  startRequest();
  axios
    .post(API_AUTH_EMAIL, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful();
    });
};

const startRequest = () => {
  store.dispatch(authLoadingTrue());
};

export const successful = (response: responseInterface<signInResponse>) => {
  if (response.statusCode === STATUS_UNAUTHORIZED) {
    const text = codeTitles(CODE_STATUS_UNAUTHORIZED);
    store.dispatch(setMainFormError([text]));
    store.dispatch(authLoadingFalse());
  }
  if (response?.data?.token !== undefined)
    store.dispatch(setToken(response?.data?.token));
};

export const unsuccessful = () => {
  store.dispatch(authLoadingFalse());
};
