import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  ButtonGroup,
  Container,
  CssBaseline,
  Divider,
  Skeleton,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_LEARN } from "../../constants/titlesConstants";
import AppLoader from "../../components/loader/AppLoader";
import { learnDataApi } from "../../api/learnData/learnDataApi";
import { clearLearnDataDispatch } from "../../redux/learnData/learnDataDispatcher";
import { clearLearnNextDispatch } from "../../redux/learnNext/learnNextDispatcher";
import { clearLearnCheckDispatch } from "../../redux/learnCheck/learnCheckDispatcher";
import NewLearningWordWordLayout from "../../components/layouts/learn/NewLearningWordWordLayout";
import newLearningWord from "../../models/newLearningWord";
import ChooseValueComponent from "../../components/chooseValue/ChooseValueComponent";
import selectValueWord from "../../models/selectValueWord";
import LearnProgressLayout from "../../components/layouts/learn/LearnProgressLayout";
import { useSelector } from "react-redux";
import learnDataSelectors from "../../redux/learnData/learnDataSelectors";
import learnCheckSelectors from "../../redux/learnCheck/learnCheckSelectors";
import learnNextSelectors from "../../redux/learnNext/learnNextSelectors";
import { learnNextApi } from "../../api/learnNext/learnNextApi";
import learnCheckData from "../../api/learnCheck/learnCheckData";
import { learnCheckApi } from "../../api/learnCheck/learnCheckApi";
import LastNewLearningWordLayout from "../../components/layouts/learn/LastNewLearningWordLayout";
import { Link } from "react-router-dom";
import { ROUTE_PAY } from "../../constants/routesConstants";
import TranscriptionsComponent from "../../components/transcriptions/TranscriptionsComponent";
import AudioMultipleComponent from "../../components/audioComponent/AudioMultipleComponent";
import EnglishVariantChecker from "../../components/layouts/learn/EnglishVariantChecker";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";

export default function LearnPage() {
  const [isNewWord, setIsNewWord] = useState(false);
  const [newLearningWord, setNewLearningWord] =
    useState<newLearningWord | null>(null);
  const [progress, setProgress] = useState(0);
  const [selectValueWord, setSelectValueWord] =
    useState<selectValueWord | null>(null);
  const [successAlertShow, setSuccessAlertShow] = useState(false);

  const learnDataLoading = useSelector(learnDataSelectors.learnDataLoading);
  const lastNewLearningWord = useSelector(
    learnDataSelectors.lastNewLearningWord,
  );
  const updateNewWord = useSelector(learnDataSelectors.updateNewWord);
  const learnDataProgress = useSelector(learnDataSelectors.progress);
  const learningWordSoundTypes = useSelector(
    learnDataSelectors.learningWordSoundTypes,
  );
  const learningWordTranscriptionTypes = useSelector(
    learnDataSelectors.learningWordTranscriptionTypes,
  );

  const chooseValueWord = useSelector(learnNextSelectors.learnNext);
  const learnNextLoading = useSelector(learnNextSelectors.learnNextLoading);

  const learnCheckNewLearningWord = useSelector(
    learnCheckSelectors.newLearningWord,
  );
  const learnCheckLoading = useSelector(learnCheckSelectors.learnCheckLoading);
  const learnCheckProgress = useSelector(learnCheckSelectors.progress);
  const success = useSelector(learnCheckSelectors.success);

  const paidAccess = useSelector(learnDataSelectors.paidAccess);

  useEffect(() => {
    learnDataApi();

    return () => {
      clearLearnDataDispatch();
      clearLearnNextDispatch();
      clearLearnCheckDispatch();
    };
  }, []);

  useEffect(() => {
    setNewLearningWord(lastNewLearningWord);
  }, [lastNewLearningWord]);

  useEffect(() => {
    if (learnCheckNewLearningWord !== null) {
      setNewLearningWord(learnCheckNewLearningWord);
      setIsNewWord(true);
    }
  }, [learnCheckNewLearningWord]);

  useEffect(() => {
    if (updateNewWord === true) {
      setIsNewWord(true);
    }
  }, [updateNewWord]);

  useEffect(() => {
    setProgress(learnDataProgress);
  }, [learnDataProgress]);

  useEffect(() => {
    setProgress(learnCheckProgress);
  }, [learnCheckProgress]);

  useEffect(() => {
    if (selectValueWord !== null) {
      setSelectValueWord({
        ...selectValueWord,
        success: success,
      });
    }

    if (success === true) {
      setSuccessAlertShow(true);
      learnNextApi();
      setSelectValueWord(null);
    }
  }, [success]);

  useEffect(() => {
    if (learnDataLoading === false && chooseValueWord === null) {
      learnNextApi();
    }
  }, [learnDataLoading]);

  useEffect(() => {
    if (learnNextLoading === false) {
      setSuccessAlertShow(false);
    }
  }, [learnNextLoading]);

  function AlertSuccessComponent() {
    return (
      <>
        <Box
          sx={{
            display: "box",
            width: "100%",
            position: "fixed",
            bottom: "10px",
            left: 0,
            zIndex: 1,
            pl: 2,
            pr: 2,
          }}
        >
          <Alert variant="filled" severity="success">
            {"Верно!"}
          </Alert>
        </Box>
      </>
    );
  }

  function newWordHandle() {
    learnDataApi();
    setIsNewWord(false);
  }

  function isStart() {
    return learnDataLoading === false;
  }

  function isNewWordShow() {
    return isStart() && isNewWord === true;
  }

  function isLearnLayoutShow() {
    return isStart() && isNewWord === false;
  }

  function selectValueHandle(select: selectValueWord) {
    setSelectValueWord(select);

    const data = learnCheckData(
      select.learningWord.learning_word_id,
      select.chooseWordTranslate.learning_word_translate_id,
    );

    learnCheckApi(data);
  }

  function isShowPaidLayout() {
    return paidAccess === false;
  }

  function ButtonChooseSkeleton() {
    return (
      <Skeleton
        variant="rounded"
        sx={{
          margin: 1,
          minWith: 200,
          p: 2,
          fontWeight: "bold",
          fontSize: 18,
          height: 64,
        }}
      />
    );
  }

  function LoadingLayout() {
    return (
      <>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 61,
          }}
        >
          <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
            <Skeleton variant="text" sx={{ width: 250 }} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            fontSize: 20,
            fontStyle: "italic",
            mb: 2,
            height: 32,
          }}
        >
          <Skeleton variant="text" sx={{ width: 150 }} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
            fontSize: "12px",
            marginTop: "0px",
            color: "#9a9a9a",
          }}
        >
          {"(Выберите правильное значение)"}
        </Box>
        <Box
          display={"flex"}
          justifyContent="center"
          sx={{ marginTop: "15px" }}
        >
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            sx={{
              p: 2,
              width: "100%",
              maxWidth: 400,
            }}
          >
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
            <ButtonChooseSkeleton />
          </ButtonGroup>
        </Box>
      </>
    );
  }

  function PaidLayout() {
    return (
      <>
        <Alert severity="warning">
          {"Новые слова не будут появляться."}
          <Box component={"span"} sx={{ pl: 2 }}>
            <Link to={ROUTE_PAY}>{"Оплатитe подписку"}</Link>
          </Box>
        </Alert>
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md" sx={{ minHeight: 550 }}>
        <TitleLineComponent title={TITLE_PAGE_LEARN} />
        {isShowPaidLayout() ? <PaidLayout /> : ""}
        {isNewWordShow() && newLearningWord ? (
          <Box sx={{ maxWidth: 400, margin: "0 auto" }}>
            <NewLearningWordWordLayout
              newLearningWord={newLearningWord}
              continueHandler={newWordHandle}
              learningWordTranscriptionTypes={learningWordTranscriptionTypes}
              learningWordSoundTypes={learningWordSoundTypes}
            />
          </Box>
        ) : (
          ""
        )}
        {isLearnLayoutShow() ? (
          <>
            <EnglishVariantChecker />
            <LearnProgressLayout progress={progress} />
            {chooseValueWord !== null && learnNextLoading === false ? (
              <>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 61,
                  }}
                >
                  <Box sx={{ fontSize: 40, textTransform: "uppercase" }}>
                    <AudioMultipleComponent
                      learningWordSounds={chooseValueWord.sounds}
                      learningWordSoundTypes={learningWordSoundTypes}
                    />
                    {chooseValueWord.learningWord.word}
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    fontStyle: "italic",
                    mb: 2,
                    height: 32,
                  }}
                >
                  <TranscriptionsComponent
                    transcriptions={chooseValueWord.transcriptions}
                    learningWordTranscriptionTypes={
                      learningWordTranscriptionTypes
                    }
                  />
                </Box>
                <ChooseValueComponent
                  chooseValueWord={chooseValueWord}
                  selectValueHandle={selectValueHandle}
                  selectValueWord={selectValueWord}
                  disable={learnCheckLoading || learnNextLoading}
                />
              </>
            ) : (
              <LoadingLayout />
            )}
          </>
        ) : (
          ""
        )}
        {isLearnLayoutShow() && newLearningWord ? (
          <Box sx={{ mt: "40px" }}>
            <Divider />
            <LastNewLearningWordLayout newLearningWord={newLearningWord} />
          </Box>
        ) : (
          ""
        )}
        {!isStart() ? (
          <>
            <AppLoader />
          </>
        ) : (
          ""
        )}
        {successAlertShow ? <AlertSuccessComponent /> : ""}
      </Container>
      <Footer />
    </>
  );
}
