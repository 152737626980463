import store from "../store";
import {
  clearUserGeneratePay,
  setPayUrl,
  setUserGeneratePayLoadingFalse,
  setUserGeneratePayLoadingTrue,
} from "./userGeneratePayReduce";

export function clearUserGeneratePayDispatch() {
  store.dispatch(clearUserGeneratePay());
}
export function setUserGeneratePayLoadingTrueDispatch() {
  store.dispatch(setUserGeneratePayLoadingTrue());
}
export function setUserGeneratePayLoadingFalseDispatch() {
  store.dispatch(setUserGeneratePayLoadingFalse());
}
export function setPayUrlDispatch(payUrl: string) {
  store.dispatch(setPayUrl(payUrl));
}
