import wordListItem from "../../models/wordListItem";

export function learningWordIdsFromWordList(wordList: wordListItem[]) {
  return wordList
    .filter((value: wordListItem) => {
      return value.remove === false;
    })
    .map((value: wordListItem) => {
      return value.learningWord.learning_word_id;
    });
}
