import { createSlice } from "@reduxjs/toolkit";

export interface groupPersonalUpdateStoreInterface {
  groupPersonalUpdateLoading: boolean;
  groupPersonalUpdateComplete: boolean | null;
}

export const initState: groupPersonalUpdateStoreInterface = {
  groupPersonalUpdateLoading: false,
  groupPersonalUpdateComplete: null,
};

export const groupPersonalUpdateReduceSlice = createSlice({
  name: "GroupPersonalUpdateReduce",
  initialState: initState,
  reducers: {
    clearGroupPersonalUpdate: (state: groupPersonalUpdateStoreInterface) => {
      state.groupPersonalUpdateLoading = initState.groupPersonalUpdateLoading;
      state.groupPersonalUpdateComplete = initState.groupPersonalUpdateComplete;
    },
    setGroupPersonalUpdateLoadingTrue: (
      state: groupPersonalUpdateStoreInterface,
    ) => {
      state.groupPersonalUpdateLoading = true;
    },
    setGroupPersonalUpdateLoadingFalse: (
      state: groupPersonalUpdateStoreInterface,
    ) => {
      state.groupPersonalUpdateLoading = false;
    },
    setGroupPersonalUpdateComplete: (
      state: groupPersonalUpdateStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.groupPersonalUpdateComplete = action.payload;
    },
  },
});

export const {
  clearGroupPersonalUpdate,
  setGroupPersonalUpdateLoadingTrue,
  setGroupPersonalUpdateLoadingFalse,
  setGroupPersonalUpdateComplete,
} = groupPersonalUpdateReduceSlice.actions;

export default groupPersonalUpdateReduceSlice.reducer;
