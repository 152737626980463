import { updateEmailApi } from "../../api/updateEmail/updateEmailApi";
import updateEmailData from "../../api/updateEmail/updateEmailData";
import {
  isEmailIncorrectFormat,
  isEmailValueEmpty,
} from "../formCheckService/formCheckService";
import { clearFormError } from "../formErrors/formErrorsService";

export const updateEmailHandle = (email: string) => {
  clearFormError();

  if (isEmailValueEmpty(email)) {
    return;
  }

  if (isEmailIncorrectFormat(email)) {
    return;
  }

  const data = updateEmailData(email);
  updateEmailApi(data);
};
