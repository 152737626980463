import { createSlice } from "@reduxjs/toolkit";

export interface groupPersonalDeleteStoreInterface {
  groupPersonalDeleteLoading: boolean;
  groupPersonalDeleteComplete: boolean | null;
}

export const initState: groupPersonalDeleteStoreInterface = {
  groupPersonalDeleteLoading: false,
  groupPersonalDeleteComplete: null,
};

export const groupPersonalDeleteReduceSlice = createSlice({
  name: "GroupPersonalDeleteReduce",
  initialState: initState,
  reducers: {
    clearGroupPersonalDelete: (state: groupPersonalDeleteStoreInterface) => {
      state.groupPersonalDeleteLoading = initState.groupPersonalDeleteLoading;
      state.groupPersonalDeleteComplete = initState.groupPersonalDeleteComplete;
    },
    setGroupPersonalDeleteLoadingTrue: (
      state: groupPersonalDeleteStoreInterface,
    ) => {
      state.groupPersonalDeleteLoading = true;
    },
    setGroupPersonalDeleteLoadingFalse: (
      state: groupPersonalDeleteStoreInterface,
    ) => {
      state.groupPersonalDeleteLoading = false;
    },
    setGroupPersonalDeleteComplete: (
      state: groupPersonalDeleteStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.groupPersonalDeleteComplete = action.payload;
    },
  },
});

export const {
  clearGroupPersonalDelete,
  setGroupPersonalDeleteLoadingTrue,
  setGroupPersonalDeleteLoadingFalse,
  setGroupPersonalDeleteComplete,
} = groupPersonalDeleteReduceSlice.actions;

export default groupPersonalDeleteReduceSlice.reducer;
