import { createSlice } from "@reduxjs/toolkit";
import step from "../../models/step";

export interface stepperStoreInterface {
  isNextStep: boolean;
  steps: step[];
}

export const initStepper: stepperStoreInterface = {
  isNextStep: true,
  steps: [],
};

export const stepperReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initStepper,
  reducers: {
    clearStepper: (state: stepperStoreInterface) => {
      state.isNextStep = false;
      state.steps = [];
    },
    setNextStepTrue: (state: stepperStoreInterface) => {
      state.isNextStep = true;
    },
    setNextStepFalse: (state: stepperStoreInterface) => {
      state.isNextStep = false;
    },
    setSteps: (
      state: stepperStoreInterface,
      action: {
        type: string;
        payload: step[];
      },
    ) => {
      state.steps = action.payload;
    },
  },
});

export const { clearStepper, setNextStepTrue, setNextStepFalse, setSteps } =
  stepperReduceSlice.actions;

export default stepperReduceSlice.reducer;
