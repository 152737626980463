import { createSlice } from "@reduxjs/toolkit";

export interface userConfirmEmailStoreInterface {
  userConfirmEmailLoading: boolean;
  userConfirmEmailComplete: boolean | null;
}

export const initState: userConfirmEmailStoreInterface = {
  userConfirmEmailLoading: false,
  userConfirmEmailComplete: null,
};

const userConfirmEmailReduceSlice = createSlice({
  name: "userConfirmEmailReduce",
  initialState: initState,
  reducers: {
    clearUserConfirmEmail: (state: userConfirmEmailStoreInterface) => {
      state.userConfirmEmailLoading = initState.userConfirmEmailLoading;
      state.userConfirmEmailComplete = initState.userConfirmEmailComplete;
    },
    setUserConfirmEmailLoadingTrue: (state: userConfirmEmailStoreInterface) => {
      state.userConfirmEmailLoading = true;
    },
    setUserConfirmEmailLoadingFalse: (
      state: userConfirmEmailStoreInterface,
    ) => {
      state.userConfirmEmailLoading = false;
    },
    setUserConfirmEmailCompleteTrue: (
      state: userConfirmEmailStoreInterface,
    ) => {
      state.userConfirmEmailComplete = true;
    },
    setUserConfirmEmailCompleteFalse: (
      state: userConfirmEmailStoreInterface,
    ) => {
      state.userConfirmEmailComplete = false;
    },
    setUserConfirmEmailCompleteNull: (
      state: userConfirmEmailStoreInterface,
    ) => {
      state.userConfirmEmailComplete = null;
    },
  },
});

export const {
  clearUserConfirmEmail,
  setUserConfirmEmailLoadingTrue,
  setUserConfirmEmailLoadingFalse,
  setUserConfirmEmailCompleteTrue,
  setUserConfirmEmailCompleteFalse,
  setUserConfirmEmailCompleteNull,
} = userConfirmEmailReduceSlice.actions;

export default userConfirmEmailReduceSlice.reducer;
