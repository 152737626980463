import store from "../store";
import {
  alertClose,
  alertError,
  alertInfo,
  alertSuccess,
  alertWarning,
} from "./alertReduce";

export function alertErrorDispatch(text: string) {
  store.dispatch(alertError(text));
}

export function alertWarningDispatch(text: string) {
  store.dispatch(alertWarning(text));
}

export function alertInfoDispatch(text: string) {
  store.dispatch(alertInfo(text));
}

export function alertSuccessDispatch(text: string) {
  store.dispatch(alertSuccess(text));
}

export function alertCloseDispatch() {
  store.dispatch(alertClose());
}
