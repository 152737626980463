import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupExam from "../../models/learningWordGroupExam";

export interface groupExamStoreInterface {
  groupExamAccess: boolean;
  learningWordGroupExam: learningWordGroupExam | null;
  learningWordGroupExamLoading: boolean;
}

export const initGroupTest: groupExamStoreInterface = {
  groupExamAccess: true,
  learningWordGroupExam: null,
  learningWordGroupExamLoading: true,
};

export const groupExamReduceSlice = createSlice({
  name: "groupExamReduce",
  initialState: initGroupTest,
  reducers: {
    clearGroupExam: (state: groupExamStoreInterface) => {
      state.groupExamAccess = true;
      state.learningWordGroupExam = null;
      state.learningWordGroupExamLoading = true;
    },
    setGroupExamAccessTrue: (state: groupExamStoreInterface) => {
      state.groupExamAccess = true;
    },
    setGroupExamAccessFalse: (state: groupExamStoreInterface) => {
      state.groupExamAccess = false;
    },
    setLearningWordGroupExam: (
      state: groupExamStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupExam | null;
      },
    ) => {
      state.learningWordGroupExam = action.payload;
    },
    setLearningWordGroupExamLoadingTrue: (state: groupExamStoreInterface) => {
      state.learningWordGroupExamLoading = true;
    },
    setLearningWordGroupExamLoadingFalse: (state: groupExamStoreInterface) => {
      state.learningWordGroupExamLoading = false;
    },
  },
});

export const {
  clearGroupExam,
  setGroupExamAccessTrue,
  setGroupExamAccessFalse,
  setLearningWordGroupExam,
  setLearningWordGroupExamLoadingTrue,
  setLearningWordGroupExamLoadingFalse,
} = groupExamReduceSlice.actions;

export default groupExamReduceSlice.reducer;
