import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import { API_LEARN_DATA } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnDataAccessFalseDispatch,
  setLearnDataLoadingFalseDispatch,
  setLearnDataLoadingTrueDispatch,
  setCompleteDispatch,
  setLastNewLearningWordDispatch,
  setProgressDispatch,
  setUpdateNewWordDispatch,
  setLearnDataAccessTrueDispatch,
  setPaidAccessDispatch,
  setLearningWordSoundTypesDispatch,
  setLearningWordTranscriptionTypesDispatch,
} from "../../redux/learnData/learnDataDispatcher";
import learnDataResponse from "./learnDataResponse";
import { setCountLearningWordDispatch } from "../../redux/userData/userDataDispatcher";

export const learnDataApi = () => {
  startRequest();

  axios
    .get(API_LEARN_DATA)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnDataLoadingTrueDispatch();
};

function successful(response: responseInterface<learnDataResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnDataAccessFalseDispatch();
    setLearnDataLoadingFalseDispatch();
    return;
  }

  setLearnDataAccessTrueDispatch();
  setLastNewLearningWordDispatch(response.data.lastNewLearningWord);
  setUpdateNewWordDispatch(response.data.updateNewWord);
  setCompleteDispatch(response.data.complete);
  setProgressDispatch(response.data.progress);
  setPaidAccessDispatch(response.data.paidAccess);
  setCountLearningWordDispatch(response.data.countLearningWord);
  setLearningWordSoundTypesDispatch(response.data.learningWordSoundTypes);
  setLearningWordTranscriptionTypesDispatch(
    response.data.learningWordTranscriptionTypes,
  );

  setLearnDataLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
