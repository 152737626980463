import React, { useEffect } from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import { TITLE_PAGE_USER_UNSUBSCRIBE } from "../../constants/titlesConstants";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { clearUserUnsubscribeDispatch } from "../../redux/userUnsubscribe/userUnsubscribeDispatcher";
import userUnsubscribeData from "../../api/userUnsubscribe/userUnsubscribeData";
import userUnsubscribeApi from "../../api/userUnsubscribe/userUnsubscribeApi";
import { useSelector } from "react-redux";
import userUnsubscribeSelectors from "../../redux/userUnsubscribe/userUnsubscribeSelectors";

export default function UnsubscribePage() {
  const email = String(useParams().email);
  const userUnsubscribeLoading = useSelector(
    userUnsubscribeSelectors.userUnsubscribeLoading,
  );
  const userUnsubscribeComplete = useSelector(
    userUnsubscribeSelectors.userUnsubscribeComplete,
  );

  useEffect(() => {
    return () => {
      clearUserUnsubscribeDispatch();
    };
  }, []);

  function unsubscribeHandle() {
    const data = userUnsubscribeData(email);

    userUnsubscribeApi(data);
  }

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md" sx={{ p: 4 }}>
        <TitleLineComponent title={TITLE_PAGE_USER_UNSUBSCRIBE} />
      </Container>
      <Container component="main" maxWidth="xs">
        {userUnsubscribeComplete === true ? (
          <>
            <Box>{"Вы успешно отписались"}</Box>
          </>
        ) : (
          <>
            <Box>
              {
                "Вы уверены, что не хотите получать скидки и персональные предложения?"
              }
            </Box>
            <Box sx={{ pt: 2 }}>
              <LoadingButton
                variant="contained"
                loading={userUnsubscribeLoading}
                onClick={unsubscribeHandle}
              >
                {"Отписаться"}
              </LoadingButton>
            </Box>
          </>
        )}
      </Container>
    </>
  );
}
