import learningWordGroupPersonal from "../../models/learningWordGroupPersonal";
import store from "../store";
import {
  clearGroupPersonalList,
  setGroupPersonalListLoadingFalse,
  setGroupPersonalListLoadingTrue,
  setLearningWordGroupPersonals,
} from "./groupPersonalListReduce";

export function clearGroupPersonalListDispatch() {
  store.dispatch(clearGroupPersonalList());
}

export function setGroupPersonalListLoadingTrueDispatch() {
  store.dispatch(setGroupPersonalListLoadingTrue());
}

export function setGroupPersonalListLoadingFalseDispatch() {
  store.dispatch(setGroupPersonalListLoadingFalse());
}

export function setLearningWordGroupPersonalsDispatch(
  value: learningWordGroupPersonal[],
) {
  store.dispatch(setLearningWordGroupPersonals(value));
}
