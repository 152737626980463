import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect } from "react";
import socialAuth, { SOCIAL_NAME_VK } from "../../../models/socialAuth";
import { SocialIcon } from "react-social-icons";
import ConfirmationButton from "../../confirmationButton/ConfirmationButton";
import { useSelector } from "react-redux";
import userDataSelectors from "../../../redux/userData/userDataSelectors";
import { updateSocialAuthLoadingFalse } from "../../../redux/userData/userDataReduce";
import Loader from "../../loader/Loader";
import { socialRemoveApi } from "../../../api/socialRemove/socialRemoveApi";
import socialRemoveData from "../../../api/socialRemove/socialRemoveData";
import { vkAuthService } from "../../../services/vkAuthService/vkAuthService";
import userDataApi from "../../../api/userData/userDataApi";
import { storeInterface } from "../../../redux/store";

export default function SettingSocialAuthLayout() {
  const token = useSelector((state: storeInterface) => state.app.token);
  const socialAuthArray = useSelector(userDataSelectors.userData).socialAuth;
  const updateSocialAuthLoading = useSelector(
    userDataSelectors.updateSocialAuthLoading,
  );

  useEffect(() => {
    return () => {
      updateSocialAuthLoadingFalse();
    };
  }, []);

  function afterHandle() {
    userDataApi();
  }

  function hookHandleYes(socialAuth: socialAuth) {
    if (socialAuth.socialName === SOCIAL_NAME_VK) {
      vkAuthService(afterHandle, token);
    }
  }

  function unhookHandleYes(socialAuth: socialAuth) {
    const data = socialRemoveData(socialAuth.socialAuthProviderId);
    socialRemoveApi(data);
  }

  return (
    <>
      <Divider
        textAlign="left"
        sx={{ mt: "25px", mb: "25px", fontSize: "18px" }}
      >
        {"Привязка социальных аккаунтов"}
      </Divider>

      {updateSocialAuthLoading === true ? (
        <Loader />
      ) : (
        socialAuthArray.map((item: socialAuth, key: number) => {
          return (
            <Grid container key={key}>
              <Grid
                xs={6}
                item
                sx={{
                  placeSelf: "center",
                  pr: "10px",
                  textAlign: "right",
                }}
              >
                {item.socialName === SOCIAL_NAME_VK ? (
                  <SocialIcon
                    network="vk"
                    style={{ width: "40px", height: "40px" }}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <Grid xs={4} item sx={{ placeSelf: "center" }}>
                {item.connected === false ? (
                  <Button
                    onClick={() => {
                      hookHandleYes(item);
                    }}
                    variant="contained"
                  >
                    {"Привязать"}
                  </Button>
                ) : (
                  <ConfirmationButton
                    handleYes={() => {
                      unhookHandleYes(item);
                    }}
                    title={"Отвязать авторизацию через выбранным способом?"}
                    buttonText={"Отвязать"}
                    variant="text"
                  />
                )}
              </Grid>
            </Grid>
          );
        })
      )}
    </>
  );
}
