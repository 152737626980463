import { createSlice } from "@reduxjs/toolkit";
import learningWord from "../../models/learningWord";

export interface wordSearchStoreInterface {
  wordSearchResponse: learningWord[] | null;
  wordSearchLoading: boolean;
}

export const initLearnSearch: wordSearchStoreInterface = {
  wordSearchResponse: null,
  wordSearchLoading: false,
};

export const wordSearchReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initLearnSearch,
  reducers: {
    clearWordSearch: (state: wordSearchStoreInterface) => {
      state.wordSearchResponse = null;
      state.wordSearchLoading = false;
    },
    setWordSearchResponse: (
      state: wordSearchStoreInterface,
      action: {
        type: string;
        payload: learningWord[] | null;
      },
    ) => {
      state.wordSearchResponse = action.payload;
    },
    setWordSearchLoadingTrue: (state: wordSearchStoreInterface) => {
      state.wordSearchLoading = true;
    },
    setWordSearchLoadingFalse: (state: wordSearchStoreInterface) => {
      state.wordSearchLoading = false;
    },
  },
});

export const {
  clearWordSearch,
  setWordSearchResponse,
  setWordSearchLoadingTrue,
  setWordSearchLoadingFalse,
} = wordSearchReduceSlice.actions;

export default wordSearchReduceSlice.reducer;
