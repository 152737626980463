import { createSlice } from "@reduxjs/toolkit";
import selectValueWord from "../../models/selectValueWord";

export interface learnTestProcessStoreInterface {
  complete: boolean | null;
  selectValueWordArray: selectValueWord[];
  learnTestProcessLoading: boolean;
  learnTestProcessAccess: boolean;
}

export const initData: learnTestProcessStoreInterface = {
  complete: null,
  selectValueWordArray: [],
  learnTestProcessLoading: false,
  learnTestProcessAccess: true,
};

export const learnTestProcessReduceSlice = createSlice({
  name: "learnTestProcessReduce",
  initialState: initData,
  reducers: {
    clearLearnTestProcess: (state: learnTestProcessStoreInterface) => {
      state.complete = initData.complete;
      state.selectValueWordArray = initData.selectValueWordArray;
      state.learnTestProcessLoading = initData.learnTestProcessLoading;
      state.learnTestProcessAccess = initData.learnTestProcessAccess;
    },
    setCompleteTrue: (state: learnTestProcessStoreInterface) => {
      state.complete = true;
    },
    setCompleteFalse: (state: learnTestProcessStoreInterface) => {
      state.complete = false;
    },
    setSelectValueWordArray: (
      state: learnTestProcessStoreInterface,
      action: {
        type: string;
        payload: selectValueWord[];
      },
    ) => {
      state.selectValueWordArray = action.payload;
    },
    setLearnTestProcessLoadingTrue: (state: learnTestProcessStoreInterface) => {
      state.learnTestProcessLoading = true;
    },
    setLearnTestProcessLoadingFalse: (
      state: learnTestProcessStoreInterface,
    ) => {
      state.learnTestProcessLoading = false;
    },
    setLearnTestProcessAccessTrue: (state: learnTestProcessStoreInterface) => {
      state.learnTestProcessAccess = true;
    },
    setLearnTestProcessAccessFalse: (state: learnTestProcessStoreInterface) => {
      state.learnTestProcessAccess = false;
    },
  },
});

export const {
  clearLearnTestProcess,
  setCompleteTrue,
  setCompleteFalse,
  setSelectValueWordArray,
  setLearnTestProcessLoadingTrue,
  setLearnTestProcessLoadingFalse,
  setLearnTestProcessAccessTrue,
  setLearnTestProcessAccessFalse,
} = learnTestProcessReduceSlice.actions;

export default learnTestProcessReduceSlice.reducer;
