import { createSlice } from "@reduxjs/toolkit";

export interface groupRemoveTestStoreInterface {
  groupRemoveTestAccess: boolean;
  groupRemoveTestLoading: boolean;
  groupRemoveTestSuccess: boolean | null;
}

export const initData: groupRemoveTestStoreInterface = {
  groupRemoveTestAccess: true,
  groupRemoveTestLoading: false,
  groupRemoveTestSuccess: null,
};

export const groupRemoveTestReduceSlice = createSlice({
  name: "groupTestReduce",
  initialState: initData,
  reducers: {
    clearGroupRemoveTest: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestAccess = true;
      state.groupRemoveTestLoading = false;
      state.groupRemoveTestSuccess = null;
    },
    setGroupRemoveTestAccessTrue: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestAccess = true;
    },
    setGroupRemoveTestAccessFalse: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestAccess = false;
    },
    setGroupRemoveTestLoadingTrue: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestLoading = true;
    },
    setGroupRemoveTestLoadingFalse: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestLoading = false;
    },
    setGroupRemoveTestSuccessTrue: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestSuccess = true;
    },
    setGroupRemoveTestSuccessFalse: (state: groupRemoveTestStoreInterface) => {
      state.groupRemoveTestSuccess = false;
    },
  },
});

export const {
  clearGroupRemoveTest,
  setGroupRemoveTestAccessTrue,
  setGroupRemoveTestAccessFalse,
  setGroupRemoveTestLoadingTrue,
  setGroupRemoveTestLoadingFalse,
  setGroupRemoveTestSuccessTrue,
  setGroupRemoveTestSuccessFalse,
} = groupRemoveTestReduceSlice.actions;

export default groupRemoveTestReduceSlice.reducer;
