import React, { useState } from "react";
import QuestionDialog from "../questionDialog/QuestionDialog";
import { LoadingButton } from "@mui/lab";

interface confirmationButtonPropsInterface {
  handleYes: CallableFunction;
  title?: string;
  content?: string;
  yesText?: string;
  noText?: string;
  buttonText?: string;
  variant?: "contained" | "text" | "outlined";
  loading: boolean;
}

const defaultProps: confirmationButtonPropsInterface = {
  handleYes: () => {},
  title: "Вы точно хотите это сделать?",
  content: "",
  yesText: "да",
  noText: "нет",
  buttonText: "Удалить",
  variant: "contained",
  loading: false,
};

function ConfirmationButton(props: confirmationButtonPropsInterface) {
  const [open, setOpen] = useState(false);

  function handleOpen() {
    return setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleYes() {
    props.handleYes();
    setOpen(false);
  }

  return (
    <>
      <QuestionDialog
        open={open}
        handleYes={handleYes}
        handleClose={handleClose}
        title={props.title}
        content={props.content}
        yesText={props.yesText}
      />
      <LoadingButton
        variant={props.variant}
        onClick={handleOpen}
        loading={props.loading}
        disabled={props.loading}
      >
        {props.buttonText}
      </LoadingButton>
    </>
  );
}

ConfirmationButton.defaultProps = defaultProps;

export default ConfirmationButton;
