import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { vkAuthService } from "../../../services/vkAuthService/vkAuthService";
import { authSocial, authSocialData } from "../../../api/authSocial";
import { useSelector } from "react-redux";
import { storeInterface } from "../../../redux/store";
import userTokenApi from "../../../api/userToken/userTokenApi";
import Loader from "../../loader/Loader";

export default function VkAuthButton() {
  const temporaryToken = useSelector(
    (state: storeInterface) => state.app.temporaryToken,
  );
  const temporaryTokenLoading = useSelector(
    (state: storeInterface) => state.app.temporaryTokenLoading,
  );

  useEffect(() => {
    if (temporaryToken.length === 0) {
      userTokenApi();
    }
  }, []);

  function afterHandle() {
    const data = authSocialData(temporaryToken);
    authSocial(data);
  }

  return (
    <>
      {temporaryTokenLoading === false ? (
        <Button
          variant="contained"
          onClick={() => {
            vkAuthService(afterHandle, temporaryToken);
          }}
          sx={{ width: "100%" }}
        >
          {"Через VK"}
        </Button>
      ) : (
        <Loader />
      )}
    </>
  );
}
