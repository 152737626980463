import React from "react";
import { Box, CircularProgress, Container } from "@mui/material";

function Loader() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default Loader;
