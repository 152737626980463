import React from "react";
import { Box, Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_SUPPORT } from "../../constants/titlesConstants";
import { useSelector } from "react-redux";
import appSelectors from "../../redux/app/appSelectors";
import { Link } from "react-router-dom";

export default function SupportPage() {
  const supportEmail = useSelector(appSelectors.supportEmail);

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_SUPPORT} />
      </Container>
      <Container maxWidth="sm">
        <Box sx={{ mt: 10, mb: 2 }}>
          {
            "Пожалуйста, отправьте интересующий вас вопрос на указанный ниже Email."
          }
        </Box>
        <Box>
          <Link to={"mailto:" + supportEmail}>{supportEmail}</Link>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
