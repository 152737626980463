import React from "react";
import SettingEmailChangeLayout from "../../components/layouts/settings/SettingEmailChangeLayout";
import SettingPasswordChangeLayout from "../../components/layouts/settings/SettingPasswordChangeLayout";
import SettingSocialAuthLayout from "../../components/layouts/settings/SettingSocialAuthLayout";

const styleColumnText = {
  placeSelf: "center",
  pr: "10px",
  textAlign: "right",
  fontWeight: "bold",
  fontSize: "14px",
};

export default function SettingsContainer() {
  return (
    <>
      <SettingPasswordChangeLayout />
      <SettingEmailChangeLayout />
      <SettingSocialAuthLayout />
    </>
  );
}
