import { Box, LinearProgress, Tooltip } from "@mui/material";
import React from "react";

interface propsInterface {
  progress: number;
}

export default function LearnProgressLayout(props: propsInterface) {
  return (
    <Box>
      <Tooltip title="Прогресс до получения нового слова" enterTouchDelay={0}>
        {props.progress >= 100 ? (
          <LinearProgress variant="determinate" color="success" value={100} />
        ) : (
          <LinearProgress variant="determinate" value={props.progress} />
        )}
      </Tooltip>
    </Box>
  );
}
