import { createSlice } from "@reduxjs/toolkit";

export interface learnStartStoreInterface {
  learnStartLoading: boolean;
  learnStartComplete: boolean | null;
  learnStartAccess: boolean;
  learnStartEnoughsFunds: boolean;
}

export const initData: learnStartStoreInterface = {
  learnStartLoading: false,
  learnStartComplete: null,
  learnStartAccess: true,
  learnStartEnoughsFunds: true,
};

export const learnStartReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initData,
  reducers: {
    clearLearnStart: (state: learnStartStoreInterface) => {
      state.learnStartLoading = initData.learnStartLoading;
      state.learnStartComplete = initData.learnStartComplete;
      state.learnStartAccess = initData.learnStartAccess;
      state.learnStartEnoughsFunds = initData.learnStartEnoughsFunds;
    },
    setLearnStartLoadingTrue: (state: learnStartStoreInterface) => {
      state.learnStartLoading = true;
    },
    setLearnStartLoadingFalse: (state: learnStartStoreInterface) => {
      state.learnStartLoading = false;
    },
    setLearnStartCompleteTrue: (state: learnStartStoreInterface) => {
      state.learnStartComplete = true;
    },
    setLearnStartCompleteFalse: (state: learnStartStoreInterface) => {
      state.learnStartComplete = false;
    },
    setLearnStartAccessTrue: (state: learnStartStoreInterface) => {
      state.learnStartAccess = true;
    },
    setLearnStartAccessFalse: (state: learnStartStoreInterface) => {
      state.learnStartAccess = false;
    },
    setLearnStartEnoughsFundsTrue: (state: learnStartStoreInterface) => {
      state.learnStartEnoughsFunds = true;
    },
    setLearnStartEnoughsFundsFalse: (state: learnStartStoreInterface) => {
      state.learnStartEnoughsFunds = false;
    },
  },
});

export const {
  clearLearnStart,
  setLearnStartLoadingTrue,
  setLearnStartLoadingFalse,
  setLearnStartCompleteTrue,
  setLearnStartCompleteFalse,
  setLearnStartAccessTrue,
  setLearnStartAccessFalse,
  setLearnStartEnoughsFundsTrue,
  setLearnStartEnoughsFundsFalse,
} = learnStartReduceSlice.actions;

export default learnStartReduceSlice.reducer;
