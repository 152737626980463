import axios, { responseInterface } from "../axios";
import { API_USER_CONFIRM_EMAIL } from "../../constants/apiRoutConstants";
import { userConfirmEmailRequest } from "./userConfirmEmailRequest";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import {
  setUserConfirmEmailCompleteFalseDispatch,
  setUserConfirmEmailCompleteNullDispatch,
  setUserConfirmEmailCompleteTrueDispatch,
  setUserConfirmEmailLoadingFalseDispatch,
  setUserConfirmEmailLoadingTrueDispatch,
} from "../../redux/userConfirmEmail/userConfirmEmailDispatcher";
import userConfirmEmailResponse from "./userConfirmEmailResponse";
import { clearFormError } from "../../services/formErrors/formErrorsService";

export const userConfirmEmailApi = (data: userConfirmEmailRequest) => {
  startRequest();

  axios
    .post(API_USER_CONFIRM_EMAIL, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful(res);
    });
};

const startRequest = () => {
  clearFormError();
  setUserConfirmEmailCompleteNullDispatch();
  setUserConfirmEmailLoadingTrueDispatch();
};

const successful = (response: responseInterface<userConfirmEmailResponse>) => {
  response.data.complete === true
    ? setUserConfirmEmailCompleteTrueDispatch()
    : setUserConfirmEmailCompleteFalseDispatch();

  setUserConfirmEmailLoadingFalseDispatch();
};

const unsuccessful = (res: any) => {
  alertWarningDispatch(alertAppError);
};
