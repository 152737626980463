import React from "react";
import { ThemeProvider } from "@emotion/react";
import { Container, createTheme, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_RESET_PASSWORD } from "../../constants/titlesConstants";
import ResetPasswordContainer from "../resetPassword/ResetPasswordContainer";

function ResetPasswordPage() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_RESET_PASSWORD} />
      </Container>
      <Container maxWidth="sm">
        <ResetPasswordContainer />
      </Container>
      <Footer />
    </ThemeProvider>
  );
}

export default ResetPasswordPage;
