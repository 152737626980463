import {
  alertAppError,
  alertInTimeCreate,
} from "../../constants/alertConstants";
import { API_GROUP_PERSONAL_CREATE } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setGroupPersonalCreateLoadingFalseDispatch,
  setGroupPersonalCreateLoadingTrueDispatch,
  setLearningWordGroupPersonalIdDispatch,
} from "../../redux/groupPersonalCreate/groupPersonalCreateDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import groupPersonalCreateRequest from "./groupPersonalcreateRequest";
import groupPersonalCreateResponse from "./groupPersonalcreateResponse";

export default function groupPersonalCreateApi(
  data: groupPersonalCreateRequest,
) {
  startRequest();

  axios
    .post(API_GROUP_PERSONAL_CREATE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setGroupPersonalCreateLoadingTrueDispatch();
};

const successful = (
  response: responseInterface<groupPersonalCreateResponse>,
) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertInTimeCreate);
    setGroupPersonalCreateLoadingFalseDispatch();
    return;
  }

  if (!response.data.learningWordGroupPersonalId) {
    return;
  }

  setLearningWordGroupPersonalIdDispatch(
    response.data.learningWordGroupPersonalId,
  );

  setGroupPersonalCreateLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
