import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import {
  clearToken,
  setTemporaryToken,
  setToken,
  temporaryTokenLoadingFalse,
  temporaryTokenLoadingTrue,
} from "../../redux/app/appReduce";
import { API_USER_TOKEN } from "../../constants/apiRoutConstants";
import userTokenResponse from "./userTokenResponse";

export default function userTokenApi() {
  startRequest();

  axios
    .get(API_USER_TOKEN)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(temporaryTokenLoadingTrue());
};

const successful = (res: responseInterface<userTokenResponse>) => {
  store.dispatch(setTemporaryToken(res.data.token));
  store.dispatch(temporaryTokenLoadingFalse());
};

const unsuccessful = () => {
  //
};
