import signUpRequest from "./signUpRequest";

export default function signUpData(
  name: string,
  email: string,
  password: string,
  emailSubscribe: boolean,
): signUpRequest {
  return {
    name: name,
    email: email,
    password: password,
    emailSubscribe: emailSubscribe,
  };
}
