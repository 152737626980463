import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useEffect } from "react";

interface questionDialogPropsInterface {
  open: boolean;
  handleYes: CallableFunction;
  handleClose: CallableFunction;
  title?: string;
  content?: string;
  yesText?: string;
  noText?: string;
  loading?: boolean;
}

const defaultProps = {
  open: false,
  handleYes: () => {},
  handleClose: () => {},
  title: "Вы точно хотите это сделать?",
  content: "",
  yesText: "да",
  noText: "нет",
  loading: false,
};

function QuestionDialog(props: questionDialogPropsInterface) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  function handleClose() {
    setOpen(false);
    props.handleClose();
  }

  function handleYes() {
    props.handleYes();
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {props.title ? (
          <>
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          </>
        ) : (
          ""
        )}
        {props.content ? (
          <>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.content}
              </DialogContentText>
            </DialogContent>
          </>
        ) : (
          ""
        )}
        <DialogActions>
          <LoadingButton
            loading={props.loading}
            onClick={handleClose}
            variant="contained"
          >
            {props.noText}
          </LoadingButton>
          <LoadingButton loading={props.loading} onClick={handleYes} autoFocus>
            {props.yesText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

QuestionDialog.defaultProps = defaultProps;

export default QuestionDialog;
