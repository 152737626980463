export interface elementsForPageProps<data = any[]> {
  array: data | null;
  pageSize: number;
  page: number;
}

export const elementsForPage = (props: elementsForPageProps) => {
  return (
    props.array?.slice(
      (props.page - 1) * props.pageSize,
      props.page * props.pageSize,
    ) ?? []
  );
};

export const countPage = (countElements: number, pageSize: number): number => {
  return Number(Math.ceil(countElements / pageSize));
};
