import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupPersonal from "../../models/learningWordGroupPersonal";

export interface groupPersonalListStoreInterface {
  groupPersonalListLoading: boolean;
  learningWordGroupPersonals: learningWordGroupPersonal[];
}

export const initState: groupPersonalListStoreInterface = {
  groupPersonalListLoading: true,
  learningWordGroupPersonals: [],
};

export const groupPersonalListReduceSlice = createSlice({
  name: "GroupPersonalListReduce",
  initialState: initState,
  reducers: {
    clearGroupPersonalList: (state: groupPersonalListStoreInterface) => {
      state.groupPersonalListLoading = initState.groupPersonalListLoading;
      state.learningWordGroupPersonals = initState.learningWordGroupPersonals;
    },
    setGroupPersonalListLoadingTrue: (
      state: groupPersonalListStoreInterface,
    ) => {
      state.groupPersonalListLoading = true;
    },
    setGroupPersonalListLoadingFalse: (
      state: groupPersonalListStoreInterface,
    ) => {
      state.groupPersonalListLoading = false;
    },
    setLearningWordGroupPersonals: (
      state: groupPersonalListStoreInterface,
      action: { payload: learningWordGroupPersonal[]; type: string },
    ) => {
      state.learningWordGroupPersonals = action.payload;
    },
  },
});

export const {
  clearGroupPersonalList,
  setGroupPersonalListLoadingTrue,
  setGroupPersonalListLoadingFalse,
  setLearningWordGroupPersonals,
} = groupPersonalListReduceSlice.actions;

export default groupPersonalListReduceSlice.reducer;
