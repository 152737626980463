import { storeInterface } from "../store";

const appDataLoading = (state: storeInterface) => state.app.appDataLoading;
const showAppLoader = (state: storeInterface) => state.app.showAppLoader;
const token = (state: storeInterface) => state.app.token;
const temporaryToken = (state: storeInterface) => state.app.temporaryToken;
const autoPlaySound = (state: storeInterface) => state.app.autoPlaySound;
const autoPlayTypeSound = (state: storeInterface) =>
  state.app.autoPlayTypeSound;
const englishContentVariant = (state: storeInterface) =>
  state.app.englishContentVariant;
const subscribeCost = (state: storeInterface) => state.app.subscribeCost;
const supportEmail = (state: storeInterface) => state.app.supportEmail;

export default {
  appDataLoading,
  showAppLoader,
  token,
  temporaryToken,
  autoPlaySound,
  autoPlayTypeSound,
  englishContentVariant,
  subscribeCost,
  supportEmail,
};
