import { resetPasswordApi } from "../../api/resetPassword/resetPasswordApi";
import resetPasswordData from "../../api/resetPassword/resetPasswordData";
import {
  isEmailIncorrectFormat,
  isEmailValueEmpty,
} from "../formCheckService/formCheckService";
import { clearFormError } from "../formErrors/formErrorsService";

export function resetPasswordHandle(email: string) {
  clearFormError();

  if (isEmailValueEmpty(email)) {
    return;
  }

  if (isEmailIncorrectFormat(email)) {
    return;
  }

  const data = resetPasswordData(email);

  resetPasswordApi(data);
}
