import createEmailAuthRequest from "./createEmailAuthRequest";

export default function createEmailAuthData(
  email: string,
  password: string,
  emailSubscribe: boolean,
): createEmailAuthRequest {
  return {
    email: email,
    password: password,
    emailSubscribe: emailSubscribe,
  };
}
