import store from "../store";
import {
  clearGroupPersonalCreate,
  setGroupPersonalCreateLoadingFalse,
  setGroupPersonalCreateLoadingTrue,
  setLearningWordGroupPersonalId,
} from "./groupPersonalCreateReduce";

export function clearGroupPersonalCreateDispatch() {
  store.dispatch(clearGroupPersonalCreate());
}

export function setGroupPersonalCreateLoadingTrueDispatch() {
  store.dispatch(setGroupPersonalCreateLoadingTrue());
}

export function setGroupPersonalCreateLoadingFalseDispatch() {
  store.dispatch(setGroupPersonalCreateLoadingFalse());
}

export function setLearningWordGroupPersonalIdDispatch(value: number) {
  store.dispatch(setLearningWordGroupPersonalId(value));
}
