import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import AppLoader from "./components/loader/AppLoader";
import RouteList from "./routes/RouteList";
import store, { storeInterface } from "./redux/store";
import { stopAppLoaded } from "./redux/app/appReduce";
import AlertComponent from "./components/alert/AlertComponent";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import appSelectors from "./redux/app/appSelectors";
import UseCookiesComponent from "./components/cookies/UseCookiesComponent";
import userDataApi from "./api/userData/userDataApi";
import userDataSelectors from "./redux/userData/userDataSelectors";
import MetricComponent from "./components/metric/MetricComponent";
import config from "./config/config";
import appDataApi from "./api/appData/appDataApi";

function App() {
  const theme = createTheme();
  const appDataLoading = useSelector(appSelectors.appDataLoading);
  const showAppLoader = useSelector(appSelectors.showAppLoader);
  const userDataLoading = useSelector(userDataSelectors.userDataLoading);
  const token = useSelector((state: storeInterface) => state.app.token);

  useEffect(() => {
    appDataApi();

    if (token.length > 0) {
      userDataApi();
    } else {
      store.dispatch(stopAppLoaded());
    }
  }, []);

  useEffect(() => {
    if (userDataLoading === false) {
      store.dispatch(stopAppLoaded());
    }
  }, [userDataLoading]);

  function isAppLoading() {
    return appDataLoading === true || showAppLoader === true;
  }

  return (
    <>
      <AlertComponent />
      <ThemeProvider theme={theme}>
        <UseCookiesComponent />
        <BrowserRouter>
          {isAppLoading() ? <AppLoader /> : <RouteList />}
        </BrowserRouter>
        {config.isProd === true ? <MetricComponent /> : ""}
      </ThemeProvider>
    </>
  );
}

export default App;
