import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import config from "../config/config";
import { clearToken } from "../redux/app/appReduce";
import store from "../redux/store";
import { alertWarning } from "../redux/alert/alertReduce";
import {
  STATUS_SERVER_ERROR_TEXT,
  STATUS_UNAUTHORIZED,
} from "../constants/errorCodes";

const token = store.getState().app.token;

const axiosConfig: AxiosRequestConfig = {
  baseURL: config.baseUrl,
  responseType: "json",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
};

const axios = Axios.create(axiosConfig);

axios.interceptors.request.use((config: any) => {
  config.headers.common["Authorization"] = token ? `Bearer ${token}` : false;
  return config;
});

axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data;
  },
  function (error: AxiosError) {
    catchHandle(error);

    return Promise.reject(error.response?.data);
  },
);

const catchHandle = (res: AxiosError) => {
  if (res.response?.status === STATUS_UNAUTHORIZED) {
    if (token) store.dispatch(clearToken());
  }

  if (res.response?.status === 0) {
    store.dispatch(alertWarning(STATUS_SERVER_ERROR_TEXT));
  }
};

export interface responseInterface<dataInterface = any> {
  statusCode: number;
  data: dataInterface;
}

export default axios;
