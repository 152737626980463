import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import {
  updateSocialAuthLoadingFalse,
  updateSocialAuthLoadingTrue,
  updateUserDataSocialAuth,
} from "../../redux/userData/userDataReduce";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";
import { socialRemoveRequest } from "./socialRemoveRequest";
import { API_SOCIAL_REMOVE } from "../../constants/apiRoutConstants";
import socialRemoveResponse from "./socialRemoveResponse";

export const socialRemoveApi = (data: socialRemoveRequest) => {
  startRequest();

  axios
    .post(API_SOCIAL_REMOVE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful(res);
    });
};

const startRequest = () => {
  store.dispatch(updateSocialAuthLoadingTrue());
};

const successful = (response: responseInterface<socialRemoveResponse>) => {
  const stopLoadingHandler = () => {
    store.dispatch(updateSocialAuthLoadingFalse());
  };
  const handler = () => {
    store.dispatch(updateUserDataSocialAuth(response.data));
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

const unsuccessful = (res: any) => {
  store.dispatch(updateSocialAuthLoadingFalse());
};
