import axios, { responseInterface } from "../axios";
import { API_USER_GENERATE_PAY } from "../../constants/apiRoutConstants";
import userGeneratePayResponse from "./userGeneratePayResponse";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import {
  setPayUrlDispatch,
  setUserGeneratePayLoadingFalseDispatch,
  setUserGeneratePayLoadingTrueDispatch,
} from "../../redux/userGeneratePay/userGeneratePayDispatcher";

export default function userGeneratePayApi() {
  startRequest();

  axios
    .get(API_USER_GENERATE_PAY)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setUserGeneratePayLoadingTrueDispatch();
};

const successful = (response: responseInterface<userGeneratePayResponse>) => {
  setPayUrlDispatch(response.data.payUrl);
  // setUserGeneratePayLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
