import { createSlice } from "@reduxjs/toolkit";
import learningWordGroup from "../../models/learningWordGroup";

export interface groupDataStoreInterface {
  learningWordGroup: learningWordGroup;
  learningWordGroupLoading: boolean;
  learningWordGroupAccess: boolean | null;
}

export const initData: groupDataStoreInterface = {
  learningWordGroup: {
    learning_word_group_id: 0,
    title: "",
    preview: "",
    cost: 0,
    learn: 0,
    info: {
      user_learn: 0,
      count_word: 0,
    },
    partition: {
      learning_word_group_partition_id: 0,
      partition: "",
    },
    environment: null,
  },
  learningWordGroupLoading: true,
  learningWordGroupAccess: null,
};

export const groupDataReduceSlice = createSlice({
  name: "groupDataReduce",
  initialState: initData,
  reducers: {
    clearGroupData: (state: groupDataStoreInterface) => {
      state.learningWordGroup = initData.learningWordGroup;
      state.learningWordGroupLoading = initData.learningWordGroupLoading;
      state.learningWordGroupAccess = initData.learningWordGroupAccess;
    },
    setLearningWordGroup: (
      state: groupDataStoreInterface,
      action: {
        type: string;
        payload: learningWordGroup;
      },
    ) => {
      state.learningWordGroup = action.payload;
    },
    setLearningWordGroupLoadingTrue: (state: groupDataStoreInterface) => {
      state.learningWordGroupLoading = true;
    },
    setLearningWordGroupLoadingFalse: (state: groupDataStoreInterface) => {
      state.learningWordGroupLoading = false;
    },
    setLearningWordGroupAccessTrue: (state: groupDataStoreInterface) => {
      state.learningWordGroupAccess = true;
    },
    setLearningWordGroupAccessFalse: (state: groupDataStoreInterface) => {
      state.learningWordGroupAccess = false;
    },
  },
});

export const {
  clearGroupData,
  setLearningWordGroup,
  setLearningWordGroupLoadingTrue,
  setLearningWordGroupLoadingFalse,
  setLearningWordGroupAccessTrue,
  setLearningWordGroupAccessFalse,
} = groupDataReduceSlice.actions;

export default groupDataReduceSlice.reducer;
