import { createSlice } from "@reduxjs/toolkit";
import payment from "../../models/payment";

export interface userSubscribeDataStoreInterface {
  userSubscribeDataLoading: boolean;
  subscribeAccess: boolean;
  freeAccess: boolean;
  endPrimeTime: string;
  freeWordCount: number;
  cost: number;
  extendTo: string;
  payments: payment[];
}

export const initData: userSubscribeDataStoreInterface = {
  userSubscribeDataLoading: true,
  subscribeAccess: true,
  freeAccess: false,
  endPrimeTime: "",
  freeWordCount: 0,
  cost: 0,
  extendTo: "",
  payments: [],
};

export const userSubscribeDataReduceSlice = createSlice({
  name: "userSubscribeDataReduce",
  initialState: initData,
  reducers: {
    clearUserSubscribeData: (state: userSubscribeDataStoreInterface) => {
      state.userSubscribeDataLoading = initData.userSubscribeDataLoading;
      state.subscribeAccess = initData.subscribeAccess;
      state.freeAccess = initData.freeAccess;
      state.endPrimeTime = initData.endPrimeTime;
      state.freeWordCount = initData.freeWordCount;
      state.payments = initData.payments;
    },
    setUserSubscribeDataLoadingTrue: (
      state: userSubscribeDataStoreInterface,
    ) => {
      state.userSubscribeDataLoading = true;
    },
    setUserSubscribeDataLoadingFalse: (
      state: userSubscribeDataStoreInterface,
    ) => {
      state.userSubscribeDataLoading = false;
    },
    setSubscribeAccess: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.subscribeAccess = actions.payload;
    },
    setFreeAccess: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.freeAccess = actions.payload;
    },
    setEndPrimeTime: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: string; type: string },
    ) => {
      state.endPrimeTime = actions.payload;
    },
    setFreeWordCount: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.freeWordCount = actions.payload;
    },
    setCost: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.cost = actions.payload;
    },
    setExtendTo: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: string; type: string },
    ) => {
      state.extendTo = actions.payload;
    },
    setPayments: (
      state: userSubscribeDataStoreInterface,
      actions: { payload: payment[]; type: string },
    ) => {
      state.payments = actions.payload;
    },
  },
});

export const {
  clearUserSubscribeData,
  setUserSubscribeDataLoadingTrue,
  setUserSubscribeDataLoadingFalse,
  setSubscribeAccess,
  setFreeAccess,
  setEndPrimeTime,
  setFreeWordCount,
  setCost,
  setExtendTo,
  setPayments,
} = userSubscribeDataReduceSlice.actions;

export default userSubscribeDataReduceSlice.reducer;
