import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  routeLearnGroupNavigate,
  routeLearnGroupPersonalNavigate,
} from "../../../constants/routesConstants";
import PersonalGroupItemDropMenuComponent from "./PersonalGroupItemDropMenuComponent";
import learningWordGroupPersonal from "../../../models/learningWordGroupPersonal";

interface personalGroupItemComponentPropsInterface {
  learningWordGroupPersonal: learningWordGroupPersonal;
}

export default function PersonalGroupItemComponent(
  props: personalGroupItemComponentPropsInterface,
) {
  const navigate = useNavigate();

  function toLearnPersonalGroup() {
    navigate(
      routeLearnGroupPersonalNavigate(
        String(props.learningWordGroupPersonal.learning_word_group_personal_id),
      ),
    );
  }

  return (
    <>
      <Paper elevation={3} sx={{ width: "100%" }}>
        <Grid container spacing={"1px"}>
          <Grid item xs={12} sx={{ mt: "2px", mb: "2px" }}>
            <PersonalGroupItemDropMenuComponent
              learningWordGroupPersonalId={
                props.learningWordGroupPersonal.learning_word_group_personal_id
              }
            />
            <Typography gutterBottom variant="subtitle1" component="span">
              {props.learningWordGroupPersonal.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              size="small"
              onClick={toLearnPersonalGroup}
            >
              {"Продолжить изучение"}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
