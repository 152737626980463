import payment from "../../models/payment";
import store from "../store";
import {
  clearUserSubscribeData,
  setCost,
  setEndPrimeTime,
  setExtendTo,
  setFreeAccess,
  setFreeWordCount,
  setPayments,
  setSubscribeAccess,
  setUserSubscribeDataLoadingFalse,
  setUserSubscribeDataLoadingTrue,
} from "./userSubscribeDataReduce";

export function clearUserSubscribeDataDispatch() {
  store.dispatch(clearUserSubscribeData());
}
export function setUserSubscribeDataLoadingTrueDispatch() {
  store.dispatch(setUserSubscribeDataLoadingTrue());
}
export function setUserSubscribeDataLoadingFalseDispatch() {
  store.dispatch(setUserSubscribeDataLoadingFalse());
}
export function setSubscribeAccessDispatch(value: boolean) {
  store.dispatch(setSubscribeAccess(value));
}
export function setFreeAccessDispatch(value: boolean) {
  store.dispatch(setFreeAccess(value));
}
export function setEndPrimeTimeDispatch(value: string) {
  store.dispatch(setEndPrimeTime(value));
}
export function setFreeWordCountDispatch(value: number) {
  store.dispatch(setFreeWordCount(value));
}
export function setCostDispatch(value: number) {
  store.dispatch(setCost(value));
}
export function setExtendToDispatch(value: string) {
  store.dispatch(setExtendTo(value));
}
export function setPaymentsDispatch(value: payment[]) {
  store.dispatch(setPayments(value));
}
