import axios, { responseInterface } from "../axios";
import { API_LEARN_GROUP_PERSONAL_NEXT } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnGroupPersonalNextResponse from "./learnGroupPersonalNextResponse";
import {
  setLearnGroupPersonalNextAccessFalseDispatch,
  setLearnGroupPersonalNextAccessTrueDispatch,
  setLearnGroupPersonalNextDispatch,
  setLearnGroupPersonalNextLoadingFalseDispatch,
  setLearnGroupPersonalNextLoadingTrueDispatch,
} from "../../redux/learnGroupPersonalNext/learnGroupPersonalNextDispatcher";
import learnGroupPersonalNextRequest from "./learnGroupPersonalNextRequest";

export const learnGroupPersonalNextApi = (
  data: learnGroupPersonalNextRequest,
) => {
  startRequest();

  axios
    .get(API_LEARN_GROUP_PERSONAL_NEXT, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnGroupPersonalNextLoadingTrueDispatch();
};

function successful(
  response: responseInterface<learnGroupPersonalNextResponse>,
) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnGroupPersonalNextAccessFalseDispatch();
    setLearnGroupPersonalNextLoadingFalseDispatch();
    return;
  }

  setLearnGroupPersonalNextAccessTrueDispatch();
  setLearnGroupPersonalNextDispatch(response.data.wordChooseTranslate);

  setLearnGroupPersonalNextLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
