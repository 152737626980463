import { Box, Checkbox } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import formErrorsSelectors from "../../../redux/formErrors/formErrorsSelectors";
import { FormErrorsText } from "../FormErrorsText";

export interface userAgreementCheckboxPropsInterface {
  checked: boolean;
  loading: boolean;
  valueChangeHandle: CallableFunction;
}

export default function UserAgreementCheckbox(
  props: userAgreementCheckboxPropsInterface,
) {
  const errors = useSelector(formErrorsSelectors.formErrors).userAgreement;

  function changeHandle(e: any) {
    props.valueChangeHandle(e.currentTarget.value);
  }

  return (
    <>
      <Checkbox
        disabled={props.loading}
        checked={props.checked}
        onChange={changeHandle}
        name={"userAgreementCheckbox"}
      />
      <Box>
        <FormErrorsText propertyValues={errors} />
      </Box>
    </>
  );
}
