import learningWord from "../../models/learningWord";
import store from "../store";
import {
  clearWordFromText,
  setWordFromTextLoadingFalse,
  setWordFromTextLoadingTrue,
  setLearningWordFromText,
} from "./wordFromTextReduce";

export function clearWordFromTextDispatch() {
  store.dispatch(clearWordFromText());
}

export function setWordFromTextLoadingTrueDispatch() {
  store.dispatch(setWordFromTextLoadingTrue());
}

export function setWordFromTextLoadingFalseDispatch() {
  store.dispatch(setWordFromTextLoadingFalse());
}

export function setLearningWordFromTextDispatch(value: learningWord[]) {
  store.dispatch(setLearningWordFromText(value));
}
