import React from "react";
import { Box, List, ListItemButton, Paper } from "@mui/material";
import learningWord from "../../models/learningWord";

interface searchResponseComponentPropsInterface {
  dataFiltered: learningWord[];
  itemClickHandle: CallableFunction;
  addClickHandle: CallableFunction;
}

export default function SearchResponseWordComponent(
  props: searchResponseComponentPropsInterface,
) {
  return (
    <>
      <Paper
        sx={{
          position: "absolute",
          top: "50px",
          width: "100%",
          zIndex: 1,
        }}
      >
        {props.dataFiltered.length === 0 ? (
          <>
            <Box
              sx={{
                textAlign: "center",
                p: "2 0",
                color: "#9c9c9c",
                fontSize: "14px",
              }}
            >
              <ListItemButton
                onClick={() => {
                  props.addClickHandle();
                }}
              >
                {"Не найдено. Добавить?"}
              </ListItemButton>
            </Box>
          </>
        ) : (
          <List component="nav" aria-label="mailbox folders">
            {props.dataFiltered.map(
              (learningWord: learningWord, index: number) => (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    props.itemClickHandle(learningWord);
                  }}
                >
                  {learningWord.word}
                </ListItemButton>
              ),
            )}
            <ListItemButton
              sx={{
                textAlign: "center",
                p: "3",
                color: "#9c9c9c",
                fontSize: "14px",
              }}
              key={999999}
              onClick={() => {
                props.addClickHandle();
              }}
            >
              {"Нет нужного? Добавить."}
            </ListItemButton>
          </List>
        )}
      </Paper>
    </>
  );
}
