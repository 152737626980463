import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import { API_CREATE_EMAIL_AUTH } from "../../constants/apiRoutConstants";
import {
  startUpdateName,
  stopUpdateName,
  updateUserDataEmail,
} from "../../redux/userData/userDataReduce";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";
import createEmailAuthRequest from "./createEmailAuthRequest";
import createEmailAuthResponse from "./createEmailAuthResponse";

export const createEmailAuthApi = (data: createEmailAuthRequest) => {
  startRequest();

  axios
    .post(API_CREATE_EMAIL_AUTH, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful(res);
    });
};

const startRequest = () => {
  store.dispatch(startUpdateName());
};

const successful = (response: responseInterface<createEmailAuthResponse>) => {
  const stopLoadingHandler = () => {
    store.dispatch(stopUpdateName());
  };
  const handler = () => {
    store.dispatch(updateUserDataEmail(response.data));
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

const unsuccessful = (res: any) => {
  //
};
