import groupPersonalCreateRequest from "./groupPersonalcreateRequest";

export default function groupPersonalCreateData(
  title: string,
  learningWordIds: number[],
): groupPersonalCreateRequest {
  return {
    title: title,
    learningWordIds: learningWordIds,
  };
}
