import axios, { responseInterface } from "../axios";
import { API_AUTH_RESET_PASSWORD } from "../../constants/apiRoutConstants";
import store from "../../redux/store";
import {
  authLoadingFalse,
  authLoadingTrue,
  sendResetPasswordSuccessTrue,
} from "../../redux/userData/userDataReduce";
import resetPasswordRequest from "./resetPasswordRequest";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";

export const resetPasswordApi = (data: resetPasswordRequest) => {
  startRequest();
  axios
    .post(API_AUTH_RESET_PASSWORD, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful();
    });
};

const startRequest = () => {
  store.dispatch(authLoadingTrue());
};

export const successful = (response: responseInterface<any>) => {
  const stopLoadingHandler = () => {
    store.dispatch(authLoadingFalse());
  };
  const handler = () => {
    store.dispatch(sendResetPasswordSuccessTrue());
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

export const unsuccessful = () => {
  store.dispatch(authLoadingFalse());
};
