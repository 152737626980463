import { storeInterface } from "../store";

const userGeneratePayLoading = (state: storeInterface) =>
  state.userGeneratePay.userGeneratePayLoading;
const payUrl = (state: storeInterface) => state.userGeneratePay.payUrl;

export default {
  userGeneratePayLoading,
  payUrl,
};
