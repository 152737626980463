import { createSlice } from "@reduxjs/toolkit";
import selectValueWord from "../../models/selectValueWord";

export interface learnExamProcessStoreInterface {
  complete: boolean | null;
  selectValueWordArray: selectValueWord[];
  learnExamProcessLoading: boolean;
  learnExamProcessAccess: boolean;
}

export const initData: learnExamProcessStoreInterface = {
  complete: null,
  selectValueWordArray: [],
  learnExamProcessLoading: false,
  learnExamProcessAccess: true,
};

export const learnExamProcessReduceSlice = createSlice({
  name: "learnExamProcessReduce",
  initialState: initData,
  reducers: {
    clearLearnExamProcess: (state: learnExamProcessStoreInterface) => {
      state.complete = initData.complete;
      state.selectValueWordArray = initData.selectValueWordArray;
      state.learnExamProcessLoading = initData.learnExamProcessLoading;
      state.learnExamProcessAccess = initData.learnExamProcessAccess;
    },
    setCompleteTrue: (state: learnExamProcessStoreInterface) => {
      state.complete = true;
    },
    setCompleteFalse: (state: learnExamProcessStoreInterface) => {
      state.complete = false;
    },
    setSelectValueWordArray: (
      state: learnExamProcessStoreInterface,
      action: {
        type: string;
        payload: selectValueWord[];
      },
    ) => {
      state.selectValueWordArray = action.payload;
    },
    setLearnExamProcessLoadingTrue: (state: learnExamProcessStoreInterface) => {
      state.learnExamProcessLoading = true;
    },
    setLearnExamProcessLoadingFalse: (
      state: learnExamProcessStoreInterface,
    ) => {
      state.learnExamProcessLoading = false;
    },
    setLearnExamProcessAccessTrue: (state: learnExamProcessStoreInterface) => {
      state.learnExamProcessAccess = true;
    },
    setLearnExamProcessAccessFalse: (state: learnExamProcessStoreInterface) => {
      state.learnExamProcessAccess = false;
    },
  },
});

export const {
  clearLearnExamProcess,
  setCompleteTrue,
  setCompleteFalse,
  setSelectValueWordArray,
  setLearnExamProcessLoadingTrue,
  setLearnExamProcessLoadingFalse,
  setLearnExamProcessAccessTrue,
  setLearnExamProcessAccessFalse,
} = learnExamProcessReduceSlice.actions;

export default learnExamProcessReduceSlice.reducer;
