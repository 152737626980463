import { alertAppError } from "../../constants/alertConstants";
import { API_WORDS_FROM_TEXT } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearningWordFromTextDispatch,
  setWordFromTextLoadingFalseDispatch,
  setWordFromTextLoadingTrueDispatch,
} from "../../redux/wordFromText/wordFromTextDispatcher";
import axios, { responseInterface } from "../axios";
import wordsFromTextRequest from "./wordsFromTextRequest";
import groupPersonalListResponse from "./wordsFromTextResponse";

export default function wordsFromTextApi(data: wordsFromTextRequest) {
  startRequest();

  axios
    .post(API_WORDS_FROM_TEXT, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setWordFromTextLoadingTrueDispatch();
  setLearningWordFromTextDispatch([]);
};

const successful = (response: responseInterface<groupPersonalListResponse>) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertAppError);
    setWordFromTextLoadingFalseDispatch();
    return;
  }

  setLearningWordFromTextDispatch(response.data.learningWords);

  setWordFromTextLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
