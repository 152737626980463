import store from "../store";
import {
  setAppDataLoadingFalse,
  setAppDataLoadingTrue,
  setAutoPlaySoundFalse,
  setAutoPlaySoundTrue,
  setEnglishContentVariant,
  setSubscribeCost,
  setSupportEmail,
} from "./appReduce";

export function setAppDataLoadingTrueDispatch() {
  store.dispatch(setAppDataLoadingTrue());
}

export function setAppDataLoadingFalseDispatch() {
  store.dispatch(setAppDataLoadingFalse());
}

export function setAutoPlaySoundTrueDispatch() {
  store.dispatch(setAutoPlaySoundTrue());
}

export function setAutoPlaySoundFalseDispatch() {
  store.dispatch(setAutoPlaySoundFalse());
}

export function setEnglishContentVariantDispatch(
  value: "british" | "american",
) {
  store.dispatch(setEnglishContentVariant(value));
}

export function setSubscribeCostDispatch(value: number) {
  store.dispatch(setSubscribeCost(value));
}

export function setSupportEmailDispatch(value: string) {
  store.dispatch(setSupportEmail(value));
}
