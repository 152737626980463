import React from "react";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTE_SETTINGS } from "../../constants/routesConstants";

function ConfirmEmailInfoComponent() {
  const userData = useSelector(userDataSelectors.userData);

  return (
    <>
      {userData.email !== "" && userData.confirmEmail === false ? (
        <>
          <Alert severity="warning">
            {"Подтвердите E-mail адрес в "}
            <Link to={ROUTE_SETTINGS}>{"настройках"}</Link>
            {". Без подтверждения действует ограничение на изучение не более " +
              userData.freeWordCountWithoutConfirmEmail +
              " слов."}
          </Alert>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default ConfirmEmailInfoComponent;
