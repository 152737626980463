import { Box, ClickAwayListener, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import learningWord from "../../models/learningWord";
import { useSelector } from "react-redux";
import wordSearchSelectors from "../../redux/wordSearch/wordSearchSelectors";
import wordFindData from "../../api/wordFind/wordFindData";
import wordFindApi from "../../api/wordFind/wordFindApi";
import {
  clearWordSearch,
  setWordSearchLoadingFalse,
  setWordSearchLoadingTrue,
  setWordSearchResponse,
} from "../../redux/wordSearch/wordSearchReduce";
import store from "../../redux/store";
import SearchBarComponent from "./SearchBarComponent";
import LoadingComponent from "./LoadingComponent";
import SearchResponseWordComponent from "./SearchResponseWordComponent";
import wordCreateData from "../../api/wordCreate/wordCreateData";
import wordCreateApi from "../../api/wordCreate/wordCreateApi";
import wordCrateSelectors from "../../redux/wordCreate/wordCrateSelectors";
import { clearWordCreateDispatch } from "../../redux/wordCreate/wordCreateDispatcher";

interface WordSearchBarComponentProps {
  clickItem: CallableFunction;
  disabled: boolean;
}

export default function WordSearchBarComponent(
  props: WordSearchBarComponentProps,
) {
  const [searchQuery, setSearchQuery] = useState("");
  const wordSearchResponse = useSelector(
    wordSearchSelectors.wordSearchResponse,
  );
  const loading = useSelector(wordSearchSelectors.wordSearchLoading);
  const wordCreateLoading = useSelector(wordCrateSelectors.wordCreateLoading);
  const wordCreateLearningWord = useSelector(wordCrateSelectors.learningWord);

  useEffect(() => {
    return () => {
      clearWordCreateDispatch();
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim().length) {
      wordSearchLoadingTrue();

      const timer = setTimeout(() => {
        const data = wordFindData(searchQuery);
        wordFindApi(data);
      }, 400);
      return () => clearTimeout(timer);
    } else {
      wordSearchLoadingFalse();
      searchFieldOnBlur();
    }
  }, [searchQuery]);

  useEffect(() => {
    if (wordCreateLearningWord !== null) {
      itemClickHandle(wordCreateLearningWord);
    }
  }, [wordCreateLearningWord]);

  function wordSearchLoadingTrue() {
    store.dispatch(setWordSearchLoadingTrue());
  }

  function wordSearchLoadingFalse() {
    store.dispatch(setWordSearchLoadingFalse());
  }

  function searchFieldOnInput(string: string) {
    setSearchQuery(string);
  }

  function searchFieldOnBlur() {
    store.dispatch(clearWordSearch());
    // store.dispatch(clearWordSearch());
  }

  function itemClickHandle(learningWord: learningWord) {
    props.clickItem(learningWord);
    store.dispatch(clearWordSearch());
    setSearchQuery("");
  }

  function addClickHandle() {
    const data = wordCreateData(searchQuery);
    wordCreateApi(data);
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          searchFieldOnBlur();
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <TextField
            id="search-bar"
            onInput={(e: any) => {
              searchFieldOnInput(e.target.value);
            }}
            // onBlur={searchFieldOnBlur}
            value={searchQuery}
            label="Добавить слово в словарь"
            type="search"
            variant="standard"
            size="small"
            sx={{ width: "100%" }}
            disabled={props.disabled}
          />
          {!wordCreateLoading && !loading && wordSearchResponse !== null ? (
            <SearchResponseWordComponent
              dataFiltered={wordSearchResponse}
              itemClickHandle={itemClickHandle}
              addClickHandle={addClickHandle}
            />
          ) : (
            ""
          )}
          {loading || wordCreateLoading ? <LoadingComponent /> : ""}
        </Box>
      </ClickAwayListener>
    </>
  );
}
