import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import {
  startUpdateEmail,
  stopUpdateEmail,
  updateUserDataEmail,
} from "../../redux/userData/userDataReduce";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";
import { API_UPDATE_EMAIL } from "../../constants/apiRoutConstants";
import { updateEmailRequest } from "./updateEmailRequest";
import { updateEmailResponse } from "./updateEmailResponse";

export const updateEmailApi = (data: updateEmailRequest) => {
  startRequest();

  axios
    .post(API_UPDATE_EMAIL, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful(res);
    });
};

const startRequest = () => {
  store.dispatch(startUpdateEmail());
};

const successful = (response: responseInterface<updateEmailResponse>) => {
  const stopLoadingHandler = () => {
    store.dispatch(stopUpdateEmail());
  };
  const handler = () => {
    store.dispatch(updateUserDataEmail(response.data));
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

const unsuccessful = (res: any) => {
  store.dispatch(stopUpdateEmail());
};
