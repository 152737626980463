import store from "../store";
import {
  clearGroupPersonalDelete,
  setGroupPersonalDeleteLoadingFalse,
  setGroupPersonalDeleteLoadingTrue,
  setGroupPersonalDeleteComplete,
} from "./groupPersonalDeleteReduce";

export function clearGroupPersonalDeleteDispatch() {
  store.dispatch(clearGroupPersonalDelete());
}

export function setGroupPersonalDeleteLoadingTrueDispatch() {
  store.dispatch(setGroupPersonalDeleteLoadingTrue());
}

export function setGroupPersonalDeleteLoadingFalseDispatch() {
  store.dispatch(setGroupPersonalDeleteLoadingFalse());
}

export function setGroupPersonalDeleteCompleteDispatch(value: boolean | null) {
  store.dispatch(setGroupPersonalDeleteComplete(value));
}
