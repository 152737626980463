import { alertAppError } from "../../constants/alertConstants";
import { API_WORD_FIND } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import store from "../../redux/store";
import {
  setWordSearchLoadingFalse,
  setWordSearchLoadingTrue,
  setWordSearchResponse,
} from "../../redux/wordSearch/wordSearchReduce";
import axios, { responseInterface } from "../axios";
import { wordFindRequest } from "./wordFindRequest";
import { wordFindResponse } from "./wordFindResponse";

export default function wordFindApi(data: wordFindRequest) {
  startRequest();

  axios
    .get(API_WORD_FIND, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  store.dispatch(setWordSearchLoadingTrue());
};

const successful = (response: responseInterface<wordFindResponse>) => {
  store.dispatch(setWordSearchResponse(response.data.learningWords));

  store.dispatch(setWordSearchLoadingFalse());
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
