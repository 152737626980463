import React from "react";
import { Route, Routes } from "react-router-dom";

import {
  ROUTE_AGREEMENT,
  ROUTE_BALANCE,
  ROUTE_CONFIRM_EMAIL,
  ROUTE_CONTACTS,
  ROUTE_FILL_USER_DATA,
  ROUTE_GROUPS,
  ROUTE_GROUPS_AVAILABLE,
  ROUTE_GROUPS_ENVIRONMENT,
  ROUTE_GROUPS_PERSONAL,
  ROUTE_GROUP_ADD,
  ROUTE_GROUP_ADD_EXAM,
  ROUTE_GROUP_ADD_FROM_TEXT,
  ROUTE_GROUP_ADD_FROM_WORDS,
  ROUTE_GROUP_EDIT,
  ROUTE_GROUP_EDIT_TEST,
  ROUTE_GROUP_EDIT_TESTS,
  ROUTE_GROUP_EXAM_EDIT,
  ROUTE_GROUP_PAY,
  ROUTE_GROUP_PERSONAL_ADD,
  ROUTE_GROUP_PERSONAL_EDIT,
  ROUTE_GROUP_TEST_ADD,
  ROUTE_GROUP_TEST_ADD_FROM_TEXT,
  ROUTE_GROUP_TEST_ADD_FROM_WORDS,
  ROUTE_HOME,
  ROUTE_LEARN,
  ROUTE_LEARN_EXAM_GROUP,
  ROUTE_LEARN_GROUP,
  ROUTE_LEARN_GROUP_PERSONAL,
  ROUTE_LEARN_TEST_GROUP,
  ROUTE_PAY,
  ROUTE_PRIVACY,
  ROUTE_PROFILE,
  ROUTE_RESET_PASSWORD,
  ROUTE_SERVICES_AND_PAYMENT,
  ROUTE_SETTINGS,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_SUPPORT,
  ROUTE_USER_UNSUBSCRIBE,
  ROUTE_WORKOUT,
} from "../constants/routesConstants";
import IndexPage from "../containers/pages/IndexPage";
import SignInPage from "../containers/pages/SignInPage";
import SignUpPage from "../containers/pages/SignUpPage";
import GroupsPage from "../containers/pages/GroupsPage";
import ResetPasswordPage from "../containers/pages/ResetPasswordPage";
import middleware from "./RouteMiddleware";
import LearnPage from "../containers/pages/LearnPage";
import GroupPayPage from "../containers/pages/GroupPayPage";
import GroupAddPage from "../containers/pages/GroupAddPage";
import GroupEditPage from "../containers/pages/GroupEditPage";
import GroupAddFromTextPage from "../containers/pages/GroupAddFromTextPage";
import GroupAddFromWordsPage from "../containers/pages/GroupAddFromWordsPage";
import GroupTestsPage from "../containers/pages/GroupTestsPage";
import GroupEditTestPage from "../containers/pages/GroupEditTestPage";
import GroupTestAddPage from "../containers/pages/GroupTestAddPage";
import GroupTestAddFromTextPage from "../containers/pages/GroupTestAddFromTextPage";
import GroupTestAddFromWordsPage from "../containers/pages/GroupTestAddFromWordsPage";
import GroupTestAddExamPage from "../containers/pages/GroupTestAddExamPage";
import GroupTestEditExamPage from "../containers/pages/GroupTestEditExamPage";
import SettingsPage from "../containers/pages/SettingsPage";
import BalancePage from "../containers/pages/BalancePage";
import ProfilePage from "../containers/pages/ProfilePage";
import FillUserDataPage from "../containers/pages/FillUserDataPage";
import NotFoundPage from "../containers/pages/NotFoundPage";
import PrivacyPage from "../containers/pages/PrivacyPage";
import AgreementPage from "../containers/pages/AgreementPage";
import LearnTestGroupPage from "../containers/pages/LearnTestGroupPage";
import LearnExamGroupPage from "../containers/pages/LearnExamGroupPage";
import GroupsPersonalPage from "../containers/pages/GroupsPersonalPage";
import GroupsAvailablePage from "../containers/pages/GroupsAvailablePage";
import GroupEnvironmentPage from "../containers/pages/GroupEnvironmentPage";
import SupportPage from "../containers/pages/SupportPage";
import GroupWorkoutPage from "../containers/pages/GroupWorkoutPage";
import LearnGroupPage from "../containers/pages/LearnGroupPage";
import ContactsPage from "../containers/pages/ContactsPage";
import PayPage from "../containers/pages/PayPage";
import ServicesAndPaymentPage from "../containers/pages/ServicesAndPaymentPage";
import ConfirmEmailPage from "../containers/pages/ConfirmEmailPage";
import GroupPersonalAddPage from "../containers/pages/GroupPersonalAddPage";
import GroupPersonalEditPage from "../containers/pages/GroupPersonalEditPage";
import LearnGroupPersonalPage from "../containers/pages/LearnGroupPersonalPage";
import UnsubscribePage from "../containers/pages/UnsubscribePage";

function RouteList() {
  return (
    <Routes>
      {/* <Route path={ROUTE_CONFIRM_EMAIL} element={<ConfirmEmailPage />} /> */}
      <Route
        path={ROUTE_HOME}
        element={middleware(<IndexPage />, [
          "stopMiddlewareIsNotAuthMiddleware",
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_SIGN_IN}
        element={middleware(<SignInPage />, ["toHomeIfAuthMiddleware"])}
      />
      <Route
        path={ROUTE_SIGN_UP}
        element={middleware(<SignUpPage />, ["toHomeIfAuthMiddleware"])}
      />
      {/* <Route
        path={ROUTE_GROUPS}
        element={middleware(<GroupsPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      <Route path={ROUTE_PRIVACY} element={<PrivacyPage />} />
      <Route path={ROUTE_AGREEMENT} element={<AgreementPage />} />
      <Route path={ROUTE_CONTACTS} element={<ContactsPage />} />
      <Route
        path={ROUTE_SERVICES_AND_PAYMENT}
        element={<ServicesAndPaymentPage />}
      />
      <Route
        path={ROUTE_GROUPS_PERSONAL}
        element={middleware(<GroupsPersonalPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      {/* <Route
        path={ROUTE_GROUPS_AVAILABLE}
        element={middleware(<GroupsAvailablePage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      {/* <Route path={ROUTE_GROUP_PAY} element={<GroupPayPage />} /> */}
      <Route
        path={ROUTE_RESET_PASSWORD}
        element={middleware(<ResetPasswordPage />, ["toHomeIfAuthMiddleware"])}
      />
      <Route
        path={ROUTE_LEARN}
        element={middleware(<LearnPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_LEARN_GROUP_PERSONAL}
        element={middleware(<LearnGroupPersonalPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      {/* <Route
        path={ROUTE_LEARN_GROUP}
        element={middleware(<LearnGroupPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      <Route
        path={ROUTE_PAY}
        element={middleware(<PayPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_SETTINGS}
        element={middleware(<SettingsPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      {/* <Route
        path={ROUTE_GROUP_EDIT}
        element={middleware(<GroupEditPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      {/* <Route
        path={ROUTE_PAY_PERSONAL_WORD}
        element={authMiddleware(<PayPersonalWordPage />)}
      /> */}
      <Route
        path={ROUTE_GROUP_PERSONAL_ADD}
        element={middleware(<GroupPersonalAddPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_GROUP_PERSONAL_EDIT}
        element={middleware(<GroupPersonalEditPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      {/* <Route
        path={ROUTE_GROUP_ADD_FROM_TEXT}
        element={middleware(<GroupAddFromTextPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_GROUP_ADD_FROM_WORDS}
        element={middleware(<GroupAddFromWordsPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      {/* <Route
        path={ROUTE_GROUP_EDIT_TESTS}
        element={middleware(<GroupTestsPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      {/* <Route
        path={ROUTE_GROUP_EDIT_TEST}
        element={middleware(<GroupEditTestPage />, ["authMiddleware"])}
      /> */}
      {/* <Route
        path={ROUTE_GROUP_TEST_ADD}
        element={middleware(<GroupTestAddPage />, ["authMiddleware"])}
      /> */}
      {/* <Route
        path={ROUTE_GROUP_TEST_ADD_FROM_TEXT}
        element={middleware(<GroupTestAddFromTextPage />, ["authMiddleware"])}
      /> */}
      {/* <Route
        path={ROUTE_GROUP_TEST_ADD_FROM_WORDS}
        element={middleware(<GroupTestAddFromWordsPage />, ["authMiddleware"])}
      /> */}
      {/* <Route
        path={ROUTE_GROUP_ADD_EXAM}
        element={middleware(<GroupTestAddExamPage />, ["authMiddleware"])}
      />
      <Route
        path={ROUTE_GROUP_EXAM_EDIT}
        element={middleware(<GroupTestEditExamPage />, ["authMiddleware"])}
      />
      <Route
        path={ROUTE_BALANCE}
        element={middleware(<BalancePage />, ["authMiddleware"])}
      />
      <Route
        path={ROUTE_PROFILE}
        element={middleware(<ProfilePage />, ["authMiddleware"])}
      /> */}
      <Route
        path={ROUTE_FILL_USER_DATA}
        element={middleware(<FillUserDataPage />, [
          "toNotFoundIsNotAuthMiddleware",
          "fullFillUserDataMiddleware",
        ])}
      />
      {/* <Route
        path={ROUTE_LEARN_TEST_GROUP}
        element={middleware(<LearnTestGroupPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_LEARN_EXAM_GROUP}
        element={middleware(<LearnExamGroupPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      />
      <Route
        path={ROUTE_GROUPS_ENVIRONMENT}
        element={middleware(<GroupEnvironmentPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      {/* <Route
        path={ROUTE_WORKOUT}
        element={middleware(<GroupWorkoutPage />, [
          "authMiddleware",
          "fillUserDataMiddleware",
        ])}
      /> */}
      <Route path={ROUTE_SUPPORT} element={<SupportPage />} />
      <Route path={ROUTE_USER_UNSUBSCRIBE} element={<UnsubscribePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default RouteList;
