import React from "react";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import { TITLE_PAGE_AGREEMENT } from "../../constants/titlesConstants";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";

export default function AgreementPage() {
  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_AGREEMENT} />
      </Container>
      <Container component="main" maxWidth="md">
        <Box sx={{ pl: "10px", fontSize: "12px" }}>
          {
            "Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между ИП Гераськов Евгений Александрович (далее easye.ru или Администрация) с одной стороны и пользователем сайта с другой. Сайт easye.ru не является средством массовой информации."
          }
        </Box>
        <Box sx={{ pt: "25px", pl: "10px", fontSize: "12px" }}>
          {"Используя сайт, Вы соглашаетесь с условиями данного соглашения."}
          <Box sx={{ fontWeight: "bold" }}>
            {
              "Если Вы не согласны с условиями данного соглашения, не используйте сайт easye.ru!"
            }
          </Box>
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Права и обязанности сторон"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          <Box sx={{ fontWeight: "bold" }}>{"Пользователь имеет право:"}</Box>
          <Box>{"- получать информацию на сайте"}</Box>
          <Box>
            {"- использовать информацию сайта в личных некоммерческих целях"}
          </Box>
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          <Box sx={{ fontWeight: "bold" }}>{"Администрация имеет право:"}</Box>
          <Box>
            {
              "- по своему усмотрению и необходимости создавать, изменять, отменять правила"
            }
          </Box>
          <Box>{"- ограничивать доступ к любой информации на сайте"}</Box>
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          <Box sx={{ fontWeight: "bold" }}>{"Пользователь обязуется:"}</Box>
          <Box>
            {"- обеспечивать сохранность личных данных от доступа третьих лиц"}
          </Box>
          <Box>{"- не нарушать работоспособность сайта"}</Box>
          <Box>
            {
              "- не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу"
            }
          </Box>
          <Box>
            {
              "- не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам"
            }
          </Box>
          <Box>
            {
              "- не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами"
            }
          </Box>
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          <Box sx={{ fontWeight: "bold" }}>{"Администрация обязуется:"}</Box>
          <Box>
            {
              "- поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам."
            }
          </Box>
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Ответственность сторон"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          <Box>
            {
              "- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами"
            }
          </Box>
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Условия действия Соглашения"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          <Box>
            {
              "- администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами"
            }
          </Box>
          <Box>
            {
              "Данное Соглашение вступает в силу при любом использовании данного сайта."
            }
          </Box>
          <Box>
            {"Соглашение перестает действовать при появлении его новой версии."}
          </Box>
          <Box>
            {
              "Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению."
            }
          </Box>
          <Box>
            {
              "Администрация не оповещает пользователей об изменении в Соглашении."
            }
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
