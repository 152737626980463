import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import { API_LEARN_NEXT } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnNextResponse from "./learnNextResponse";
import {
  setLearnNextAccessFalseDispatch,
  setLearnNextAccessTrueDispatch,
  setLearnNextDispatch,
  setLearnNextLoadingFalseDispatch,
  setLearnNextLoadingTrueDispatch,
} from "../../redux/learnNext/learnNextDispatcher";

export const learnNextApi = () => {
  startRequest();

  axios
    .get(API_LEARN_NEXT)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnNextLoadingTrueDispatch();
};

function successful(response: responseInterface<learnNextResponse>) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnNextAccessFalseDispatch();
    setLearnNextLoadingFalseDispatch();
    return;
  }

  setLearnNextAccessTrueDispatch();
  setLearnNextDispatch(response.data.wordChooseTranslate);

  setLearnNextLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
