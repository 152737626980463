import { createSlice } from "@reduxjs/toolkit";

export interface groupAddExamStoreInterface {
  groupAddExamAccess: boolean;
  groupAddExamLoading: boolean;
  groupAddExamSuccess: boolean | null;
}

export const initGroupExamAdd: groupAddExamStoreInterface = {
  groupAddExamAccess: true,
  groupAddExamLoading: false,
  groupAddExamSuccess: null,
};

export const groupAddExamReduceSlice = createSlice({
  name: "groupExamReduce",
  initialState: initGroupExamAdd,
  reducers: {
    clearGroupAddExam: (state: groupAddExamStoreInterface) => {
      state.groupAddExamAccess = true;
      state.groupAddExamLoading = false;
      state.groupAddExamSuccess = null;
    },
    setGroupAddExamAccessTrue: (state: groupAddExamStoreInterface) => {
      state.groupAddExamAccess = true;
    },
    setGroupAddExamAccessFalse: (state: groupAddExamStoreInterface) => {
      state.groupAddExamAccess = false;
    },
    setGroupAddExamLoadingTrue: (state: groupAddExamStoreInterface) => {
      state.groupAddExamLoading = true;
    },
    setGroupAddExamLoadingFalse: (state: groupAddExamStoreInterface) => {
      state.groupAddExamLoading = false;
    },
    setGroupAddExamSuccessTrue: (state: groupAddExamStoreInterface) => {
      state.groupAddExamSuccess = true;
    },
    setGroupAddExamSuccessFalse: (state: groupAddExamStoreInterface) => {
      state.groupAddExamSuccess = false;
    },
  },
});

export const {
  clearGroupAddExam,
  setGroupAddExamAccessTrue,
  setGroupAddExamAccessFalse,
  setGroupAddExamLoadingTrue,
  setGroupAddExamLoadingFalse,
  setGroupAddExamSuccessTrue,
  setGroupAddExamSuccessFalse,
} = groupAddExamReduceSlice.actions;

export default groupAddExamReduceSlice.reducer;
