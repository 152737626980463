import { createSlice } from "@reduxjs/toolkit";
import supportMessage from "../../models/supportMessage";

export interface supportMessagesStoreInterface {
  supportMessages: supportMessage[];
  supportMessagesLoading: boolean;
  supportMessagesAccess: boolean;
}

const initData: supportMessagesStoreInterface = {
  supportMessages: [],
  supportMessagesLoading: true,
  supportMessagesAccess: true,
};

export const supportMessagesReduceSlice = createSlice({
  name: "supportMessagesReduce",
  initialState: initData,
  reducers: {
    clearSupportMessage: (state: supportMessagesStoreInterface) => {
      state.supportMessages = initData.supportMessages;
      state.supportMessagesLoading = initData.supportMessagesLoading;
      state.supportMessagesAccess = initData.supportMessagesAccess;
    },
    setSupportMessages: (
      state: supportMessagesStoreInterface,
      action: {
        type: string;
        payload: supportMessage[];
      },
    ) => {
      state.supportMessages = action.payload;
    },
    pushSupportMessage: (
      state: supportMessagesStoreInterface,
      action: {
        type: string;
        payload: supportMessage;
      },
    ) => {
      state.supportMessages = [...state.supportMessages, action.payload];
    },
    setSupportMessagesLoadingTrue: (state: supportMessagesStoreInterface) => {
      state.supportMessagesLoading = true;
    },
    setSupportMessagesLoadingFalse: (state: supportMessagesStoreInterface) => {
      state.supportMessagesLoading = false;
    },
    setSupportMessagesAccessTrue: (state: supportMessagesStoreInterface) => {
      state.supportMessagesAccess = true;
    },
    setSupportMessagesAccessFalse: (state: supportMessagesStoreInterface) => {
      state.supportMessagesAccess = false;
    },
  },
});

export const {
  clearSupportMessage,
  setSupportMessages,
  setSupportMessagesLoadingTrue,
  pushSupportMessage,
  setSupportMessagesLoadingFalse,
  setSupportMessagesAccessTrue,
  setSupportMessagesAccessFalse,
} = supportMessagesReduceSlice.actions;

export default supportMessagesReduceSlice.reducer;
