import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupEnvironment from "../../models/learningWordGroupEnvironment";

export interface learningWordGroupEnvironmentsStoreInterface {
  learningWordGroupEnvironments: learningWordGroupEnvironment[];
  learningWordGroupEnvironmentsLoading: boolean;
}

export const initLearningWordGroupEnvironments: learningWordGroupEnvironmentsStoreInterface =
  {
    learningWordGroupEnvironments: [],
    learningWordGroupEnvironmentsLoading: true,
  };

export const learningWordGroupEnvironmentsReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initLearningWordGroupEnvironments,
  reducers: {
    clearLearningWordGroupEnvironments: (
      state: learningWordGroupEnvironmentsStoreInterface,
    ) => {
      state.learningWordGroupEnvironments = [];
      state.learningWordGroupEnvironmentsLoading = true;
    },
    setLearningWordGroupEnvironments: (
      state: learningWordGroupEnvironmentsStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupEnvironment[];
      },
    ) => {
      state.learningWordGroupEnvironments = action.payload;
    },
    pushLearningWordGroupEnvironments: (
      state: learningWordGroupEnvironmentsStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupEnvironment;
      },
    ) => {
      state.learningWordGroupEnvironments = [
        ...state.learningWordGroupEnvironments,
        action.payload,
      ];
    },
    setLearningWordGroupEnvironmentsLoadingTrue: (
      state: learningWordGroupEnvironmentsStoreInterface,
    ) => {
      state.learningWordGroupEnvironmentsLoading = true;
    },
    setLearningWordGroupEnvironmentsLoadingFalse: (
      state: learningWordGroupEnvironmentsStoreInterface,
    ) => {
      state.learningWordGroupEnvironmentsLoading = false;
    },
  },
});

export const {
  clearLearningWordGroupEnvironments,
  setLearningWordGroupEnvironments,
  pushLearningWordGroupEnvironments,
  setLearningWordGroupEnvironmentsLoadingTrue,
  setLearningWordGroupEnvironmentsLoadingFalse,
} = learningWordGroupEnvironmentsReduceSlice.actions;

export default learningWordGroupEnvironmentsReduceSlice.reducer;
