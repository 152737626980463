import React from "react";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";
import learningWordTranscription from "../../models/learningWordTranscription";
import { useSelector } from "react-redux";
import appSelectors from "../../redux/app/appSelectors";
import { ENGLISH_VARIANT_BRITISH } from "../../constants/learnConstants";
import { Box } from "@mui/material";

interface transcriptionsComponentPropsInterface {
  transcriptions: learningWordTranscription[];
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
}

export default function TranscriptionsComponent(
  props: transcriptionsComponentPropsInterface,
) {
  const latinBritish = "latinBritish";
  const latinAmerican = "latinAmerican";
  const englishContentVariant = useSelector(appSelectors.englishContentVariant);

  function transcription() {
    const selectVariant =
      englishContentVariant === ENGLISH_VARIANT_BRITISH
        ? latinBritish
        : latinAmerican;

    const indexType = props.learningWordTranscriptionTypes.findIndex(function (
      type: learningWordTranscriptionType,
    ) {
      return type.type === selectVariant;
    });

    if (indexType < 0) {
      return "";
    }

    const indexTranscription = props.transcriptions.findIndex(function (
      transcription: learningWordTranscription,
    ) {
      return (
        transcription.learning_word_transcription_type_id ===
        props.learningWordTranscriptionTypes[indexType]
          .learning_word_transcription_type_id
      );
    });

    return indexTranscription >= 0
      ? props.transcriptions[indexTranscription].transcription
      : "";
  }

  return (
    <>
      <Box component={"span"} sx={{ fontFamily: "Georgia, Times, serif" }}>
        {"[" + transcription() + "]"}
      </Box>
    </>
  );
}
