import { storeInterface } from "../store";

const userSubscribeDataLoading = (state: storeInterface) =>
  state.userSubscribeData.userSubscribeDataLoading;
const subscribeAccess = (state: storeInterface) =>
  state.userSubscribeData.subscribeAccess;
const freeAccess = (state: storeInterface) =>
  state.userSubscribeData.freeAccess;
const endPrimeTime = (state: storeInterface) =>
  state.userSubscribeData.endPrimeTime;
const freeWordCount = (state: storeInterface) =>
  state.userSubscribeData.freeWordCount;
const cost = (state: storeInterface) => state.userSubscribeData.cost;
const extendTo = (state: storeInterface) => state.userSubscribeData.extendTo;
const payments = (state: storeInterface) => state.userSubscribeData.payments;

export default {
  userSubscribeDataLoading,
  subscribeAccess,
  freeAccess,
  endPrimeTime,
  freeWordCount,
  cost,
  extendTo,
  payments,
};
