import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupEnvironment from "../../models/learningWordGroupEnvironment";
import learningWordGroup from "../../models/learningWordGroup";

export interface groupEnvironmentStoreInterface {
  learningWordGroupEnvironment: learningWordGroupEnvironment | null;
  learningWordGroups: learningWordGroup[];
  groupEnvironmentLoading: boolean;
  groupEnvironmentAccess: boolean;
}

export const initData: groupEnvironmentStoreInterface = {
  learningWordGroupEnvironment: null,
  learningWordGroups: [],
  groupEnvironmentLoading: true,
  groupEnvironmentAccess: true,
};

export const groupEnvironmentReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initData,
  reducers: {
    clearLearningWordGroupEnvironment: (
      state: groupEnvironmentStoreInterface,
    ) => {
      state.learningWordGroupEnvironment =
        initData.learningWordGroupEnvironment;
      state.learningWordGroups = initData.learningWordGroups;
      state.groupEnvironmentLoading = initData.groupEnvironmentLoading;
      state.groupEnvironmentAccess = initData.groupEnvironmentAccess;
    },
    setLearningWordGroupEnvironment: (
      state: groupEnvironmentStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupEnvironment;
      },
    ) => {
      state.learningWordGroupEnvironment = action.payload;
    },
    setLearningWordGroups: (
      state: groupEnvironmentStoreInterface,
      action: {
        type: string;
        payload: learningWordGroup[];
      },
    ) => {
      state.learningWordGroups = action.payload;
    },
    setGroupEnvironmentLoadingTrue: (state: groupEnvironmentStoreInterface) => {
      state.groupEnvironmentLoading = true;
    },
    setGroupEnvironmentLoadingFalse: (
      state: groupEnvironmentStoreInterface,
    ) => {
      state.groupEnvironmentLoading = false;
    },
    setGroupEnvironmentAccessTrue: (state: groupEnvironmentStoreInterface) => {
      state.groupEnvironmentAccess = true;
    },
    setGroupEnvironmentAccessFalse: (state: groupEnvironmentStoreInterface) => {
      state.groupEnvironmentAccess = false;
    },
  },
});

export const {
  clearLearningWordGroupEnvironment,
  setLearningWordGroupEnvironment,
  setLearningWordGroups,
  setGroupEnvironmentLoadingTrue,
  setGroupEnvironmentLoadingFalse,
  setGroupEnvironmentAccessTrue,
  setGroupEnvironmentAccessFalse,
} = groupEnvironmentReduceSlice.actions;

export default groupEnvironmentReduceSlice.reducer;
