import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import WordSearchBarComponent from "../../searchBar/WordSearchBarComponent";
import learningWord from "../../../models/learningWord";
import wordListItem from "../../../models/wordListItem";
import { useSelector } from "react-redux";
import actionWordGroupSelectors from "../../../redux/actionWordGroup/actionWordGroupSelectors";
import store from "../../../redux/store";
import { setWordList } from "../../../redux/actionWordGroup/actionWordGroupReduce";
import WordsListComponent from "./WordsListComponent";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface propsInterface {
  disabled: boolean;
}

export default function GroupPersonalWordListComponent(
  props: propsInterface = {
    disabled: false,
  },
) {
  const [isIssetNotPublic, setIsIssetNotPublic] = useState(false);
  const [countNotPublic, setCountNotPublic] = useState(0);
  const wordList = useSelector(actionWordGroupSelectors.wordList);

  useEffect(() => {
    const countNotPublic = wordList.filter((item: wordListItem) => {
      return item.learningWord.public === 0;
    }).length;

    setCountNotPublic(countNotPublic);
    setIsIssetNotPublic(countNotPublic > 0);
  }, [wordList]);

  function pushItem(learningWord: learningWord) {
    const newItems = copyWordList(wordList);
    const matches = findMatches(learningWord, newItems);

    if (isMatches(matches)) {
      newItems.push({ learningWord: learningWord, remove: false });
      setNewWordList(newItems);
    }
  }

  function copyWordList(wordList: wordListItem[]) {
    return Array.from(wordList);
  }

  function findMatches(learningWord: learningWord, wordList: wordListItem[]) {
    return wordList.filter((wordListItem: wordListItem) => {
      if (
        wordListItem.learningWord.learning_word_id ===
        learningWord.learning_word_id
      ) {
        return true;
      }
    });
  }

  function isMatches(matches: wordListItem[]) {
    return matches.length === 0;
  }

  function setNewWordList(array: wordListItem[]) {
    store.dispatch(setWordList(array));
  }

  return (
    <>
      {isIssetNotPublic === true ? (
        <Box sx={{ fontSize: 12, bgcolor: "#f1f1f1", p: 1, mt: 3 }}>
          <Box sx={{ textAlign: "center" }}>
            <WarningAmberIcon sx={{ color: "error.main" }} />
          </Box>
          {"Помеченные таким образом слова " +
            "должны быть проверены администратором. " +
            "Приступить к изучению не получится. " +
            "Проверка займет какое-то время."}
          <Box sx={{ mt: 1, fontWeight: "bold" }}>
            {"Всего помеченных слов: " + countNotPublic}
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Box sx={{ mt: "15px;" }}>
        <WordSearchBarComponent
          clickItem={pushItem}
          disabled={props.disabled}
        />
        <WordsListComponent
          items={wordList}
          setItems={setNewWordList}
          disabled={props.disabled}
        />
      </Box>
    </>
  );
}
