import { createSlice } from "@reduxjs/toolkit";
import learningUserGroupProcess from "../../models/learningUserGroupProcess";
import selectValueWord from "../../models/selectValueWord";

export interface learnProcessStoreInterface {
  learnProcessAccess: boolean;
  learningUserGroupProcess: learningUserGroupProcess | null;
  learnProcessLoading: boolean;
  success: boolean | null;
  selectValueWord: selectValueWord | null;
}

const initStore: learnProcessStoreInterface = {
  learnProcessAccess: false,
  learningUserGroupProcess: null,
  learnProcessLoading: false,
  success: null,
  selectValueWord: null,
};

export const learnProcessReduceSlice = createSlice({
  name: "learnProcessReduce",
  initialState: initStore,
  reducers: {
    clearLearnProcess: (state: learnProcessStoreInterface) => {
      state.learnProcessAccess = initStore.learnProcessAccess;
      state.learningUserGroupProcess = initStore.learningUserGroupProcess;
      state.learnProcessLoading = initStore.learnProcessLoading;
      state.success = initStore.success;
      state.selectValueWord = initStore.selectValueWord;
    },
    setLearnProcessAccessTrue: (state: learnProcessStoreInterface) => {
      state.learnProcessAccess = true;
    },
    setLearnProcessAccessFalse: (state: learnProcessStoreInterface) => {
      state.learnProcessAccess = false;
    },
    setLearningUserGroupProcess: (
      state: learnProcessStoreInterface,
      actions: { payload: learningUserGroupProcess; type: string },
    ) => {
      state.learningUserGroupProcess = actions.payload;
    },
    learnProcessLoadingTrue: (state: learnProcessStoreInterface) => {
      state.learnProcessLoading = true;
    },
    learnProcessLoadingFalse: (state: learnProcessStoreInterface) => {
      state.learnProcessLoading = false;
    },
    setSuccessNull: (state: learnProcessStoreInterface) => {
      state.success = null;
    },
    setSuccessTrue: (state: learnProcessStoreInterface) => {
      state.success = true;
    },
    setSuccessFalse: (state: learnProcessStoreInterface) => {
      state.success = false;
    },
    setLearningUserGroupProcessChooseCount: (
      state: learnProcessStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      if (state.learningUserGroupProcess) {
        state.learningUserGroupProcess.learn_choose_count = actions.payload;
      }
    },
    setLearningUserGroupProcessFinishChooseCount: (
      state: learnProcessStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      if (state.learningUserGroupProcess) {
        state.learningUserGroupProcess.learn_finish_choose_count =
          actions.payload;
      }
    },
    setSelectValueWord: (
      state: learnProcessStoreInterface,
      actions: { payload: selectValueWord; type: string },
    ) => {
      state.selectValueWord = actions.payload;
    },
    setSelectValueWordNull: (state: learnProcessStoreInterface) => {
      state.selectValueWord = null;
    },
    setSelectValueWordSuccessTrue: (state: learnProcessStoreInterface) => {
      if (state.selectValueWord) {
        state.selectValueWord.success = true;
      }
    },
    setSelectValueWordSuccessFalse: (state: learnProcessStoreInterface) => {
      if (state.selectValueWord) {
        state.selectValueWord.success = false;
      }
    },
  },
});

export const {
  clearLearnProcess,
  setLearnProcessAccessTrue,
  setLearnProcessAccessFalse,
  setLearningUserGroupProcess,
  learnProcessLoadingTrue,
  learnProcessLoadingFalse,
  setSuccessNull,
  setSuccessTrue,
  setSuccessFalse,
  setLearningUserGroupProcessChooseCount,
  setLearningUserGroupProcessFinishChooseCount,
  setSelectValueWord,
  setSelectValueWordNull,
  setSelectValueWordSuccessTrue,
  setSelectValueWordSuccessFalse,
} = learnProcessReduceSlice.actions;

export default learnProcessReduceSlice.reducer;
