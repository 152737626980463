import axios, { responseInterface } from "../axios";
import { API_APP_DATA } from "../../constants/apiRoutConstants";
import appDataResponse from "./appDataResponse";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import { alertAppError } from "../../constants/alertConstants";
import {
  setAppDataLoadingFalseDispatch,
  setAppDataLoadingTrueDispatch,
  setSubscribeCostDispatch,
  setSupportEmailDispatch,
} from "../../redux/app/appDispatcher";

export default function appDataApi() {
  startRequest();

  axios
    .get(API_APP_DATA)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setAppDataLoadingTrueDispatch();
};

const successful = (response: responseInterface<appDataResponse>) => {
  setSubscribeCostDispatch(response.data.subscribeCost);
  setSupportEmailDispatch(response.data.supportEmail);
  setAppDataLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
