import { Box, Divider, Grid, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import userDataSelectors from "../../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";
import EmailInput from "../../forms/inputs/EmailInput";
import { updateEmailHandle } from "../../../services/updateEmail/updateEmailService";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EmailConfirmCodeInput from "../../forms/inputs/EmailConfirmCodeInput";
import DoneIcon from "@mui/icons-material/Done";
import userConfirmEmailData from "../../../api/userConfirmEmail/userConfirmEmailData";
import { userConfirmEmailApi } from "../../../api/userConfirmEmail/userConfirmEmailApi";
import userConfirmEmailSelectors from "../../../redux/userConfirmEmail/userConfirmEmailSelectors";
import userDataApi from "../../../api/userData/userDataApi";
import { clearUserConfirmEmailDispatch } from "../../../redux/userConfirmEmail/userConfirmEmailDispatcher";
import { setConfirmEmailCodeErrorDispatch } from "../../../redux/formErrors/formErrorsDispatcher";

export default function SettingEmailChangeLayout() {
  const userData = useSelector(userDataSelectors.userData);
  const [emailOld, setEmailOld] = useState(userData.email);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState(userData.email);
  const [confirmCode, setConfirmCode] = useState("");
  const updateEmailLoading = useSelector(userDataSelectors.updateEmailLoading);
  const userConfirmEmailLoading = useSelector(
    userConfirmEmailSelectors.userConfirmEmailLoading,
  );
  const userConfirmEmailComplete = useSelector(
    userConfirmEmailSelectors.userConfirmEmailComplete,
  );

  useEffect(() => {
    if (emailOld !== userData.email) {
      userDataApi();
    }
  }, [userData.email]);

  useEffect(() => {
    if (userConfirmEmailComplete === true) {
      userDataApi();
    }

    if (userConfirmEmailComplete === false) {
      setConfirmEmailCodeErrorDispatch(["Код введен неверно"]);
    }

    return () => {
      clearUserConfirmEmailDispatch();
    };
  }, [userConfirmEmailComplete]);

  function changeHandle(email: string) {
    setEmail(email);
  }

  function changeConfirmCodeHandle(confirmCode: string) {
    setConfirmCode(confirmCode);
  }

  function handle() {
    if (emailOld === email) {
      return;
    }

    setSuccess(false);
    updateEmailHandle(email);
  }

  function confirmCodeHandle() {
    if (confirmCode.length !== 0) {
      const data = userConfirmEmailData(confirmCode);
      userConfirmEmailApi(data);
    }
  }

  return (
    <>
      <Divider textAlign="left" sx={{ mt: "25px", mb: 1, fontSize: "18px" }}>
        {"Email-адрес"}
      </Divider>
      <Grid container>
        <Grid
          xs={4}
          item
          sx={{
            placeSelf: "top",
            pt: "15px",
            pr: "10px",
            textAlign: "right",
            fontWeight: "bold",
            fontSize: "14px",
          }}
        >
          {"Email:"}
        </Grid>
        <Grid xs={6} item>
          <EmailInput
            email={email}
            loading={updateEmailLoading}
            valueChangeHandle={changeHandle}
          />
        </Grid>
        <Grid xs={2} item sx={{ placeSelf: "center", pl: 2 }}>
          {userData.confirmEmail === true ? (
            <DoneIcon sx={{ color: "success.main" }} />
          ) : (
            <Tooltip title={"E-mail не подтвержден"} enterTouchDelay={0}>
              <ErrorOutlineIcon sx={{ color: "error.main" }} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      {success ? (
        <Box sx={{ color: "green", textAlign: "center" }}>
          {"Сохранено, код подтверждения отправлен"}
        </Box>
      ) : (
        ""
      )}
      <Box sx={{ textAlign: "center" }}>
        <LoadingButton
          loading={updateEmailLoading}
          variant="contained"
          onClick={handle}
        >
          {"Сохранить"}
        </LoadingButton>
      </Box>
      {userData.confirmEmail === false ? (
        <>
          <Divider textAlign="left" sx={{ mt: 3, mb: 1 }}></Divider>
          <Box sx={{ mt: 1, color: "text.secondary" }}>
            {
              "Введите код подтверждения, который был отправлен на указанный e-mail адрес"
            }
          </Box>
          <Grid container sx={{ mt: 2 }}>
            <Grid
              xs={4}
              item
              sx={{
                placeSelf: "top",
                pt: "15px",
                pr: "10px",
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {"Код подтверждения:"}
            </Grid>
            <Grid xs={6} item>
              <EmailConfirmCodeInput
                confirmCode={confirmCode}
                loading={userConfirmEmailLoading}
                valueChangeHandle={changeConfirmCodeHandle}
              />
            </Grid>
          </Grid>
          <Box sx={{ textAlign: "center" }}>
            <LoadingButton
              loading={userConfirmEmailLoading}
              variant="contained"
              onClick={confirmCodeHandle}
            >
              {"Подтвердить"}
            </LoadingButton>
          </Box>
        </>
      ) : (
        ""
      )}
    </>
  );
}
