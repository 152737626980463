import { storeInterface } from "../store";

const groupPersonalCreateLoading = (state: storeInterface) =>
  state.groupPersonalCreate.groupPersonalCreateLoading;
const learningWordGroupPersonalId = (state: storeInterface) =>
  state.groupPersonalCreate.learningWordGroupPersonalId;

export default {
  groupPersonalCreateLoading,
  learningWordGroupPersonalId,
};
