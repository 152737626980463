import { createSlice } from "@reduxjs/toolkit";
import chooseValueWord from "../../models/chooseValueWord";

export interface workoutNextWordStoreInterface {
  workoutNextWord: chooseValueWord | null;
  workoutNextWordLoading: boolean;
  workoutNextWordAccess: boolean;
}

const initStore: workoutNextWordStoreInterface = {
  workoutNextWord: null,
  workoutNextWordLoading: true,
  workoutNextWordAccess: true,
};

export const workoutNextWordReduceSlice = createSlice({
  name: "workoutNextWordReduce",
  initialState: initStore,
  reducers: {
    clearWorkoutNextWord: (state: workoutNextWordStoreInterface) => {
      state.workoutNextWord = initStore.workoutNextWord;
      state.workoutNextWordLoading = initStore.workoutNextWordLoading;
      state.workoutNextWordAccess = initStore.workoutNextWordAccess;
    },
    setWorkoutNextWordAccessTrue: (state: workoutNextWordStoreInterface) => {
      state.workoutNextWordAccess = true;
    },
    setWorkoutNextWordAccessFalse: (state: workoutNextWordStoreInterface) => {
      state.workoutNextWordAccess = false;
    },
    setWorkoutNextWord: (
      state: workoutNextWordStoreInterface,
      actions: { payload: chooseValueWord; type: string },
    ) => {
      state.workoutNextWord = actions.payload;
    },
    workoutNextWordLoadingTrue: (state: workoutNextWordStoreInterface) => {
      state.workoutNextWordLoading = true;
    },
    workoutNextWordLoadingFalse: (state: workoutNextWordStoreInterface) => {
      state.workoutNextWordLoading = false;
    },
  },
});

export const {
  clearWorkoutNextWord,
  setWorkoutNextWordAccessTrue,
  setWorkoutNextWordAccessFalse,
  setWorkoutNextWord,
  workoutNextWordLoadingTrue,
  workoutNextWordLoadingFalse,
} = workoutNextWordReduceSlice.actions;

export default workoutNextWordReduceSlice.reducer;
