import { storeInterface } from "../store";

const userData = (state: storeInterface) => state.userData.userData;
const userDataLoading = (state: storeInterface) =>
  state.userData.userDataLoading;
const updateNameLoading = (state: storeInterface) =>
  state.userData.updateNameLoading;
const updateEmailLoading = (state: storeInterface) =>
  state.userData.updateEmailLoading;
const updatePasswordLoading = (state: storeInterface) =>
  state.userData.updatePasswordLoading;
const updatePasswordSuccess = (state: storeInterface) =>
  state.userData.updatePasswordSuccess;
const updateSocialAuthLoading = (state: storeInterface) =>
  state.userData.updateSocialAuthLoading;
const sendResetPasswordSuccess = (state: storeInterface) =>
  state.userData.sendResetPasswordSuccess;
const authLoading = (state: storeInterface) => state.userData.authLoading;
const newGroupEnvironmentLoading = (state: storeInterface) =>
  state.userData.newGroupEnvironmentLoading;

export default {
  userData,
  userDataLoading,
  updateNameLoading,
  updateEmailLoading,
  updatePasswordLoading,
  updatePasswordSuccess,
  updateSocialAuthLoading,
  sendResetPasswordSuccess,
  authLoading,
  newGroupEnvironmentLoading,
};
