import { responseInterface } from "../../api/axios";
import {
  CODE_BAD_CURRENT_PASSWORD,
  CODE_BAD_CURRENT_PASSWORD_TEXT,
  CODE_EMAIL_FORMAT,
  CODE_EMAIL_FORMAT_TEXT,
  CODE_FORM_ERRORS,
  CODE_INT_ONLY,
  CODE_INT_ONLY_TEXT,
  CODE_MAX_FORMAT,
  CODE_MAX_FORMAT_TEXT,
  CODE_MIN_FORMAT,
  CODE_MIN_FORMAT_TEXT,
  CODE_NOT_EXIST,
  CODE_NOT_EXIST_TEXT,
  CODE_NOT_SAME,
  CODE_NOT_SAME_TEXT,
  CODE_REQUIRE,
  CODE_REQUIRE_ADDITIONAL_AGREEMENTS,
  CODE_REQUIRE_ADDITIONAL_AGREEMENTS_TEXT,
  CODE_REQUIRE_TEXT,
  CODE_STATUS_UNAUTHORIZED,
  CODE_STATUS_UNAUTHORIZED_TEXT,
  CODE_STRING_ONLY,
  CODE_STRING_ONLY_TEXT,
  CODE_STRING_REGEX,
  CODE_STRING_REGEX_TEXT,
  CODE_UNIQUE,
  CODE_UNIQUE_TEXT,
} from "../../constants/errorCodes";
import { alertCloseDispatch } from "../../redux/alert/alertDispatcher";
import {
  clearFromErrorsStore,
  formErrorsStoreInterface,
  setEmailFormError,
  setFormErrors,
  setNameFormError,
  setPasswordFormError,
  setCurrentPasswordFormError,
  setPasswordRepeatFormError,
  setUserAgreementFormError,
  setPrivacyPolicyFormError,
  setTitleFormError,
} from "../../redux/formErrors/formErrorsReduce";
import store from "../../redux/store";
import {
  authLoadingFalse,
  sendResetPasswordSuccessFalse,
  updatePasswordSuccessFalse,
} from "../../redux/userData/userDataReduce";

export const successApiResponseHandle = (
  response: responseInterface<any>,
  stopLoadingHandler: CallableFunction,
  handler: CallableFunction,
) => {
  const statusCode = response.statusCode;

  stopLoadingHandler();
  if (isFormError(statusCode)) {
    formErrorHandle(response.data);
    return;
  }

  handler();
};

export const isFormError = (statusCode: number) => {
  return statusCode === CODE_FORM_ERRORS;
};

export const formErrorHandle = (data: any) => {
  clearFormError();
  store.dispatch(setFormErrors(textErrorFromCode(data)));
};

export const nameFormErrorHandle = (value: string[]) => {
  store.dispatch(setNameFormError(arrayTextErrorsFromCodes(value)));
};

export const emailFormErrorHandle = (value: string[]) => {
  store.dispatch(setEmailFormError(arrayTextErrorsFromCodes(value)));
};

export const currentPasswordFormErrorHandle = (value: string[]) => {
  store.dispatch(setCurrentPasswordFormError(arrayTextErrorsFromCodes(value)));
};

export const passwordFormErrorHandle = (
  value: string[],
  text: string[] = [],
) => {
  store.dispatch(
    setPasswordFormError(
      text.length > 0 ? text : arrayTextErrorsFromCodes(value),
    ),
  );
};

export const passwordRepeatFormErrorHandle = (value: string[]) => {
  store.dispatch(setPasswordRepeatFormError(arrayTextErrorsFromCodes(value)));
};

export const userAgreementCheckboxFormErrorHandle = (value: string[]) => {
  store.dispatch(setUserAgreementFormError(arrayTextErrorsFromCodes(value)));
};

export const privacyPolicyCheckboxFormErrorHandle = (value: string[]) => {
  store.dispatch(setPrivacyPolicyFormError(arrayTextErrorsFromCodes(value)));
};

export const titleFormErrorHandle = (value: string[]) => {
  store.dispatch(setTitleFormError(arrayTextErrorsFromCodes(value)));
};

export const clearFormError = () => {
  alertCloseDispatch();
  clearAuthLoading();
  clearUpdatePasswordSuccess();
  clearSendResetPasswordSuccess();
  store.dispatch(clearFromErrorsStore());
};

export const clearAuthLoading = () => {
  store.dispatch(authLoadingFalse());
};

export const clearUpdatePasswordSuccess = () => {
  store.dispatch(updatePasswordSuccessFalse());
};

export const clearSendResetPasswordSuccess = () => {
  store.dispatch(sendResetPasswordSuccessFalse());
};

export const textErrorFromCode = (data: formErrorsStoreInterface) => {
  let key: keyof formErrorsStoreInterface;

  for (key in data) {
    data[key] = arrayTextErrorsFromCodes(data[key]);
  }

  return data;
};

export const arrayTextErrorsFromCodes: (arrayCodes: string[]) => string[] = (
  arrayCodes: string[],
) => {
  return (
    arrayCodes?.map((errorCode: string) => {
      return codeTitles(errorCode);
    }) ?? []
  );
};

export const codeTitles = (code: string) => {
  switch (code) {
    case CODE_REQUIRE:
      return CODE_REQUIRE_TEXT;
    case CODE_REQUIRE_ADDITIONAL_AGREEMENTS:
      return CODE_REQUIRE_ADDITIONAL_AGREEMENTS_TEXT;
    case CODE_UNIQUE:
      return CODE_UNIQUE_TEXT;
    case CODE_INT_ONLY:
      return CODE_INT_ONLY_TEXT;
    case CODE_STRING_ONLY:
      return CODE_STRING_ONLY_TEXT;
    case CODE_EMAIL_FORMAT:
      return CODE_EMAIL_FORMAT_TEXT;
    case CODE_MIN_FORMAT:
      return CODE_MIN_FORMAT_TEXT;
    case CODE_MAX_FORMAT:
      return CODE_MAX_FORMAT_TEXT;
    case CODE_STRING_REGEX:
      return CODE_STRING_REGEX_TEXT;
    case CODE_NOT_SAME:
      return CODE_NOT_SAME_TEXT;
    case CODE_BAD_CURRENT_PASSWORD:
      return CODE_BAD_CURRENT_PASSWORD_TEXT;
    case CODE_STATUS_UNAUTHORIZED:
      return CODE_STATUS_UNAUTHORIZED_TEXT;
    case CODE_NOT_EXIST:
      return CODE_NOT_EXIST_TEXT;
    default:
      return code;
  }
};
