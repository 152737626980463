import { createSlice } from "@reduxjs/toolkit";

export interface groupAddTestStoreInterface {
  groupAddTestAccess: boolean;
  groupAddTestLoading: boolean;
  groupAddTestSuccess: boolean | null;
}

export const initGroupTestAdd: groupAddTestStoreInterface = {
  groupAddTestAccess: true,
  groupAddTestLoading: false,
  groupAddTestSuccess: null,
};

export const groupAddTestReduceSlice = createSlice({
  name: "groupTestReduce",
  initialState: initGroupTestAdd,
  reducers: {
    clearGroupAddTest: (state: groupAddTestStoreInterface) => {
      state.groupAddTestAccess = true;
      state.groupAddTestLoading = false;
      state.groupAddTestSuccess = null;
    },
    setGroupAddTestAccessTrue: (state: groupAddTestStoreInterface) => {
      state.groupAddTestAccess = true;
    },
    setGroupAddTestAccessFalse: (state: groupAddTestStoreInterface) => {
      state.groupAddTestAccess = false;
    },
    setGroupAddTestLoadingTrue: (state: groupAddTestStoreInterface) => {
      state.groupAddTestLoading = true;
    },
    setGroupAddTestLoadingFalse: (state: groupAddTestStoreInterface) => {
      state.groupAddTestLoading = false;
    },
    setGroupAddTestSuccessTrue: (state: groupAddTestStoreInterface) => {
      state.groupAddTestSuccess = true;
    },
    setGroupAddTestSuccessFalse: (state: groupAddTestStoreInterface) => {
      state.groupAddTestSuccess = false;
    },
  },
});

export const {
  clearGroupAddTest,
  setGroupAddTestAccessTrue,
  setGroupAddTestAccessFalse,
  setGroupAddTestLoadingTrue,
  setGroupAddTestLoadingFalse,
  setGroupAddTestSuccessTrue,
  setGroupAddTestSuccessFalse,
} = groupAddTestReduceSlice.actions;

export default groupAddTestReduceSlice.reducer;
