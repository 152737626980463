import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import {
  startUpdatePassword,
  stopUpdatePassword,
  updatePasswordSuccessTrue,
} from "../../redux/userData/userDataReduce";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";
import { API_UPDATE_PASSWORD } from "../../constants/apiRoutConstants";
import { updatePasswordRequest } from "./updatePasswordRequest";

export const updatePasswordApi = (data: updatePasswordRequest) => {
  startRequest();

  axios
    .post(API_UPDATE_PASSWORD, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful(res);
    });
};

const startRequest = () => {
  store.dispatch(startUpdatePassword());
};

const successful = (response: responseInterface<any>) => {
  const stopLoadingHandler = () => {
    store.dispatch(stopUpdatePassword());
  };
  const handler = () => {
    store.dispatch(updatePasswordSuccessTrue());
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

const unsuccessful = (res: any) => {
  store.dispatch(stopUpdatePassword());
};
