import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { List, ListItem, ListItemText } from "@mui/material";

const SectionWhom = () => {
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 10,
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ fontSize: 30, lineHeight: 1.2, mb: 5 }}>
            {"Кому будет полезно"}
          </Box>
          <Box>
            <List>
              <ListItem sx={{ borderLeft: "5px #dadada solid", mb: 3 }}>
                <ListItemText
                  primary="Тем, кто хочет знать"
                  secondary="Знание английского будет преимуществом для любого"
                />
              </ListItem>
              <ListItem sx={{ borderLeft: "5px #dadada solid", mb: 3 }}>
                <ListItemText
                  primary="Студентам"
                  secondary="Идеально подойдет для изучения новых слов и новых текстов, особенно, когда это нужно сделать быстро"
                />
              </ListItem>
              <ListItem sx={{ borderLeft: "5px #dadada solid" }}>
                <ListItemText
                  primary="Ученикам"
                  secondary="Чем быстрее освоится заданный материал, тем больше будет свободного времени"
                />
              </ListItem>
            </List>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SectionWhom;
