import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import wordsFromTextData from "../../api/wordsFromText/wordsFromTextData";
import wordsFromTextApi from "../../api/wordsFromText/wordsFromTextApi";
import { clearWordFromTextDispatch } from "../../redux/wordFromText/wordFromTextDispatcher";
import { useSelector } from "react-redux";
import wordFromTextSelectors from "../../redux/wordFromText/wordFromTextSelectors";
import wordListItem from "../../models/wordListItem";
import learningWord from "../../models/learningWord";
import { setWordListDispatch } from "../../redux/actionWordGroup/actionWordGroupDispatcher";
import actionWordGroupSelectors from "../../redux/actionWordGroup/actionWordGroupSelectors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function GroupActionFillWordListFromTextButton() {
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);

  const wordFromTextLoading = useSelector(
    wordFromTextSelectors.wordFromTextLoading,
  );
  const learningWordFromText = useSelector(
    wordFromTextSelectors.learningWordFromText,
  );
  const wordList = useSelector(actionWordGroupSelectors.wordList);

  useEffect(() => {
    return () => {
      clearWordFromTextDispatch();
    };
  }, []);

  useEffect(() => {
    if (learningWordFromText.length > 0) {
      const wordListItems: wordListItem[] = learningWordFromText.map(
        (item: learningWord) => {
          return {
            learningWord: item,
            remove: false,
          };
        },
      );

      const filteredDuplicated = wordListItems.filter((item: wordListItem) => {
        return (
          wordList.filter((itemMain: wordListItem) => {
            return (
              itemMain.learningWord.learning_word_id ===
              item.learningWord.learning_word_id
            );
          }).length === 0
        );
      });

      setWordListDispatch(wordList.concat(filteredDuplicated));
      handleClose();
    }
  }, [learningWordFromText]);

  function handleClose() {
    setShow(false);
    // setText("");
  }

  function handleOpen() {
    setShow(!show);
  }

  function addHandle() {
    if (!text) {
      return;
    }
    const data = wordsFromTextData(text);
    wordsFromTextApi(data);
  }

  return (
    <Box sx={{ bgcolor: "#f0f0f0", p: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <LoadingButton
          loading={wordFromTextLoading}
          size={"small"}
          color="primary"
          variant="outlined"
          onClick={handleOpen}
          startIcon={
            show === true ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        >
          {"Заполнить из текста"}
        </LoadingButton>
      </Box>
      {show === true ? (
        <Box sx={{ pt: 2 }}>
          <TextField
            fullWidth
            label="Введите текст"
            id="fullWidth"
            multiline
            rows={8}
            value={text}
            onChange={(e: any) => setText(e.target.value)}
            sx={{ width: "100%" }}
            disabled={wordFromTextLoading}
          />
          <Box sx={{ p: "1 0", fontSize: 12 }}>
            {"* слова будут добавлены в конец списка словаря"}
          </Box>
          <LoadingButton
            loading={wordFromTextLoading}
            variant="contained"
            sx={{ mt: 1 }}
            onClick={addHandle}
            size="small"
          >
            {"Заполнить Из текста"}
          </LoadingButton>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}

export default GroupActionFillWordListFromTextButton;
