import { createSlice } from "@reduxjs/toolkit";

export interface groupRemoveExamStoreInterface {
  groupRemoveExamAccess: boolean;
  groupRemoveExamLoading: boolean;
  groupRemoveExamSuccess: boolean | null;
}

export const initGroupExamAdd: groupRemoveExamStoreInterface = {
  groupRemoveExamAccess: true,
  groupRemoveExamLoading: false,
  groupRemoveExamSuccess: null,
};

export const groupRemoveExamReduceSlice = createSlice({
  name: "groupExamReduce",
  initialState: initGroupExamAdd,
  reducers: {
    clearGroupRemoveExam: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamAccess = true;
      state.groupRemoveExamLoading = false;
      state.groupRemoveExamSuccess = null;
    },
    setGroupRemoveExamAccessTrue: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamAccess = true;
    },
    setGroupRemoveExamAccessFalse: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamAccess = false;
    },
    setGroupRemoveExamLoadingTrue: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamLoading = true;
    },
    setGroupRemoveExamLoadingFalse: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamLoading = false;
    },
    setGroupRemoveExamSuccessTrue: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamSuccess = true;
    },
    setGroupRemoveExamSuccessFalse: (state: groupRemoveExamStoreInterface) => {
      state.groupRemoveExamSuccess = false;
    },
  },
});

export const {
  clearGroupRemoveExam,
  setGroupRemoveExamAccessTrue,
  setGroupRemoveExamAccessFalse,
  setGroupRemoveExamLoadingTrue,
  setGroupRemoveExamLoadingFalse,
  setGroupRemoveExamSuccessTrue,
  setGroupRemoveExamSuccessFalse,
} = groupRemoveExamReduceSlice.actions;

export default groupRemoveExamReduceSlice.reducer;
