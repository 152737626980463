import { Pagination } from "@mui/material";
import React, { ChangeEvent } from "react";

interface PaginationComponentPropsInterface {
  count: number;
  page: number;
  handleChange: CallableFunction;
}

export default function PaginationComponent(
  props: PaginationComponentPropsInterface,
) {
  function handleChange(event: ChangeEvent<unknown>, value: number) {
    props.handleChange(value);
  }

  return (
    <>
      <Pagination
        count={props.count}
        color="primary"
        page={props.page}
        onChange={handleChange}
        siblingCount={0}
      />
    </>
  );
}
