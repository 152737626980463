import { storeInterface } from "../store";

const learningWord = (state: storeInterface) => state.wordCreate.learningWord;
const wordCreateLoading = (state: storeInterface) =>
  state.wordCreate.wordCreateLoading;

export default {
  learningWord,
  wordCreateLoading,
};
