import { storeInterface } from "../store";

const wordSearchResponse = (state: storeInterface) =>
  state.wordSearch.wordSearchResponse;
const wordSearchLoading = (state: storeInterface) =>
  state.wordSearch.wordSearchLoading;

export default {
  wordSearchResponse,
  wordSearchLoading,
};
