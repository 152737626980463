import { createSlice } from "@reduxjs/toolkit";
import newLearningWord from "../../models/newLearningWord";

export interface learnNewWordStoreInterface {
  newLearningWord: newLearningWord | null;
  newLearningWordLoading: boolean;
  newWordNotAccessByActiveTest: boolean;
  newLearningWordAccess: boolean;
  firstNewWord: boolean;
}

const initReduce: learnNewWordStoreInterface = {
  newLearningWord: null,
  newLearningWordLoading: true,
  newWordNotAccessByActiveTest: false,
  newLearningWordAccess: true,
  firstNewWord: false,
};

export const learnNewWordReduceSlice = createSlice({
  name: "learnNewWordReduce",
  initialState: initReduce,
  reducers: {
    clearLearnNewWord: (state: learnNewWordStoreInterface) => {
      state.newLearningWord = initReduce.newLearningWord;
      state.newLearningWordLoading = initReduce.newLearningWordLoading;
      state.newWordNotAccessByActiveTest =
        initReduce.newWordNotAccessByActiveTest;
      state.newLearningWordAccess = initReduce.newLearningWordAccess;
      state.firstNewWord = initReduce.firstNewWord;
    },
    setNewLearningWord: (
      state: learnNewWordStoreInterface,
      actions: { payload: newLearningWord; type: string },
    ) => {
      state.newLearningWord = actions.payload;
    },
    newLearningWordLoadingTrue: (state: learnNewWordStoreInterface) => {
      state.newLearningWordLoading = true;
    },
    newLearningWordLoadingFalse: (state: learnNewWordStoreInterface) => {
      state.newLearningWordLoading = false;
    },
    newWordNotAccessByActiveTestTrue: (state: learnNewWordStoreInterface) => {
      state.newWordNotAccessByActiveTest = true;
    },
    newWordNotAccessByActiveTestFalse: (state: learnNewWordStoreInterface) => {
      state.newWordNotAccessByActiveTest = false;
    },
    newLearningWordAccessTrue: (state: learnNewWordStoreInterface) => {
      state.newLearningWordAccess = true;
    },
    newLearningWordAccessFalse: (state: learnNewWordStoreInterface) => {
      state.newLearningWordAccess = false;
    },
    firstNewWordTrue: (state: learnNewWordStoreInterface) => {
      state.firstNewWord = true;
    },
    firstNewWordFalse: (state: learnNewWordStoreInterface) => {
      state.firstNewWord = false;
    },
  },
});

export const {
  clearLearnNewWord,
  setNewLearningWord,
  newLearningWordLoadingTrue,
  newLearningWordLoadingFalse,
  newWordNotAccessByActiveTestTrue,
  newWordNotAccessByActiveTestFalse,
  newLearningWordAccessTrue,
  newLearningWordAccessFalse,
  firstNewWordTrue,
  firstNewWordFalse,
} = learnNewWordReduceSlice.actions;

export default learnNewWordReduceSlice.reducer;
