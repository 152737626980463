import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupExam from "../../models/learningWordGroupExam";

export interface learnExamStoreInterface {
  learningWordGroupExam: learningWordGroupExam;
  learnExamLoading: boolean;
  learnExamAccess: boolean;
}

export const initData: learnExamStoreInterface = {
  learningWordGroupExam: {
    learning_word_group_exam_id: 0,
    learning_word_group_id: 0,
    start: 0,
    end: 0,
    min: 0,
    sec: 0,
    amount_passed: 0,
    amount_not_passed: 0,
    data: [],
    proposal_array: [],
  },
  learnExamLoading: false,
  learnExamAccess: true,
};

export const learnExamReduceSlice = createSlice({
  name: "learnExamReduce",
  initialState: initData,
  reducers: {
    clearLearnExam: (state: learnExamStoreInterface) => {
      state.learningWordGroupExam = initData.learningWordGroupExam;
      state.learnExamLoading = initData.learnExamLoading;
      state.learnExamAccess = initData.learnExamAccess;
    },
    setLearningWordGroupExam: (
      state: learnExamStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupExam;
      },
    ) => {
      state.learningWordGroupExam = action.payload;
    },
    setLearnExamLoadingTrue: (state: learnExamStoreInterface) => {
      state.learnExamLoading = true;
    },
    setLearnExamLoadingFalse: (state: learnExamStoreInterface) => {
      state.learnExamLoading = false;
    },
    setLearnExamAccessTrue: (state: learnExamStoreInterface) => {
      state.learnExamAccess = true;
    },
    setLearnExamAccessFalse: (state: learnExamStoreInterface) => {
      state.learnExamAccess = false;
    },
  },
});

export const {
  clearLearnExam,
  setLearningWordGroupExam,
  setLearnExamLoadingTrue,
  setLearnExamLoadingFalse,
  setLearnExamAccessTrue,
  setLearnExamAccessFalse,
} = learnExamReduceSlice.actions;

export default learnExamReduceSlice.reducer;
