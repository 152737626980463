import { createSlice } from "@reduxjs/toolkit";

export interface formErrorsStoreInterface {
  name: string[];
  email: string[];
  password: string[];
  passwordRepeat: string[];
  currentPassword: string[];
  main: string[];
  userAgreement: string[];
  privacyPolicy: string[];
  title: string[];
  confirmEmailCode: string[];
}

export const initStateFromErrors: formErrorsStoreInterface = {
  name: [],
  email: [],
  password: [],
  passwordRepeat: [],
  currentPassword: [],
  main: [],
  userAgreement: [],
  privacyPolicy: [],
  title: [],
  confirmEmailCode: [],
};

const formErrorsReduceSlice = createSlice({
  name: "appReduce",
  initialState: initStateFromErrors,
  reducers: {
    setFormErrors: (
      state: formErrorsStoreInterface,
      action: {
        payload: formErrorsStoreInterface;
        type: string;
      },
    ) => {
      if (isWriteError(state.name, action.payload.name)) {
        state.name = action.payload.name;
      }
      if (isWriteError(state.email, action.payload.email)) {
        state.email = action.payload.email;
      }
      if (isWriteError(state.password, action.payload.password)) {
        state.password = action.payload.password;
      }
      if (isWriteError(state.passwordRepeat, action.payload.passwordRepeat)) {
        state.passwordRepeat = action.payload.passwordRepeat;
      }
      if (isWriteError(state.currentPassword, action.payload.currentPassword)) {
        state.currentPassword = action.payload.currentPassword;
      }
      if (isWriteError(state.main, action.payload.main)) {
        state.main = action.payload.main;
      }
      if (isWriteError(state.userAgreement, action.payload.userAgreement)) {
        state.userAgreement = action.payload.userAgreement;
      }
      if (isWriteError(state.privacyPolicy, action.payload.privacyPolicy)) {
        state.privacyPolicy = action.payload.privacyPolicy;
      }
      if (isWriteError(state.title, action.payload.title)) {
        state.title = action.payload.title;
      }
    },
    setNameFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.name = action.payload;
    },
    setEmailFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.email = action.payload;
    },
    setPasswordFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.password = action.payload;
    },
    setPasswordRepeatFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.passwordRepeat = action.payload;
    },
    setCurrentPasswordFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.currentPassword = action.payload;
    },
    setMainFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.main = action.payload;
    },
    setUserAgreementFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.userAgreement = action.payload;
    },
    setPrivacyPolicyFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.privacyPolicy = action.payload;
    },
    setTitleFormError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.title = action.payload;
    },
    setConfirmEmailCodeError: (
      state: formErrorsStoreInterface,
      action: {
        payload: string[];
        type: string;
      },
    ) => {
      state.confirmEmailCode = action.payload;
    },
    clearFromErrorsStore: (state: formErrorsStoreInterface) => {
      state.name = [];
      state.email = [];
      state.password = [];
      state.passwordRepeat = [];
      state.currentPassword = [];
      state.main = [];
      state.userAgreement = [];
      state.privacyPolicy = [];
      state.title = [];
      state.confirmEmailCode = [];
    },
  },
});

const isWriteError = (stateItem: string[], item: string[]) => {
  return stateItem !== item;
};

export const {
  setFormErrors,
  setNameFormError,
  setEmailFormError,
  setPasswordFormError,
  setPasswordRepeatFormError,
  setCurrentPasswordFormError,
  setUserAgreementFormError,
  setPrivacyPolicyFormError,
  setMainFormError,
  setTitleFormError,
  clearFromErrorsStore,
  setConfirmEmailCodeError,
} = formErrorsReduceSlice.actions;

export default formErrorsReduceSlice.reducer;
