import { createSlice } from "@reduxjs/toolkit";
import newLearningWord from "../../models/newLearningWord";
import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";

export interface learnGroupPersonalDataStoreInterface {
  issetNotPublicWord: boolean;
  title: string;
  lastNewLearningWord: newLearningWord | null;
  learnGroupPersonalDataLoading: boolean;
  learnGroupPersonalDataAccess: boolean;
  updateNewWord: boolean;
  complete: boolean;
  progress: number;
  paidAccess: boolean;
  learningWordSoundTypes: learningWordSoundType[];
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
  countLearningWord: number;
  countWords: number;
}

const initReduce: learnGroupPersonalDataStoreInterface = {
  issetNotPublicWord: false,
  title: "",
  lastNewLearningWord: null,
  learnGroupPersonalDataLoading: true,
  learnGroupPersonalDataAccess: true,
  updateNewWord: false,
  complete: false,
  progress: 0,
  paidAccess: true,
  learningWordSoundTypes: [],
  learningWordTranscriptionTypes: [],
  countLearningWord: 0,
  countWords: 0,
};

export const learnGroupPersonalDataReduceSlice = createSlice({
  name: "learnGroupPersonalDataReduce",
  initialState: initReduce,
  reducers: {
    clearLearnGroupPersonalData: (
      state: learnGroupPersonalDataStoreInterface,
    ) => {
      state.issetNotPublicWord = initReduce.issetNotPublicWord;
      state.title = initReduce.title;
      state.lastNewLearningWord = initReduce.lastNewLearningWord;
      state.learnGroupPersonalDataLoading =
        initReduce.learnGroupPersonalDataLoading;
      state.learnGroupPersonalDataAccess =
        initReduce.learnGroupPersonalDataAccess;
      state.updateNewWord = initReduce.updateNewWord;
      state.complete = initReduce.complete;
      state.progress = initReduce.progress;
      state.paidAccess = initReduce.paidAccess;
      state.learningWordSoundTypes = initReduce.learningWordSoundTypes;
      state.learningWordTranscriptionTypes =
        initReduce.learningWordTranscriptionTypes;
      state.countLearningWord = initReduce.countLearningWord;
      state.countWords = initReduce.countWords;
    },
    setLastNewLearningWord: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: newLearningWord; type: string },
    ) => {
      state.lastNewLearningWord = actions.payload;
    },
    setLearnGroupPersonalDataLoadingTrue: (
      state: learnGroupPersonalDataStoreInterface,
    ) => {
      state.learnGroupPersonalDataLoading = true;
    },
    setLearnGroupPersonalDataLoadingFalse: (
      state: learnGroupPersonalDataStoreInterface,
    ) => {
      state.learnGroupPersonalDataLoading = false;
    },
    setLearnGroupPersonalDataAccessTrue: (
      state: learnGroupPersonalDataStoreInterface,
    ) => {
      state.learnGroupPersonalDataAccess = true;
    },
    setLearnGroupPersonalDataAccessFalse: (
      state: learnGroupPersonalDataStoreInterface,
    ) => {
      state.learnGroupPersonalDataAccess = false;
    },
    setIssetNotPublicWord: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.issetNotPublicWord = actions.payload;
    },
    setTitle: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: string; type: string },
    ) => {
      state.title = actions.payload;
    },
    setUpdateNewWord: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.updateNewWord = actions.payload;
    },
    setComplete: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.complete = actions.payload;
    },
    setProgress: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.progress = actions.payload;
    },
    setCountLearningWord: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countLearningWord = actions.payload;
    },
    setCountWords: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countWords = actions.payload;
    },
    setPaidAccess: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.paidAccess = actions.payload;
    },
    setLearningWordSoundTypes: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: learningWordSoundType[]; type: string },
    ) => {
      state.learningWordSoundTypes = actions.payload;
    },
    setLearningWordTranscriptionTypes: (
      state: learnGroupPersonalDataStoreInterface,
      actions: { payload: learningWordTranscriptionType[]; type: string },
    ) => {
      state.learningWordTranscriptionTypes = actions.payload;
    },
  },
});

export const {
  clearLearnGroupPersonalData,
  setLastNewLearningWord,
  setLearnGroupPersonalDataLoadingTrue,
  setLearnGroupPersonalDataLoadingFalse,
  setLearnGroupPersonalDataAccessTrue,
  setLearnGroupPersonalDataAccessFalse,
  setIssetNotPublicWord,
  setTitle,
  setUpdateNewWord,
  setComplete,
  setProgress,
  setPaidAccess,
  setLearningWordSoundTypes,
  setLearningWordTranscriptionTypes,
  setCountLearningWord,
  setCountWords,
} = learnGroupPersonalDataReduceSlice.actions;

export default learnGroupPersonalDataReduceSlice.reducer;
