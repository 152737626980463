import { createSlice } from "@reduxjs/toolkit";
import PersonalWord from "../../models/PersonalWord";

export interface userPersonalWordStoreInterface {
  personalWord: PersonalWord | null;
  personalWordLoading: boolean;
  choose: boolean;
  lastChooseTranslateId: number;
  success: boolean | null;
}

export const initUserPersonalWord: userPersonalWordStoreInterface = {
  personalWord: null,
  personalWordLoading: true,
  choose: false,
  lastChooseTranslateId: 0,
  success: null,
};

export const userPersonalWordReduceSlice = createSlice({
  name: "userPersonalWordReduce",
  initialState: initUserPersonalWord,
  reducers: {
    setUserPersonalWord: (state: userPersonalWordStoreInterface, action) => {
      state.personalWord = action.payload;
      state.personalWordLoading = false;
    },
    personalWordLoading: (state: userPersonalWordStoreInterface) => {
      state.personalWordLoading = true;
      state.choose = false;
      state.lastChooseTranslateId = 0;
      state.success = null;
    },
    stopChooseUserPersonalWord: (
      state: userPersonalWordStoreInterface,
      action: {
        payload: boolean;
        type: string;
      },
    ) => {
      state.choose = false;
      state.success = action.payload;
    },
    startChooseUserPersonalWord: (
      state: userPersonalWordStoreInterface,
      action,
    ) => {
      state.choose = true;
      state.lastChooseTranslateId = action.payload;
    },

    clearUserPersonalWord: (state: userPersonalWordStoreInterface) => {
      state = {
        ...initUserPersonalWord,
      };
    },
  },
});

export const {
  setUserPersonalWord,
  personalWordLoading,
  stopChooseUserPersonalWord,
  startChooseUserPersonalWord,
  clearUserPersonalWord,
} = userPersonalWordReduceSlice.actions;

export default userPersonalWordReduceSlice.reducer;
