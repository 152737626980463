import React, { useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";

interface titleLineComponentProps {
  title: string;
}

function TitleLineComponent(props: titleLineComponentProps) {
  useEffect(() => {
    document.title = props.title;
  }, []);

  return (
    <>
      <Box>
        <Divider textAlign="left" variant="fullWidth" sx={{ mt: 1, mb: 3 }}>
          <Typography variant="h5">{props.title}</Typography>
        </Divider>
      </Box>
    </>
  );
}

export default TitleLineComponent;
