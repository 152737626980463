import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_PERSONAL_DELETE } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setGroupPersonalDeleteCompleteDispatch,
  setGroupPersonalDeleteLoadingFalseDispatch,
  setGroupPersonalDeleteLoadingTrueDispatch,
} from "../../redux/groupPersonalDelete/groupPersonalDeleteDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import groupPersonalDeleteRequest from "./groupPersonalDeleteRequest";

export default function groupPersonalDeleteApi(
  data: groupPersonalDeleteRequest,
) {
  startRequest();

  axios
    .delete(API_GROUP_PERSONAL_DELETE, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setGroupPersonalDeleteCompleteDispatch(null);
  setGroupPersonalDeleteLoadingTrueDispatch();
};

const successful = (response: responseInterface<null>) => {
  setGroupPersonalDeleteCompleteDispatch(true);
  setGroupPersonalDeleteLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
