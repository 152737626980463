import { Box, Grid, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import formErrorsSelectors from "../../../redux/formErrors/formErrorsSelectors";
import { FormErrorsText } from "../FormErrorsText";

export interface emailInputPropsInterface {
  email: string;
  loading: boolean;
  valueChangeHandle: CallableFunction;
}

export default function EmailInput(props: emailInputPropsInterface) {
  const errors = useSelector(formErrorsSelectors.formErrors).email;

  function changeHandle(e: any) {
    props.valueChangeHandle(e.currentTarget.value);
  }

  function isError() {
    return errors?.length > 0;
  }

  return (
    <>
      <TextField
        disabled={props.loading}
        label=""
        sx={{ m: "5px", width: "100%" }}
        size="small"
        value={props.email}
        onChange={changeHandle}
        error={isError()}
      />
      <Box>
        <FormErrorsText propertyValues={errors} />
      </Box>
    </>
  );
}
