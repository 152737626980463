import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import ListIcon from "@mui/icons-material/List";
import { routeGroupsPersonalEditNavigate } from "../../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import QuestionDialog from "../../questionDialog/QuestionDialog";
import groupPersonalDeleteData from "../../../api/groupPersonalDelete/groupPersonalDeleteData";
import groupPersonalDeleteApi from "../../../api/groupPersonalDelete/groupPersonalDeleteApi";
import groupPersonalDeleteSelectors from "../../../redux/groupPersonalDelete/groupPersonalDeleteSelectors";
import { useSelector } from "react-redux";
import { clearGroupPersonalDeleteDispatch } from "../../../redux/groupPersonalDelete/groupPersonalDeleteDispatcher";

interface propsInterface {
  learningWordGroupPersonalId: number;
}

export default function PersonalGroupItemDropMenuComponent(
  props: propsInterface,
) {
  const navigate = useNavigate();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const groupPersonalDeleteLoading = useSelector(
    groupPersonalDeleteSelectors.groupPersonalDeleteLoading,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      clearGroupPersonalDeleteDispatch();
    };
  }, []);

  function toGroupEdit() {
    navigate(
      routeGroupsPersonalEditNavigate(
        String(props.learningWordGroupPersonalId),
      ),
    );
  }

  function deleteDialogClose() {
    setShowDeleteDialog(false);
  }

  function deleteDialogHandle() {
    const data = groupPersonalDeleteData(props.learningWordGroupPersonalId);
    groupPersonalDeleteApi(data);
  }

  return (
    <>
      <QuestionDialog
        open={showDeleteDialog}
        handleYes={deleteDialogHandle}
        handleClose={deleteDialogClose}
        title={"Вы точно хотите удалить словарь?"}
        content={"Удаление словаря не изменит количество изученных вами слов"}
        yesText={"Да, удалить"}
        loading={groupPersonalDeleteLoading}
      />
      <IconButton
        aria-label="Меню"
        size="large"
        id="item-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <ListIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "item-button",
        }}
      >
        <MenuItem onClick={toGroupEdit}>{"Редактировать"}</MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true);
          }}
          sx={{ color: "error.main" }}
        >
          {"Удалить"}
        </MenuItem>
      </Menu>
    </>
  );
}
