import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";
import newLearningWord from "../../models/newLearningWord";
import store from "../store";
import {
  clearLearnGroupPersonalData,
  setLearnGroupPersonalDataAccessFalse,
  setLearnGroupPersonalDataAccessTrue,
  setLearnGroupPersonalDataLoadingFalse,
  setLearnGroupPersonalDataLoadingTrue,
  setComplete,
  setLastNewLearningWord,
  setProgress,
  setUpdateNewWord,
  setPaidAccess,
  setLearningWordTranscriptionTypes,
  setLearningWordSoundTypes,
  setTitle,
  setCountLearningWord,
  setCountWords,
  setIssetNotPublicWord,
} from "./learnGroupPersonalDataReduce";

export function clearLearnGroupPersonalDataDispatch() {
  store.dispatch(clearLearnGroupPersonalData());
}

export function setLastNewLearningWordDispatch(
  lastNewLearningWord: newLearningWord,
) {
  store.dispatch(setLastNewLearningWord(lastNewLearningWord));
}

export function setLearnGroupPersonalDataLoadingTrueDispatch() {
  store.dispatch(setLearnGroupPersonalDataLoadingTrue());
}

export function setLearnGroupPersonalDataLoadingFalseDispatch() {
  store.dispatch(setLearnGroupPersonalDataLoadingFalse());
}

export function setLearnGroupPersonalDataAccessTrueDispatch() {
  store.dispatch(setLearnGroupPersonalDataAccessTrue());
}

export function setLearnGroupPersonalDataAccessFalseDispatch() {
  store.dispatch(setLearnGroupPersonalDataAccessFalse());
}

export function setIssetNotPublicWordDispatch(value: boolean) {
  store.dispatch(setIssetNotPublicWord(value));
}

export function setTitleDispatch(value: string) {
  store.dispatch(setTitle(value));
}

export function setUpdateNewWordDispatch(updateNewWord: boolean) {
  store.dispatch(setUpdateNewWord(updateNewWord));
}

export function setCompleteDispatch(complete: boolean) {
  store.dispatch(setComplete(complete));
}

export function setProgressDispatch(progress: number) {
  store.dispatch(setProgress(progress));
}

export function setPaidAccessDispatch(paidAccess: boolean) {
  store.dispatch(setPaidAccess(paidAccess));
}

export function setCountLearningWordDispatch(value: number) {
  store.dispatch(setCountLearningWord(value));
}

export function setCountWordsDispatch(value: number) {
  store.dispatch(setCountWords(value));
}

export function setLearningWordSoundTypesDispatch(
  learningWordSoundTypes: learningWordSoundType[],
) {
  store.dispatch(setLearningWordSoundTypes(learningWordSoundTypes));
}

export function setLearningWordTranscriptionTypesDispatch(
  learningWordTranscriptionTypes: learningWordTranscriptionType[],
) {
  store.dispatch(
    setLearningWordTranscriptionTypes(learningWordTranscriptionTypes),
  );
}
