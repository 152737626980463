import { storeInterface } from "../store";

const wordFromTextLoading = (state: storeInterface) =>
  state.wordFromText.wordFromTextLoading;
const learningWordFromText = (state: storeInterface) =>
  state.wordFromText.learningWordFromText;

export default {
  wordFromTextLoading,
  learningWordFromText,
};
