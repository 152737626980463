import { ArrowBack } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface BackComponentPropsInterface {
  routUrl: string;
}

function BackComponent(props: BackComponentPropsInterface) {
  const navigate = useNavigate();

  function toBack() {
    navigate(props.routUrl);
  }

  return (
    <>
      <Button variant="outlined" startIcon={<ArrowBack />} onClick={toBack}>
        {"Назад"}
      </Button>
    </>
  );
}

export default BackComponent;
