import { createSlice } from "@reduxjs/toolkit";
import chooseValueWord from "../../models/chooseValueWord";
import newLearningWord from "../../models/newLearningWord";

export interface learnCheckStoreInterface {
  newLearningWord: newLearningWord | null;
  learnCheckLoading: boolean;
  learnCheckAccess: boolean;
  success: boolean | null;
  complete: boolean;
  progress: number;
}

const initStore: learnCheckStoreInterface = {
  newLearningWord: null,
  learnCheckLoading: false,
  learnCheckAccess: true,
  success: null,
  complete: false,
  progress: 0,
};

export const learnCheckReduceSlice = createSlice({
  name: "learnCheckReduce",
  initialState: initStore,
  reducers: {
    clearLearnCheck: (state: learnCheckStoreInterface) => {
      state.newLearningWord = initStore.newLearningWord;
      state.learnCheckLoading = initStore.learnCheckLoading;
      state.learnCheckAccess = initStore.learnCheckAccess;
      state.success = initStore.success;
      state.complete = initStore.complete;
      state.progress = initStore.progress;
    },
    setLearnCheckAccessTrue: (state: learnCheckStoreInterface) => {
      state.learnCheckAccess = true;
    },
    setLearnCheckAccessFalse: (state: learnCheckStoreInterface) => {
      state.learnCheckAccess = false;
    },
    setNewLearningWord: (
      state: learnCheckStoreInterface,
      actions: { payload: newLearningWord | null; type: string },
    ) => {
      state.newLearningWord = actions.payload;
    },
    setLearnCheckLoadingTrue: (state: learnCheckStoreInterface) => {
      state.learnCheckLoading = true;
    },
    setLearnCheckLoadingFalse: (state: learnCheckStoreInterface) => {
      state.learnCheckLoading = false;
    },
    setSuccessNull: (state: learnCheckStoreInterface) => {
      state.success = null;
    },
    setSuccess: (
      state: learnCheckStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.success = actions.payload;
    },
    setComplete: (
      state: learnCheckStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.complete = actions.payload;
    },
    setProgress: (
      state: learnCheckStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.progress = actions.payload;
    },
  },
});

export const {
  clearLearnCheck,
  setLearnCheckAccessTrue,
  setLearnCheckAccessFalse,
  setNewLearningWord,
  setLearnCheckLoadingTrue,
  setLearnCheckLoadingFalse,
  setSuccessNull,
  setSuccess,
  setComplete,
  setProgress,
} = learnCheckReduceSlice.actions;

export default learnCheckReduceSlice.reducer;
