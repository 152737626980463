import { storeInterface } from "../store";

const userConfirmEmailLoading = (state: storeInterface) =>
  state.userConfirmEmail.userConfirmEmailLoading;
const userConfirmEmailComplete = (state: storeInterface) =>
  state.userConfirmEmail.userConfirmEmailComplete;

export default {
  userConfirmEmailLoading,
  userConfirmEmailComplete,
};
