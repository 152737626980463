import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ROUTE_HOME } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate();

  const toHome = () => {
    navigate(ROUTE_HOME);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h2" sx={{ marginBottom: "10px" }}>
          {"Ой! Страница не найдена."}
        </Typography>
        <Box
          role="img"
          aria-label="Sad Face"
          sx={{ fontSize: "5rem", marginBottom: "10px" }}
        >
          😢
        </Box>
        <Typography variant="subtitle1" sx={{ marginBottom: "10px" }}>
          {
            "Страница, на которой вы находитесь была удалена или временно не доступна."
          }
        </Typography>
        <Button variant="contained" onClick={toHome}>
          {"На главную страницу"}
        </Button>
      </Box>
    </>
  );
}
