import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";
import newLearningWord from "../../models/newLearningWord";
import store from "../store";
import {
  clearLearnData,
  setLearnDataAccessFalse,
  setLearnDataAccessTrue,
  setLearnDataLoadingFalse,
  setLearnDataLoadingTrue,
  setComplete,
  setLastNewLearningWord,
  setProgress,
  setUpdateNewWord,
  setPaidAccess,
  setLearningWordTranscriptionTypes,
  setLearningWordSoundTypes,
} from "./learnDataReduce";

export function clearLearnDataDispatch() {
  store.dispatch(clearLearnData());
}

export function setLastNewLearningWordDispatch(
  lastNewLearningWord: newLearningWord,
) {
  store.dispatch(setLastNewLearningWord(lastNewLearningWord));
}

export function setLearnDataLoadingTrueDispatch() {
  store.dispatch(setLearnDataLoadingTrue());
}

export function setLearnDataLoadingFalseDispatch() {
  store.dispatch(setLearnDataLoadingFalse());
}

export function setLearnDataAccessTrueDispatch() {
  store.dispatch(setLearnDataAccessTrue());
}

export function setLearnDataAccessFalseDispatch() {
  store.dispatch(setLearnDataAccessFalse());
}

export function setUpdateNewWordDispatch(updateNewWord: boolean) {
  store.dispatch(setUpdateNewWord(updateNewWord));
}

export function setCompleteDispatch(complete: boolean) {
  store.dispatch(setComplete(complete));
}

export function setProgressDispatch(progress: number) {
  store.dispatch(setProgress(progress));
}

export function setPaidAccessDispatch(paidAccess: boolean) {
  store.dispatch(setPaidAccess(paidAccess));
}

export function setLearningWordSoundTypesDispatch(
  learningWordSoundTypes: learningWordSoundType[],
) {
  store.dispatch(setLearningWordSoundTypes(learningWordSoundTypes));
}

export function setLearningWordTranscriptionTypesDispatch(
  learningWordTranscriptionTypes: learningWordTranscriptionType[],
) {
  store.dispatch(
    setLearningWordTranscriptionTypes(learningWordTranscriptionTypes),
  );
}
