import { storeInterface } from "../store";

const newLearningWord = (state: storeInterface) =>
  state.learnGroupPersonalCheck.newLearningWord;
const learnGroupPersonalCheckLoading = (state: storeInterface) =>
  state.learnGroupPersonalCheck.learnGroupPersonalCheckLoading;
const learnGroupPersonalCheckAccess = (state: storeInterface) =>
  state.learnGroupPersonalCheck.learnGroupPersonalCheckAccess;
const success = (state: storeInterface) =>
  state.learnGroupPersonalCheck.success;
const complete = (state: storeInterface) =>
  state.learnGroupPersonalCheck.complete;
const progress = (state: storeInterface) =>
  state.learnGroupPersonalCheck.progress;

export default {
  newLearningWord,
  learnGroupPersonalCheckLoading,
  learnGroupPersonalCheckAccess,
  success,
  complete,
  progress,
};
