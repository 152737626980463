import { createSlice } from "@reduxjs/toolkit";
import wordListItem from "../../models/wordListItem";
import learningWordGroupPartition from "../../models/learningWordGroupPartition";
import learningWordGroupEnvironment from "../../models/learningWordGroupEnvironment";

export interface actionWordGroupStoreInterface {
  actionWordGroupAccess: boolean;
  isEdit: boolean;
  wordList: wordListItem[];
  isEditWordList: boolean;
  title: string;
  description: string;
  learningWordGroupPartition: learningWordGroupPartition | null;
  learningWordGroupEnvironment: learningWordGroupEnvironment | null;
  actionWordGroupLoading: boolean;
  actionWordGroupError: string[];
  actionWordGroupSuccess: boolean | null;
}

export const initState: actionWordGroupStoreInterface = {
  actionWordGroupAccess: true,
  isEdit: true,
  wordList: [],
  isEditWordList: true,
  title: "",
  description: "",
  learningWordGroupPartition: null,
  learningWordGroupEnvironment: null,
  actionWordGroupLoading: false,
  actionWordGroupError: [],
  actionWordGroupSuccess: null,
};

export const actionWordGroupReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initState,
  reducers: {
    clearActionWordGroup: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupAccess = initState.actionWordGroupAccess;
      state.wordList = initState.wordList;
      state.title = initState.title;
      state.description = initState.description;
      state.learningWordGroupPartition = initState.learningWordGroupPartition;
      state.actionWordGroupLoading = initState.actionWordGroupLoading;
      state.actionWordGroupError = initState.actionWordGroupError;
      state.actionWordGroupSuccess = initState.actionWordGroupSuccess;
    },
    setActionWordGroupAccessTrue: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupAccess = true;
    },
    setActionWordGroupAccessFalse: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupAccess = false;
    },
    setWordList: (
      state: actionWordGroupStoreInterface,
      action: {
        type: string;
        payload: wordListItem[];
      },
    ) => {
      state.wordList = action.payload;
    },
    setIsEditTrue: (state: actionWordGroupStoreInterface) => {
      state.isEdit = true;
    },
    setIsEditFalse: (state: actionWordGroupStoreInterface) => {
      state.isEdit = false;
    },
    setIsEditWordListTrue: (state: actionWordGroupStoreInterface) => {
      state.isEditWordList = true;
    },
    setIsEditWordListFalse: (state: actionWordGroupStoreInterface) => {
      state.isEditWordList = false;
    },
    setTitle: (
      state: actionWordGroupStoreInterface,
      action: {
        type: string;
        payload: string;
      },
    ) => {
      state.title = action.payload;
    },
    setDescription: (
      state: actionWordGroupStoreInterface,
      action: {
        type: string;
        payload: string;
      },
    ) => {
      state.description = action.payload;
    },
    setLearningWordGroupPartition: (
      state: actionWordGroupStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupPartition;
      },
    ) => {
      state.learningWordGroupPartition = action.payload;
    },
    setLearningWordGroupEnvironment: (
      state: actionWordGroupStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupEnvironment;
      },
    ) => {
      state.learningWordGroupEnvironment = action.payload;
    },
    setActionWordGroupLoadingTrue: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupLoading = true;
    },
    setActionWordGroupLoadingFalse: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupLoading = false;
    },
    setActionWordGroupError: (
      state: actionWordGroupStoreInterface,
      action: {
        type: string;
        payload: string[];
      },
    ) => {
      state.actionWordGroupError = action.payload;
    },
    clearActionWordGroupError: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupError = [];
    },
    setActionWordGroupSuccessTrue: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupSuccess = true;
    },
    setActionWordGroupSuccessFalse: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupSuccess = false;
    },
    setActionWordGroupSuccessNull: (state: actionWordGroupStoreInterface) => {
      state.actionWordGroupSuccess = null;
    },
  },
});

export const {
  setActionWordGroupAccessTrue,
  setActionWordGroupAccessFalse,
  clearActionWordGroup,
  setWordList,
  setIsEditTrue,
  setIsEditFalse,
  setIsEditWordListTrue,
  setIsEditWordListFalse,
  setTitle,
  setDescription,
  setLearningWordGroupPartition,
  setLearningWordGroupEnvironment,
  setActionWordGroupLoadingTrue,
  setActionWordGroupLoadingFalse,
  setActionWordGroupError,
  clearActionWordGroupError,
  setActionWordGroupSuccessTrue,
  setActionWordGroupSuccessFalse,
  setActionWordGroupSuccessNull,
} = actionWordGroupReduceSlice.actions;

export default actionWordGroupReduceSlice.reducer;
