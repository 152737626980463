import React from "react";
import { Box, Container, CssBaseline, Typography } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import { TITLE_PAGE_SERVICES_AND_PAYMENT } from "../../constants/titlesConstants";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { SocialIcon } from "react-social-icons";
import config from "../../config/config";
import { useSelector } from "react-redux";
import appSelectors from "../../redux/app/appSelectors";

export default function ServicesAndPaymentPage() {
  const socialVkUrl = String(config.socialVkUrl);
  const socialTelegramUrl = String(config.socialTelegramUrl);
  const subscribeCost = useSelector(appSelectors.subscribeCost);

  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_SERVICES_AND_PAYMENT} />
      </Container>
      <Container component="main" maxWidth="md">
        <Box sx={{ pl: "10px", fontSize: "12px" }}>
          {
            "Данный раздел несет ознакомительный характер и содержит общие инструкции для правильности понимания получения услуг и оплаты."
          }
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Услуги и порядок их предоставления"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          {
            "Данный сайт предоставляет услуги для повышения словарного запаса английского языка. Материалы для предоставления услуг взяты из открытых источников. Используется незапатентованная технология доступная каждому. Методы, такие как частое повторение и др. позволяют облегчить усвоение новых слов. Услуги предоставляются только на этом сайте."
          }
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Порядок оплаты"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          {
            "Оплата услуг производится ежемесячно в соответствующем разделе, который доступен только авторизованным пользователям. После оплаты услуги предоставляются на протяжении одного календарного месяца, но не позднее числа, следующего за числом, когда была совершена оплата. Например, если оплата была произведена 1 февраля текущего года, то услуги будут предоставляться до 1 марта включительно текущего года. Сумма оплаты варьируется в зависимости от проводимых акций и других условий. Точную сумму оплаты можно узнать в личном кабинете."
          }
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Бесплатный доступ"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          {
            "Каждому новому пользователю предоставляется пробный бесплатный доступ. Его продолжительность варьируется и зависит от проводимых акций или иных условий."
          }
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Услуги без оплаты"}
        </Box>
        <Box sx={{ mt: "15px", pl: "10px", fontSize: "12px" }}>
          {
            "После окончания подписки услуги продолжают предоставляться, за исключением ряда услуг, которые позволяют продвигаться дальше в повышении словарного запаса."
          }
        </Box>
        <Box
          sx={{
            pl: "10px",
            m: "20px 0 10px",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {"Стоимость подписки"}
        </Box>
        <Box
          sx={{
            mt: "15px",
            pl: "30px",
            fontSize: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
        >
          {subscribeCost + " РУБ"}
        </Box>
      </Container>
      <Footer />
    </>
  );
}
