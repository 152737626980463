import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupTest from "../../models/learningWordGroupTest";

export interface learnTestStoreInterface {
  learningWordGroupTest: learningWordGroupTest;
  learnTestLoading: boolean;
  learnTestAccess: boolean;
}

export const initData: learnTestStoreInterface = {
  learningWordGroupTest: {
    learning_word_group_test_id: 0,
    learning_word_group_id: 0,
    start: 0,
    end: 0,
    min: 0,
    sec: 0,
    amount_passed: 0,
    amount_not_passed: 0,
    data: [],
    proposal_array: [],
  },
  learnTestLoading: false,
  learnTestAccess: true,
};

export const learnTestReduceSlice = createSlice({
  name: "learnTestReduce",
  initialState: initData,
  reducers: {
    clearLearnTest: (state: learnTestStoreInterface) => {
      state.learningWordGroupTest = initData.learningWordGroupTest;
      state.learnTestLoading = initData.learnTestLoading;
      state.learnTestAccess = initData.learnTestAccess;
    },
    setLearningWordGroupTest: (
      state: learnTestStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupTest;
      },
    ) => {
      state.learningWordGroupTest = action.payload;
    },
    setLearnTestLoadingTrue: (state: learnTestStoreInterface) => {
      state.learnTestLoading = true;
    },
    setLearnTestLoadingFalse: (state: learnTestStoreInterface) => {
      state.learnTestLoading = false;
    },
    setLearnTestAccessTrue: (state: learnTestStoreInterface) => {
      state.learnTestAccess = true;
    },
    setLearnTestAccessFalse: (state: learnTestStoreInterface) => {
      state.learnTestAccess = false;
    },
  },
});

export const {
  clearLearnTest,
  setLearningWordGroupTest,
  setLearnTestLoadingTrue,
  setLearnTestLoadingFalse,
  setLearnTestAccessTrue,
  setLearnTestAccessFalse,
} = learnTestReduceSlice.actions;

export default learnTestReduceSlice.reducer;
