import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import userData from "../../models/userData";
import {
  setUserData,
  userDataLoading,
  userDataStopLoading,
} from "../../redux/userData/userDataReduce";
import { API_USER_DATA } from "../../constants/apiRoutConstants";

export default function userDataApi() {
  startRequest();

  axios
    .get(API_USER_DATA)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  store.dispatch(userDataLoading());
};

const successful = (response: responseInterface<userData>) => {
  store.dispatch(setUserData(response.data));
  store.dispatch(userDataStopLoading());
};

const unsuccessful = () => {
  //
};
