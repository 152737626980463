import newLearningWord from "../../models/newLearningWord";
import store from "../store";
import {
  clearLearnCheck,
  setComplete,
  setLearnCheckAccessFalse,
  setLearnCheckAccessTrue,
  setLearnCheckLoadingFalse,
  setLearnCheckLoadingTrue,
  setNewLearningWord,
  setProgress,
  setSuccess,
  setSuccessNull,
} from "./learnCheckReduce";

export function clearLearnCheckDispatch() {
  store.dispatch(clearLearnCheck());
}

export function setNewLearningWordDispatch(learnCheck: newLearningWord | null) {
  store.dispatch(setNewLearningWord(learnCheck));
}

export function setLearnCheckAccessTrueDispatch() {
  store.dispatch(setLearnCheckAccessTrue());
}

export function setLearnCheckAccessFalseDispatch() {
  store.dispatch(setLearnCheckAccessFalse());
}

export function setLearnCheckLoadingTrueDispatch() {
  store.dispatch(setLearnCheckLoadingTrue());
}

export function setLearnCheckLoadingFalseDispatch() {
  store.dispatch(setLearnCheckLoadingFalse());
}

export function setSuccessNullDispatch() {
  store.dispatch(setSuccessNull());
}

export function setSuccessDispatch(success: boolean) {
  store.dispatch(setSuccess(success));
}

export function setCompleteDispatch(complete: boolean) {
  store.dispatch(setComplete(complete));
}

export function setProgressDispatch(progress: number) {
  store.dispatch(setProgress(progress));
}
