import React, { useEffect } from "react";
import store from "../../redux/store";
import { alertError } from "../../redux/alert/alertReduce";

interface formErrorsTextPropsInterface {
  propertyValues: string[] | null;
}

export function FormErrorsText(props: formErrorsTextPropsInterface) {
  useEffect(() => {
    let value = "";
    props.propertyValues?.map((item: string) => {
      value += value.length !== 0 ? ", " + item : item;
    });
    if (value.length > 0) {
      store.dispatch(alertError(value));
    }
  }, [props.propertyValues]);

  return <></>;
}
