import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_GROUPS_PERSONAL,
  ROUTE_LEARN,
  ROUTE_SIGN_IN,
} from "../../constants/routesConstants";
import { useSelector } from "react-redux";
import { storeInterface } from "../../redux/store";

export default function SectionTwo() {
  const navigate = useNavigate();
  const token = useSelector((state: storeInterface) => state.app.token);

  const toLearn = () => {
    navigate(ROUTE_LEARN);
  };

  const toSignIn = () => {
    navigate(ROUTE_SIGN_IN);
  };

  const toGroupsPersonal = () => {
    navigate(ROUTE_GROUPS_PERSONAL);
  };

  const ButtonToLearn = () => {
    return (
      <Button variant="contained" onClick={toLearn} size="small">
        {"Продолжить"}
      </Button>
    );
  };

  const ButtonToSignIn = () => {
    return (
      <Button variant="contained" onClick={toSignIn} size="small">
        {"Попробовать"}
      </Button>
    );
  };

  const ButtonToGroupsPersonal = () => {
    return (
      <Button variant="contained" onClick={toGroupsPersonal} size="small">
        {"К словарям"}
      </Button>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          verticalAlign: "top",
          backgroundColor: "primary.light",
          p: 3,
        }}
      >
        <Paper
          sx={{
            margin: 2,
            padding: 3,
            width: 250,
            height: 300,
          }}
        >
          <Typography
            variant="h6"
            sx={{ marginBottom: "15px", fontWeight: "bold" }}
          >
            {"Используй готовое"}
          </Typography>
          <Box>{"Изучайте предложенные слова и повышайте словарный запас"}</Box>
          <Box sx={{ pt: 5 }}>
            {token ? <ButtonToLearn /> : <ButtonToSignIn />}
          </Box>
        </Paper>
        <Paper
          sx={{
            margin: 2,
            padding: 3,
            width: 250,
            height: 300,
          }}
        >
          <Typography
            variant="h6"
            sx={{ marginBottom: "15px", fontWeight: "bold" }}
          >
            {"Делай своё"}
          </Typography>
          <Box>
            {
              "Создавай свои словари из текста или списка и изучай то, что тебе нужнее"
            }
          </Box>
          <Box sx={{ pt: 5 }}>
            {token ? <ButtonToGroupsPersonal /> : <ButtonToSignIn />}
          </Box>
        </Paper>
      </Box>
    </>
  );
}
