import { Box, Button, Typography } from "@mui/material";
import React from "react";
import TranscriptionsComponent from "../../transcriptions/TranscriptionsComponent";
import TranslatesComponent from "../../translates/TranslatesComponent";
import newLearningWord from "../../../models/newLearningWord";
import learningWordTranscriptionType from "../../../models/learningWordTranscriptionType";
import AudioMultipleComponent from "../../audioComponent/AudioMultipleComponent";
import learningWordSoundType from "../../../models/learningWordSoundType";

interface propsInterface {
  continueHandler: CallableFunction;
  newLearningWord: newLearningWord;
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
  learningWordSoundTypes: learningWordSoundType[];
}

export default function NewLearningWordWordLayout(props: propsInterface) {
  return (
    <>
      <Box
        sx={{
          mt: 1,
          mb: 3,
          p: 1,
          backgroundColor: "success.main",
          color: "#fff",
          fontSize: "32px",
          borderRadius: 2,
        }}
      >
        {"Новое слово"}
      </Box>
      <Box sx={{ p: "10px" }}>
        <Typography variant="h4" sx={{ textTransform: "uppercase" }}>
          <AudioMultipleComponent
            learningWordSounds={props.newLearningWord.sounds}
            learningWordSoundTypes={props.learningWordSoundTypes}
          />
          {props.newLearningWord.learningWord.word}
        </Typography>
        <Box
          sx={{
            fontSize: 20,
            fontStyle: "italic",
          }}
        >
          <TranscriptionsComponent
            transcriptions={props.newLearningWord.transcriptions}
            learningWordTranscriptionTypes={
              props.learningWordTranscriptionTypes
            }
          />
        </Box>
        <Box
          sx={{
            textTransform: "uppercase",
            fontSize: 18,
            mt: "10px",
            mb: "10px",
          }}
        >
          <TranslatesComponent
            translates={props.newLearningWord.translates ?? []}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => {
              props.continueHandler();
            }}
          >
            {"Продолжить"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
