import learningWordGroupPersonalData from "../../models/learningWordGroupPersonalData";
import store from "../store";
import {
  clearGroupPersonalData,
  setGroupPersonalDataLoadingFalse,
  setGroupPersonalDataLoadingTrue,
  setLearningWordGroupPersonalData,
} from "./groupPersonalDataReduce";

export function clearGroupPersonalDataDispatch() {
  store.dispatch(clearGroupPersonalData());
}

export function setGroupPersonalDataLoadingTrueDispatch() {
  store.dispatch(setGroupPersonalDataLoadingTrue());
}

export function setGroupPersonalDataLoadingFalseDispatch() {
  store.dispatch(setGroupPersonalDataLoadingFalse());
}

export function setLearningWordGroupPersonalDataDispatch(
  value: learningWordGroupPersonalData,
) {
  store.dispatch(setLearningWordGroupPersonalData(value));
}
