import {
  ENGLISH_VARIANT_AMERICAN,
  ENGLISH_VARIANT_BRITISH,
} from "../constants/learnConstants";

const tokenName = "token";
const useCookiesName = "useCookies";
const englishContentVariantName = "englishContentVariant";

export const storageGetToken: string =
  window.localStorage.getItem(tokenName) ?? "";

export const storageSetToken = (token: string) => {
  window.localStorage.setItem(tokenName, token);
};

export const storageClearToken = () => {
  window.localStorage.removeItem(tokenName);
};

export const storageUseCookies: string =
  window.localStorage.getItem(useCookiesName) ?? "";

export const storageSetUseCookiesTrue = () => {
  window.localStorage.setItem(useCookiesName, "true");
};

export const storageGetEnglishContentVariant: "british" | "american" =
  window.localStorage.getItem(englishContentVariantName) ===
  ENGLISH_VARIANT_BRITISH
    ? ENGLISH_VARIANT_BRITISH
    : ENGLISH_VARIANT_AMERICAN;

export const storageSetEnglishContentVariant = (
  value: "british" | "american",
) => {
  window.localStorage.setItem(englishContentVariantName, value);
};
