import learningWord from "../../models/learningWord";
import store from "../store";
import {
  clearWordCreate,
  setLearningWord,
  setWordCreateLoadingFalse,
  setWordCreateLoadingTrue,
} from "./wordCrateReduce";

export function clearWordCreateDispatch() {
  store.dispatch(clearWordCreate());
}

export function setLearningWordDispatch(value: learningWord | null) {
  store.dispatch(setLearningWord(value));
}

export function setWordCreateLoadingTrueDispatch() {
  store.dispatch(setWordCreateLoadingTrue());
}

export function setWordCreateLoadingFalseDispatch() {
  store.dispatch(setWordCreateLoadingFalse());
}
