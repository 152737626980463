import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupPay from "../../models/learningWordGroupPay";

export interface groupDataPayStoreInterface {
  learningWordGroupPay: learningWordGroupPay | null;
  groupDataPayLoading: boolean;
  groupDataPayAccess: boolean;
}

export const initData: groupDataPayStoreInterface = {
  learningWordGroupPay: null,
  groupDataPayLoading: true,
  groupDataPayAccess: true,
};

export const groupDataPayReduceSlice = createSlice({
  name: "groupDataPayReduce",
  initialState: initData,
  reducers: {
    clearGroupDataPay: (state: groupDataPayStoreInterface) => {
      state.learningWordGroupPay = initData.learningWordGroupPay;
      state.groupDataPayLoading = initData.groupDataPayLoading;
      state.groupDataPayAccess = initData.groupDataPayAccess;
    },
    setLearningWordGroupPay: (
      state: groupDataPayStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupPay;
      },
    ) => {
      state.learningWordGroupPay = action.payload;
    },
    setGroupDataPayLoadingTrue: (state: groupDataPayStoreInterface) => {
      state.groupDataPayLoading = true;
    },
    setGroupDataPayLoadingFalse: (state: groupDataPayStoreInterface) => {
      state.groupDataPayLoading = false;
    },
    setGroupDataPayAccessTrue: (state: groupDataPayStoreInterface) => {
      state.groupDataPayAccess = true;
    },
    setGroupDataPayAccessFalse: (state: groupDataPayStoreInterface) => {
      state.groupDataPayAccess = false;
    },
  },
});

export const {
  clearGroupDataPay,
  setLearningWordGroupPay,
  setGroupDataPayLoadingTrue,
  setGroupDataPayLoadingFalse,
  setGroupDataPayAccessTrue,
  setGroupDataPayAccessFalse,
} = groupDataPayReduceSlice.actions;

export default groupDataPayReduceSlice.reducer;
