import { createSlice } from "@reduxjs/toolkit";
import learningWordGroup from "../../models/learningWordGroup";

export interface groupListStoreInterface {
  groupListLoading: boolean;
  learningWordGroups: learningWordGroup[];
}

export const initGroupListState: groupListStoreInterface = {
  groupListLoading: false,
  learningWordGroups: [],
};

export const groupListReduceSlice = createSlice({
  name: "GroupListReduce",
  initialState: initGroupListState,
  reducers: {
    clearGroupList: (state: groupListStoreInterface) => {
      state.groupListLoading = false;
      state.learningWordGroups = [];
    },
    setGroupListLoadingTrue: (state: groupListStoreInterface) => {
      state.groupListLoading = true;
    },
    setGroupListLoadingFalse: (state: groupListStoreInterface) => {
      state.groupListLoading = false;
    },
    setLearningWordGroups: (
      state: groupListStoreInterface,
      action: { payload: learningWordGroup[]; type: string },
    ) => {
      state.learningWordGroups = action.payload;
    },
  },
});

export const {
  clearGroupList,
  setGroupListLoadingTrue,
  setGroupListLoadingFalse,
  setLearningWordGroups,
} = groupListReduceSlice.actions;

export default groupListReduceSlice.reducer;
