import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

const severityError = "error";
const severityWarning = "warning";
const severityInfo = "info";
const severitySuccess = "success";

export interface alertStoreInterface {
  visible: boolean;
  text: string;
  severity: AlertColor;
}

export const initAlertData: alertStoreInterface = {
  visible: false,
  text: "",
  severity: severityError,
};

export const alertReduceSlice = createSlice({
  name: "alertReduce",
  initialState: initAlertData,
  reducers: {
    alertError: (
      state: alertStoreInterface,
      action: {
        payload: string;
        type: string;
      },
    ) => {
      state.severity = severityError;
      state.text = action.payload;
      state.visible = true;
    },
    alertWarning: (
      state: alertStoreInterface,
      action: {
        payload: string;
        type: string;
      },
    ) => {
      state.severity = severityWarning;
      state.text = action.payload;
      state.visible = true;
    },
    alertInfo: (
      state: alertStoreInterface,
      action: {
        payload: string;
        type: string;
      },
    ) => {
      state.severity = severityInfo;
      state.text = action.payload;
      state.visible = true;
    },
    alertSuccess: (
      state: alertStoreInterface,
      action: {
        payload: string;
        type: string;
      },
    ) => {
      state.severity = severitySuccess;
      state.text = action.payload;
      state.visible = true;
    },
    alertClose: (state: alertStoreInterface) => {
      state.visible = false;
      state.text = "";
    },
  },
});

export const { alertError, alertWarning, alertInfo, alertSuccess, alertClose } =
  alertReduceSlice.actions;

export default alertReduceSlice.reducer;
