import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import formErrorsSelectors from "../../../redux/formErrors/formErrorsSelectors";
import { FormErrorsText } from "../FormErrorsText";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export interface passwordInputPropsInterface {
  password: string;
  loading: boolean;
  valueChangeHandle: CallableFunction;
}

export default function PasswordInput(props: passwordInputPropsInterface) {
  const [showPassword, setShowPassword] = useState(false);
  const errors = useSelector(formErrorsSelectors.formErrors).password;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  function changeHandle(e: any) {
    props.valueChangeHandle(e.currentTarget.value);
  }

  function isError() {
    return errors?.length > 0;
  }

  return (
    <>
      <Grid container>
        <Grid xs={10} item>
          <TextField
            label=""
            type={showPassword ? "text" : "password"}
            sx={{ m: "5px", width: "100%" }}
            size="small"
            value={props.password}
            onChange={changeHandle}
            disabled={props.loading}
            error={isError()}
          />
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            placeSelf: "center",
            pl: "10px",
            textAlign: "left",
          }}
        >
          <IconButton
            size="small"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ fontSize: 10, pl: 1 }}>
        {
          "Не короче 6 символов" // + ", должен состоять из A-z, 0-9 и одного из символов !$#%"
        }
      </Box>
      <Box>
        <FormErrorsText propertyValues={errors} />
      </Box>
    </>
  );
}
