import React from "react";
import { useSelector } from "react-redux";
import appSelectors from "../../../redux/app/appSelectors";
import { Button } from "@mui/material";
import { setEnglishContentVariantDispatch } from "../../../redux/app/appDispatcher";
import {
  ENGLISH_VARIANT_AMERICAN,
  ENGLISH_VARIANT_BRITISH,
} from "../../../constants/learnConstants";
import CachedIcon from "@mui/icons-material/Cached";

export default function EnglishVariantChecker() {
  const englishContentVariant = useSelector(appSelectors.englishContentVariant);

  function changeEnglishContentVariant() {
    setEnglishContentVariantDispatch(
      englishContentVariant === ENGLISH_VARIANT_BRITISH
        ? ENGLISH_VARIANT_AMERICAN
        : ENGLISH_VARIANT_BRITISH,
    );
  }

  return (
    <>
      <Button
        onClick={changeEnglishContentVariant}
        startIcon={<CachedIcon />}
        variant="text"
      >
        {englishContentVariant === ENGLISH_VARIANT_BRITISH
          ? "Британский вариант"
          : "Американский вариант"}
      </Button>
    </>
  );
}
