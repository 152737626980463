import { createSlice } from "@reduxjs/toolkit";

export interface groupDataLearnStoreInterface {
  groupDataLearnAccess: boolean;
  groupDataLearnLoading: boolean;
  countNewWordsInGroup: number;
  countAccessTests: number;
}

const initStore: groupDataLearnStoreInterface = {
  groupDataLearnAccess: true,
  groupDataLearnLoading: true,
  countNewWordsInGroup: 0,
  countAccessTests: 0,
};

export const groupDataLearnReduceSlice = createSlice({
  name: "groupDataLearnReduce",
  initialState: initStore,
  reducers: {
    clearGroupDataLearn: (state: groupDataLearnStoreInterface) => {
      state.groupDataLearnAccess = initStore.groupDataLearnAccess;
      state.groupDataLearnLoading = initStore.groupDataLearnLoading;
      state.countNewWordsInGroup = initStore.countNewWordsInGroup;
      state.countAccessTests = initStore.countAccessTests;
    },
    setGroupDataLearnAccessTrue: (state: groupDataLearnStoreInterface) => {
      state.groupDataLearnAccess = true;
    },
    setGroupDataLearnAccessFalse: (state: groupDataLearnStoreInterface) => {
      state.groupDataLearnAccess = false;
    },
    setGroupDataLearnLoadingTrue: (state: groupDataLearnStoreInterface) => {
      state.groupDataLearnLoading = true;
    },
    setGroupDataLearnLoadingFalse: (state: groupDataLearnStoreInterface) => {
      state.groupDataLearnLoading = false;
    },
    setCountNewWordsInGroup: (
      state: groupDataLearnStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countNewWordsInGroup = actions.payload;
    },
    setCountAccessTests: (
      state: groupDataLearnStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.countAccessTests = actions.payload;
    },
  },
});

export const {
  clearGroupDataLearn,
  setGroupDataLearnAccessTrue,
  setGroupDataLearnAccessFalse,
  setGroupDataLearnLoadingTrue,
  setGroupDataLearnLoadingFalse,
  setCountNewWordsInGroup,
  setCountAccessTests,
} = groupDataLearnReduceSlice.actions;

export default groupDataLearnReduceSlice.reducer;
