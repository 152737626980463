import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupTest from "../../models/learningWordGroupTest";

export interface groupTestListStoreInterface {
  groupTestListAccess: boolean;
  learningWordGroupTestList: learningWordGroupTest[];
  learningWordGroupTestListLoading: boolean;
}

export const initGroupTest: groupTestListStoreInterface = {
  groupTestListAccess: true,
  learningWordGroupTestList: [],
  learningWordGroupTestListLoading: true,
};

export const groupTestListReduceSlice = createSlice({
  name: "groupTestReduce",
  initialState: initGroupTest,
  reducers: {
    clearGroupTestList: (state: groupTestListStoreInterface) => {
      state.groupTestListAccess = true;
      state.learningWordGroupTestList = [];
      state.learningWordGroupTestListLoading = true;
    },
    setGroupTestListAccessTrue: (state: groupTestListStoreInterface) => {
      state.groupTestListAccess = true;
    },
    setGroupTestListAccessFalse: (state: groupTestListStoreInterface) => {
      state.groupTestListAccess = false;
    },
    setLearningWordGroupTestList: (
      state: groupTestListStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupTest[];
      },
    ) => {
      state.learningWordGroupTestList = action.payload;
    },
    setLearningWordGroupTestListLoadingTrue: (
      state: groupTestListStoreInterface,
    ) => {
      state.learningWordGroupTestListLoading = true;
    },
    setLearningWordGroupTestListLoadingFalse: (
      state: groupTestListStoreInterface,
    ) => {
      state.learningWordGroupTestListLoading = false;
    },
  },
});

export const {
  clearGroupTestList,
  setGroupTestListAccessTrue,
  setGroupTestListAccessFalse,
  setLearningWordGroupTestList,
  setLearningWordGroupTestListLoadingTrue,
  setLearningWordGroupTestListLoadingFalse,
} = groupTestListReduceSlice.actions;

export default groupTestListReduceSlice.reducer;
