import "./index.css";
import "typeface-roboto";

import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import Pusher from "pusher-js";

import store from "./redux/store";
import App from "./App";

declare global {
  interface Window {
    Pusher: any;
  }
}

window.Pusher = Pusher;

const root = createRoot(
  document.getElementById("root") ?? document.createElement("root"),
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
);
