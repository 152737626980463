import learnCheckRequest from "./learnCheckRequest";

export default function learnCheckData(
  learningWordId: number,
  learningWordTranslateId: number,
): learnCheckRequest {
  return {
    learningWordId: learningWordId,
    learningWordTranslateId: learningWordTranslateId,
  };
}
