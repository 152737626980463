import React from "react";
import SignInPage from "../containers/pages/SignInPage";
import { Navigate } from "react-router-dom";
import store from "../redux/store";
import { ROUTE_FILL_USER_DATA, ROUTE_HOME } from "../constants/routesConstants";
import NotFoundPage from "../containers/pages/NotFoundPage";

const stopMiddlewareIsNotAuthMiddleware = () => {
  if (!isAuth()) {
    throw new Error();
  }
};

const toHomeIfAuthMiddleware = () => {
  if (isAuth()) {
    throw new Error();
  }
};

const isAuth = () => {
  const token = store.getState().app.token;

  return token.length !== 0;
};

const toHomeIfAuthNavigate = () => {
  return <Navigate to={ROUTE_HOME} />;
};

const authMiddleware = () => {
  if (!isAuth()) {
    throw new Error();
  }
};

const authNavigate = () => {
  return <SignInPage />;
};

export const fillUserDataMiddleware = () => {
  if (!fullFillUserData()) {
    throw new Error();
  }
};

const fullFillUserData = () => {
  // const name = store.getState().userData.userData.name;
  const email = store.getState().userData.userData.email;

  return email !== "";
};

const fillUserDataNavigate: () => JSX.Element = () => {
  return <Navigate to={ROUTE_FILL_USER_DATA} />;
};

const fullFillUserDataMiddleware = () => {
  if (fullFillUserData()) {
    throw new Error();
  }
};

const fullFillUserDataNavigate = () => {
  return <Navigate to={ROUTE_HOME} />;
};

const toNotFoundIsNotAuthMiddleware = () => {
  if (!isAuth()) {
    throw new Error();
  }
};

const toNotFoundIsNotAuthNavigate = () => {
  return <NotFoundPage />;
};

export default function middleware(
  component: JSX.Element,
  middlewareArray: (
    | "stopMiddlewareIsNotAuthMiddleware"
    | "toHomeIfAuthMiddleware"
    | "authMiddleware"
    | "fillUserDataMiddleware"
    | "fullFillUserDataMiddleware"
    | "toNotFoundIsNotAuthMiddleware"
  )[],
) {
  for (const middleware of middlewareArray) {
    switch (middleware) {
      case "stopMiddlewareIsNotAuthMiddleware":
        try {
          stopMiddlewareIsNotAuthMiddleware();
        } catch (e) {
          return component;
        }
        break;
      case "toHomeIfAuthMiddleware":
        try {
          toHomeIfAuthMiddleware();
        } catch (e) {
          return toHomeIfAuthNavigate();
        }
        break;
      case "authMiddleware":
        try {
          authMiddleware();
        } catch (e) {
          return authNavigate();
        }
        break;
      case "fillUserDataMiddleware":
        try {
          fillUserDataMiddleware();
        } catch (e) {
          return fillUserDataNavigate();
        }
        break;
      case "fullFillUserDataMiddleware":
        try {
          fullFillUserDataMiddleware();
        } catch (e) {
          return fullFillUserDataNavigate();
        }
        break;
      case "toNotFoundIsNotAuthMiddleware":
        try {
          toNotFoundIsNotAuthMiddleware();
        } catch (e) {
          return toNotFoundIsNotAuthNavigate();
        }
        break;
      default:
        throw new Error("Middleware " + middleware + "not found");
    }
  }

  return component;
}
