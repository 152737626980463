import { Box, Checkbox, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import userDataSelectors from "../../../redux/userData/userDataSelectors";
import {
  isEmailIncorrectFormat,
  isEmailValueEmpty,
  isPasswordIncorrectFormat,
  isPasswordNotSame,
  isPasswordRepeatValueEmpty,
  isPasswordValueEmpty,
  isPrivacyPolicyCheckboxValueEmpty,
  isUserAgreementCheckboxValueEmpty,
} from "../../../services/formCheckService/formCheckService";
import EmailInput from "../../forms/inputs/EmailInput";
import { clearFormError } from "../../../services/formErrors/formErrorsService";
import PasswordInput from "../../forms/inputs/PasswordInput";
import PasswordRepeatInput from "../../forms/inputs/PasswordRepeatInput";
import createEmailAuthData from "../../../api/createEmailAuth/createEmailAuthData";
import { createEmailAuthApi } from "../../../api/createEmailAuth/createEmailAuthApi";
import UserAgreementCheckbox from "../../forms/inputs/UserAgreementCheckbox";
import { Link } from "react-router-dom";
import {
  ROUTE_AGREEMENT,
  ROUTE_PRIVACY,
} from "../../../constants/routesConstants";
import PrivacyPolicyCheckbox from "../../forms/inputs/PrivacyPolicyCheckbox";

export default function EnterAccessLayout() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [userAgreementCheckbox, setUserAgreementCheckbox] = useState(false);
  const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);
  const [emailSubscribe, setEmailSubscribe] = useState(false);
  const updateEmailLoading = useSelector(userDataSelectors.updateEmailLoading);

  useEffect(() => {
    clearFormError();

    return () => {
      clearFormError();
    };
  }, []);

  function changeEmailHandle(email: string) {
    setEmail(email);
  }

  function changePasswordHandle(password: string) {
    setPassword(password);
  }

  function changePasswordRepeatHandle(passwordRepeat: string) {
    setPasswordRepeat(passwordRepeat);
  }

  function isEmptyValues() {
    const emailEmpty = isEmailValueEmpty(email);
    const passwordEmpty = isPasswordValueEmpty(password);
    const passwordRepeatEmpty = isPasswordRepeatValueEmpty(passwordRepeat);
    const userAgreementCheckboxEmpty = isUserAgreementCheckboxValueEmpty(
      userAgreementCheckbox,
    );
    const privatePolicyCheckboxEmpty = isPrivacyPolicyCheckboxValueEmpty(
      privacyPolicyCheckbox,
    );

    return (
      emailEmpty ||
      passwordEmpty ||
      passwordRepeatEmpty ||
      userAgreementCheckboxEmpty ||
      privatePolicyCheckboxEmpty
    );
  }

  function checkTwoLevel() {
    const emailIncorrect = isEmailIncorrectFormat(email);
    const passwordIncorrect = isPasswordIncorrectFormat(password);
    const passwordNotSame = isPasswordNotSame(password, passwordRepeat);

    return emailIncorrect || passwordIncorrect || passwordNotSame;
  }

  function handle() {
    clearFormError();

    if (isEmptyValues()) {
      return;
    }

    if (checkTwoLevel()) {
      return;
    }

    const data = createEmailAuthData(email, password, emailSubscribe);
    createEmailAuthApi(data);
  }

  function userAgreementCheckboxHandle() {
    setUserAgreementCheckbox(!userAgreementCheckbox);
  }

  function privacyPolicyCheckboxHandle() {
    setPrivacyPolicyCheckbox(!privacyPolicyCheckbox);
  }

  function emailSubscribeCheckboxHandle() {
    setEmailSubscribe(!emailSubscribe);
  }

  return (
    <>
      <Box sx={{ m: "50px 0" }}>
        <Box sx={{ color: "text.secondary", fontSize: "14px" }}>
          {
            "Введите ваш действующий Email-адрес. В дальнейшем необходимо будет его подтвердить."
          }
        </Box>
        <Grid container>
          <Grid
            xs={3}
            item
            sx={{
              placeSelf: "center",
              pr: "10px",
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Email"}
          </Grid>
          <Grid xs={6} item>
            <EmailInput
              email={email}
              loading={updateEmailLoading}
              valueChangeHandle={changeEmailHandle}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            xs={3}
            item
            sx={{
              placeSelf: "center",
              pr: "10px",
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Пароль:"}
          </Grid>
          <Grid xs={5} item>
            <PasswordInput
              password={password}
              loading={updateEmailLoading}
              valueChangeHandle={changePasswordHandle}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            xs={3}
            item
            sx={{
              placeSelf: "center",
              pr: "10px",
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Пароль (провтор):"}
          </Grid>
          <Grid xs={5} item>
            <PasswordRepeatInput
              passwordRepeat={passwordRepeat}
              loading={updateEmailLoading}
              valueChangeHandle={changePasswordRepeatHandle}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider
        textAlign="left"
        sx={{ mt: "25px", mb: "25px", fontSize: "18px" }}
      >
        {"Дополнительные соглашения"}
      </Divider>
      <Grid container sx={{}}>
        <Grid
          xs={2}
          item
          sx={{
            placeSelf: "center",
            pr: "10px",
          }}
        >
          <UserAgreementCheckbox
            checked={userAgreementCheckbox}
            loading={updateEmailLoading}
            valueChangeHandle={userAgreementCheckboxHandle}
          />
        </Grid>
        <Grid
          xs={10}
          item
          sx={{
            fontSize: "12px",
            placeSelf: "center",
          }}
        >
          {"Соглашаюсь на обработку моих персональных данных в соответствии с "}
          <Link to={ROUTE_PRIVACY}>{"ПОЛИТИКОЙ КОНФИДЕНЦИАЛЬНОСТИ"}</Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          xs={2}
          item
          sx={{
            placeSelf: "center",
            pr: "10px",
          }}
        >
          <PrivacyPolicyCheckbox
            checked={privacyPolicyCheckbox}
            loading={updateEmailLoading}
            valueChangeHandle={privacyPolicyCheckboxHandle}
          />
        </Grid>
        <Grid
          xs={10}
          item
          sx={{
            fontSize: "12px",
            placeSelf: "center",
          }}
        >
          {"Принимаю условия "}
          <Link to={ROUTE_AGREEMENT}>{"ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ"}</Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          xs={2}
          item
          sx={{
            placeSelf: "center",
            pr: "10px",
          }}
        >
          <Checkbox
            disabled={updateEmailLoading}
            checked={emailSubscribe}
            onChange={emailSubscribeCheckboxHandle}
            name={"privacyPolicyCheckbox"}
          />
        </Grid>
        <Grid
          xs={10}
          item
          sx={{
            placeSelf: "center",
            fontSize: "12px",
          }}
        >
          {"Хочу получать сообщения со скидками и акциями"}
        </Grid>
      </Grid>
      <Box sx={{ m: "15px 0" }}>
        <LoadingButton
          variant="contained"
          onClick={handle}
          loading={updateEmailLoading}
        >
          {"Сохранить"}
        </LoadingButton>
      </Box>
    </>
  );
}
