import axios, { responseInterface } from "../axios";
import { API_LEARN_GROUP_PERSONAL_CHECK } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import learnGroupPersonalCheckResponse from "./learnGroupPersonalCheckResponse";
import learnGroupPersonalCheckRequest from "./learnGroupPersonalCheckRequest";
import {
  setCompleteDispatch,
  setLearnGroupPersonalCheckAccessFalseDispatch,
  setLearnGroupPersonalCheckAccessTrueDispatch,
  setLearnGroupPersonalCheckLoadingFalseDispatch,
  setLearnGroupPersonalCheckLoadingTrueDispatch,
  setNewLearningWordDispatch,
  setProgressDispatch,
  setSuccessDispatch,
  setSuccessNullDispatch,
} from "../../redux/learnGroupPersonalCheck/learnGroupPersonalCheckDispatcher";

export const learnGroupPersonalCheckApi = (
  data: learnGroupPersonalCheckRequest,
) => {
  startRequest();

  axios
    .post(API_LEARN_GROUP_PERSONAL_CHECK, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnGroupPersonalCheckLoadingTrueDispatch();
  setSuccessNullDispatch();
};

function successful(
  response: responseInterface<learnGroupPersonalCheckResponse>,
) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnGroupPersonalCheckAccessFalseDispatch();
    setLearnGroupPersonalCheckLoadingFalseDispatch();
    return;
  }

  setLearnGroupPersonalCheckAccessTrueDispatch();
  setNewLearningWordDispatch(response.data.newLearningWord);
  setSuccessDispatch(response.data.success);
  setCompleteDispatch(response.data.complete);
  setProgressDispatch(response.data.progress);

  setLearnGroupPersonalCheckLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
