import axios, { responseInterface } from "../axios";
import { API_LEARN_GROUP_PERSONAL_DATA } from "../../constants/apiRoutConstants";
import { STATUS_FORBIDDEN } from "../../constants/errorCodes";
import { alertAppError } from "../../constants/alertConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearnGroupPersonalDataAccessFalseDispatch,
  setLearnGroupPersonalDataLoadingFalseDispatch,
  setLearnGroupPersonalDataLoadingTrueDispatch,
  setCompleteDispatch,
  setLastNewLearningWordDispatch,
  setProgressDispatch,
  setUpdateNewWordDispatch,
  setLearnGroupPersonalDataAccessTrueDispatch,
  setPaidAccessDispatch,
  setLearningWordSoundTypesDispatch,
  setLearningWordTranscriptionTypesDispatch,
  setTitleDispatch,
  setCountWordsDispatch,
  setCountLearningWordDispatch,
  setIssetNotPublicWordDispatch,
} from "../../redux/learnGroupPersonalData/learnGroupPersonalDataDispatcher";
import learnGroupPersonalDataResponse from "./learnGroupPersonalDataResponse";
import learnGroupPersonalDataRequest from "./learnGroupPersonalDataRequest";

export const learnGroupPersonalDataApi = (
  data: learnGroupPersonalDataRequest,
) => {
  startRequest();

  axios
    .get(API_LEARN_GROUP_PERSONAL_DATA, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful();
    });
};

const startRequest = () => {
  setLearnGroupPersonalDataLoadingTrueDispatch();
};

function successful(
  response: responseInterface<learnGroupPersonalDataResponse>,
) {
  if (response.statusCode === STATUS_FORBIDDEN) {
    setLearnGroupPersonalDataAccessFalseDispatch();
    setLearnGroupPersonalDataLoadingFalseDispatch();
    return;
  }

  setLearnGroupPersonalDataAccessTrueDispatch();
  setIssetNotPublicWordDispatch(response.data.issetNotPublicWord);
  setTitleDispatch(response.data.title);
  setLastNewLearningWordDispatch(response.data.lastNewLearningWord);
  setUpdateNewWordDispatch(response.data.updateNewWord);
  setCompleteDispatch(response.data.complete);
  setProgressDispatch(response.data.progress);
  setPaidAccessDispatch(response.data.paidAccess);
  setCountLearningWordDispatch(response.data.countLearningWord);
  setCountWordsDispatch(response.data.countWords);
  setLearningWordSoundTypesDispatch(response.data.learningWordSoundTypes);
  setLearningWordTranscriptionTypesDispatch(
    response.data.learningWordTranscriptionTypes,
  );

  setLearnGroupPersonalDataLoadingFalseDispatch();
}

function unsuccessful() {
  alertWarningDispatch(alertAppError);
}
