import newLearningWord from "../../models/newLearningWord";
import store from "../store";
import {
  clearLearnGroupPersonalCheck,
  setComplete,
  setLearnGroupPersonalCheckAccessFalse,
  setLearnGroupPersonalCheckAccessTrue,
  setLearnGroupPersonalCheckLoadingFalse,
  setLearnGroupPersonalCheckLoadingTrue,
  setNewLearningWord,
  setProgress,
  setSuccess,
  setSuccessNull,
} from "./learnGroupPersonalCheckReduce";

export function clearLearnGroupPersonalCheckDispatch() {
  store.dispatch(clearLearnGroupPersonalCheck());
}

export function setNewLearningWordDispatch(
  learnGroupPersonalCheck: newLearningWord | null,
) {
  store.dispatch(setNewLearningWord(learnGroupPersonalCheck));
}

export function setLearnGroupPersonalCheckAccessTrueDispatch() {
  store.dispatch(setLearnGroupPersonalCheckAccessTrue());
}

export function setLearnGroupPersonalCheckAccessFalseDispatch() {
  store.dispatch(setLearnGroupPersonalCheckAccessFalse());
}

export function setLearnGroupPersonalCheckLoadingTrueDispatch() {
  store.dispatch(setLearnGroupPersonalCheckLoadingTrue());
}

export function setLearnGroupPersonalCheckLoadingFalseDispatch() {
  store.dispatch(setLearnGroupPersonalCheckLoadingFalse());
}

export function setSuccessNullDispatch() {
  store.dispatch(setSuccessNull());
}

export function setSuccessDispatch(success: boolean) {
  store.dispatch(setSuccess(success));
}

export function setCompleteDispatch(complete: boolean) {
  store.dispatch(setComplete(complete));
}

export function setProgressDispatch(progress: number) {
  store.dispatch(setProgress(progress));
}
