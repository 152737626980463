import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupTestListItem from "../../models/learningWordGroupTestListItem";
import learnExam from "../../models/learnExam";

export interface learnTestListStoreInterface {
  learningWordGroupTestList: learningWordGroupTestListItem[];
  learnExam: learnExam | null;
  learnTestListLoading: boolean;
  learnTestListAccess: boolean;
}

export const initData: learnTestListStoreInterface = {
  learningWordGroupTestList: [],
  learnExam: null,
  learnTestListLoading: true,
  learnTestListAccess: true,
};

export const learnTestListReduceSlice = createSlice({
  name: "learnTestListReduce",
  initialState: initData,
  reducers: {
    clearLearnTestList: (state: learnTestListStoreInterface) => {
      state.learningWordGroupTestList = [];
      state.learnExam = null;
      state.learnTestListLoading = true;
      state.learnTestListAccess = true;
    },
    setLearningWordGroupTestList: (
      state: learnTestListStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupTestListItem[];
      },
    ) => {
      state.learningWordGroupTestList = action.payload;
    },
    setLearnExam: (
      state: learnTestListStoreInterface,
      action: {
        type: string;
        payload: learnExam | null;
      },
    ) => {
      state.learnExam = action.payload;
    },
    setLearnTestListLoadingTrue: (state: learnTestListStoreInterface) => {
      state.learnTestListLoading = true;
    },
    setLearnTestListLoadingFalse: (state: learnTestListStoreInterface) => {
      state.learnTestListLoading = false;
    },
    setLearnTestListAccessTrue: (state: learnTestListStoreInterface) => {
      state.learnTestListAccess = true;
    },
    setLearnTestListAccessFalse: (state: learnTestListStoreInterface) => {
      state.learnTestListAccess = false;
    },
  },
});

export const {
  clearLearnTestList,
  setLearningWordGroupTestList,
  setLearnExam,
  setLearnTestListLoadingTrue,
  setLearnTestListLoadingFalse,
  setLearnTestListAccessTrue,
  setLearnTestListAccessFalse,
} = learnTestListReduceSlice.actions;

export default learnTestListReduceSlice.reducer;
