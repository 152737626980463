import React from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import SignUpContainer from "../signUp/signUpContainer";

function SignUpPage() {
  return (
    <>
      <CssBaseline />
      <AppHeadLine />
      <Container component="main" maxWidth="xs">
        <SignUpContainer />
      </Container>
      <Footer />
    </>
  );
}

export default SignUpPage;
