import { createSlice } from "@reduxjs/toolkit";
import userData from "../../models/userData";
import { updateNameResponse } from "../../api/updateName/updateNameResponse";
import { updateEmailResponse } from "../../api/updateEmail/updateEmailResponse";
import socialRemoveResponse from "../../api/socialRemove/socialRemoveResponse";

export interface userDataStoreInterface {
  userDataLoading: boolean;
  updateNameLoading: boolean;
  updateEmailLoading: boolean;
  updatePasswordLoading: boolean;
  updatePasswordSuccess: boolean;
  updateSocialAuthLoading: boolean;
  authLoading: boolean;
  sendResetPasswordSuccess: boolean;
  userData: userData;
  newGroupEnvironmentLoading: boolean;
}

export const initUserData: userDataStoreInterface = {
  userDataLoading: true,
  updateNameLoading: false,
  updateEmailLoading: false,
  updatePasswordLoading: false,
  updatePasswordSuccess: false,
  updateSocialAuthLoading: false,
  authLoading: false,
  sendResetPasswordSuccess: false,
  userData: {
    userId: 0,
    balance: 0,
    lastLearningGroupId: null,
    name: "",
    email: "",
    socialAuth: [],
    countLearningWord: 0,
    confirmEmail: null,
    freeWordCountWithoutConfirmEmail: 0,
  },
  newGroupEnvironmentLoading: false,
};

const userDataReduceSlice = createSlice({
  name: "userDataReduce",
  initialState: initUserData,
  reducers: {
    setUserData: (
      state: userDataStoreInterface,
      action: { payload: userData },
    ) => {
      state.userData = action.payload;
    },
    updateLastLearningGroupId: (
      state: userDataStoreInterface,
      action: {
        payload: number;
        type: string;
      },
    ) => {
      if (state.userData !== null) {
        state.userData.lastLearningGroupId = action.payload;
      }
    },
    userDataLoading: (state: userDataStoreInterface) => {
      state.userDataLoading = true;
    },
    userDataStopLoading: (state: userDataStoreInterface) => {
      state.userDataLoading = false;
    },
    clearUserData: (state: userDataStoreInterface) => {
      state = {
        ...initUserData,
      };
    },
    startUpdateName: (state: userDataStoreInterface) => {
      state.updateNameLoading = true;
    },
    stopUpdateName: (state: userDataStoreInterface) => {
      state.updateNameLoading = false;
    },
    updateUserDataName: (
      state: userDataStoreInterface,
      action: {
        payload: updateNameResponse;
        type: string;
      },
    ) => {
      state.userData.name = action.payload.name;
    },
    startUpdateEmail: (state: userDataStoreInterface) => {
      state.updateEmailLoading = true;
    },
    stopUpdateEmail: (state: userDataStoreInterface) => {
      state.updateEmailLoading = false;
    },
    updateUserDataEmail: (
      state: userDataStoreInterface,
      action: {
        payload: updateEmailResponse;
        type: string;
      },
    ) => {
      state.userData.email = action.payload.email;
    },
    startUpdatePassword: (state: userDataStoreInterface) => {
      state.updatePasswordLoading = true;
    },
    stopUpdatePassword: (state: userDataStoreInterface) => {
      state.updatePasswordLoading = false;
    },
    updatePasswordSuccessTrue: (state: userDataStoreInterface) => {
      state.updatePasswordSuccess = true;
    },
    updatePasswordSuccessFalse: (state: userDataStoreInterface) => {
      state.updatePasswordSuccess = false;
    },
    updateSocialAuthLoadingTrue: (state: userDataStoreInterface) => {
      state.updateSocialAuthLoading = true;
    },
    updateSocialAuthLoadingFalse: (state: userDataStoreInterface) => {
      state.updateSocialAuthLoading = false;
    },
    authLoadingTrue: (state: userDataStoreInterface) => {
      state.authLoading = true;
    },
    authLoadingFalse: (state: userDataStoreInterface) => {
      state.authLoading = false;
    },
    sendResetPasswordSuccessTrue: (state: userDataStoreInterface) => {
      state.sendResetPasswordSuccess = true;
    },
    sendResetPasswordSuccessFalse: (state: userDataStoreInterface) => {
      state.sendResetPasswordSuccess = false;
    },
    updateUserDataSocialAuth: (
      state: userDataStoreInterface,
      action: {
        payload: socialRemoveResponse;
        type: string;
      },
    ) => {
      state.userData.socialAuth = action.payload.socialAuth;
    },
    updateUserDataBalance: (
      state: userDataStoreInterface,
      action: {
        payload: number;
        type: string;
      },
    ) => {
      state.userData.balance = action.payload;
    },
    setNewGroupEnvironmentLoadingTrue: (state: userDataStoreInterface) => {
      state.newGroupEnvironmentLoading = true;
    },
    setNewGroupEnvironmentLoadingFalse: (state: userDataStoreInterface) => {
      state.newGroupEnvironmentLoading = false;
    },
    setCountLearningWord: (
      state: userDataStoreInterface,
      action: {
        payload: number;
        type: string;
      },
    ) => {
      state.userData.countLearningWord = action.payload;
    },
  },
});

export const {
  setUserData,
  updateLastLearningGroupId,
  userDataLoading,
  userDataStopLoading,
  clearUserData,
  startUpdateName,
  stopUpdateName,
  updateUserDataName,
  startUpdateEmail,
  stopUpdateEmail,
  updateUserDataEmail,
  startUpdatePassword,
  stopUpdatePassword,
  updatePasswordSuccessTrue,
  updatePasswordSuccessFalse,
  updateSocialAuthLoadingTrue,
  updateSocialAuthLoadingFalse,
  authLoadingTrue,
  authLoadingFalse,
  sendResetPasswordSuccessTrue,
  sendResetPasswordSuccessFalse,
  updateUserDataSocialAuth,
  updateUserDataBalance,
  setNewGroupEnvironmentLoadingTrue,
  setNewGroupEnvironmentLoadingFalse,
  setCountLearningWord,
} = userDataReduceSlice.actions;

export default userDataReduceSlice.reducer;
