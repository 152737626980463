import { createSlice } from "@reduxjs/toolkit";
import selectValueWord from "../../models/selectValueWord";

export interface workoutProcessStoreInterface {
  workoutProcessAccess: boolean;
  workoutProcessLoading: boolean;
  success: boolean | null;
  selectValueWord: selectValueWord | null;
}

const initStore: workoutProcessStoreInterface = {
  workoutProcessAccess: true,
  workoutProcessLoading: false,
  success: null,
  selectValueWord: null,
};

export const workoutProcessReduceSlice = createSlice({
  name: "workoutProcessReduce",
  initialState: initStore,
  reducers: {
    clearWorkoutProcess: (state: workoutProcessStoreInterface) => {
      state.workoutProcessAccess = initStore.workoutProcessAccess;
      state.workoutProcessLoading = initStore.workoutProcessLoading;
      state.success = initStore.success;
      state.selectValueWord = initStore.selectValueWord;
    },
    setWorkoutProcessAccessTrue: (state: workoutProcessStoreInterface) => {
      state.workoutProcessAccess = true;
    },
    setWorkoutProcessAccessFalse: (state: workoutProcessStoreInterface) => {
      state.workoutProcessAccess = false;
    },
    workoutProcessLoadingTrue: (state: workoutProcessStoreInterface) => {
      state.workoutProcessLoading = true;
    },
    workoutProcessLoadingFalse: (state: workoutProcessStoreInterface) => {
      state.workoutProcessLoading = false;
    },
    setSuccessNull: (state: workoutProcessStoreInterface) => {
      state.success = null;
    },
    setSuccessTrue: (state: workoutProcessStoreInterface) => {
      state.success = true;
    },
    setSuccessFalse: (state: workoutProcessStoreInterface) => {
      state.success = false;
    },
    setSelectValueWord: (
      state: workoutProcessStoreInterface,
      actions: { payload: selectValueWord; type: string },
    ) => {
      state.selectValueWord = actions.payload;
    },
    setSelectValueWordNull: (state: workoutProcessStoreInterface) => {
      state.selectValueWord = null;
    },
    setSelectValueWordSuccessTrue: (state: workoutProcessStoreInterface) => {
      if (state.selectValueWord) {
        state.selectValueWord.success = true;
      }
    },
    setSelectValueWordSuccessFalse: (state: workoutProcessStoreInterface) => {
      if (state.selectValueWord) {
        state.selectValueWord.success = false;
      }
    },
  },
});

export const {
  clearWorkoutProcess,
  setWorkoutProcessAccessTrue,
  setWorkoutProcessAccessFalse,
  workoutProcessLoadingTrue,
  workoutProcessLoadingFalse,
  setSuccessNull,
  setSuccessTrue,
  setSuccessFalse,
  setSelectValueWord,
  setSelectValueWordNull,
  setSelectValueWordSuccessTrue,
  setSelectValueWordSuccessFalse,
} = workoutProcessReduceSlice.actions;

export default workoutProcessReduceSlice.reducer;
