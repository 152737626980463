import React, { useState } from "react";
import { Box, Button, List, ListItem } from "@mui/material";
import PersonalGroupItemComponent from "../../components/item/groups/PersonalGroupItemComponent";
import { ROUTE_GROUP_PERSONAL_ADD } from "../../constants/routesConstants";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../components/pagination/PaginationComponent";
import { useSelector } from "react-redux";
import { countPage, elementsForPage } from "../../helpers/paginateHelper";
import groupPersonalListSelectors from "../../redux/groupPersonalList/groupPersonalListSelectors";
import learningWordGroupPersonal from "../../models/learningWordGroupPersonal";

function PersonalGroupsContainer() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const pageSize = 4;
  const learningWordGroupPersonals = useSelector(
    groupPersonalListSelectors.learningWordGroupPersonals,
  );

  function actionToGroupPersonalAdd() {
    navigate(ROUTE_GROUP_PERSONAL_ADD);
  }

  function handlePagination(newPage: number) {
    setPage(newPage);
  }

  function PaginationLayout() {
    const allResponseCount = learningWordGroupPersonals.length;
    const allPagesCount = countPage(allResponseCount, pageSize);

    return (
      <>
        {allPagesCount <= 1 ? (
          ""
        ) : (
          <PaginationComponent
            count={allPagesCount}
            page={page}
            handleChange={handlePagination}
          />
        )}
      </>
    );
  }

  function EmptyLayout() {
    return (
      <Box sx={{ width: "100%", textAlign: "center", margin: "10px 0" }}>
        {"Нет созданных словарей"}
      </Box>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={actionToGroupPersonalAdd}
      >
        {"Создать словарь"}
      </Button>
      <Box sx={{ display: "flex", justifyContent: "center", m: "15px 0" }}>
        <PaginationLayout />
      </Box>
      <List component="nav">
        {elementsForPage({
          array: learningWordGroupPersonals,
          pageSize: pageSize,
          page: page,
        }).map(
          (
            learningWordGroupPersonal: learningWordGroupPersonal,
            index: number,
          ) => (
            <ListItem key={index}>
              <PersonalGroupItemComponent
                learningWordGroupPersonal={learningWordGroupPersonal}
              />
            </ListItem>
          ),
        )}
      </List>
      {learningWordGroupPersonals.length === 0 ? <EmptyLayout /> : ""}
      <Box sx={{ display: "flex", justifyContent: "center", mt: "15px" }}>
        <PaginationLayout />
      </Box>
    </>
  );
}

export default PersonalGroupsContainer;
