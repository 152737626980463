import { storeInterface } from "../store";

const userUnsubscribeLoading = (state: storeInterface) =>
  state.userUnsubscribe.userUnsubscribeLoading;
const userUnsubscribeComplete = (state: storeInterface) =>
  state.userUnsubscribe.userUnsubscribeComplete;

export default {
  userUnsubscribeLoading,
  userUnsubscribeComplete,
};
