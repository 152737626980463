import { storeInterface } from "../store";

const lastNewLearningWord = (state: storeInterface) =>
  state.learnData.lastNewLearningWord;
const learnDataLoading = (state: storeInterface) =>
  state.learnData.learnDataLoading;
const learnDataAccess = (state: storeInterface) =>
  state.learnData.learnDataAccess;
const updateNewWord = (state: storeInterface) => state.learnData.updateNewWord;
const complete = (state: storeInterface) => state.learnData.complete;
const progress = (state: storeInterface) => state.learnData.progress;
const paidAccess = (state: storeInterface) => state.learnData.paidAccess;
const learningWordSoundTypes = (state: storeInterface) =>
  state.learnData.learningWordSoundTypes;
const learningWordTranscriptionTypes = (state: storeInterface) =>
  state.learnData.learningWordTranscriptionTypes;

export default {
  lastNewLearningWord,
  learnDataLoading,
  learnDataAccess,
  updateNewWord,
  complete,
  progress,
  paidAccess,
  learningWordSoundTypes,
  learningWordTranscriptionTypes,
};
