import chooseValueWord from "../../models/chooseValueWord";
import store from "../store";
import {
  clearLearnGroupPersonalNext,
  setLearnGroupPersonalNext,
  setLearnGroupPersonalNextAccessFalse,
  setLearnGroupPersonalNextAccessTrue,
  setLearnGroupPersonalNextLoadingFalse,
  setLearnGroupPersonalNextLoadingTrue,
} from "./learnGroupPersonalNextReduce";

export function clearLearnGroupPersonalNextDispatch() {
  store.dispatch(clearLearnGroupPersonalNext());
}

export function setLearnGroupPersonalNextDispatch(
  learnGroupPersonalNext: chooseValueWord,
) {
  store.dispatch(setLearnGroupPersonalNext(learnGroupPersonalNext));
}

export function setLearnGroupPersonalNextAccessTrueDispatch() {
  store.dispatch(setLearnGroupPersonalNextAccessTrue());
}

export function setLearnGroupPersonalNextAccessFalseDispatch() {
  store.dispatch(setLearnGroupPersonalNextAccessFalse());
}

export function setLearnGroupPersonalNextLoadingTrueDispatch() {
  store.dispatch(setLearnGroupPersonalNextLoadingTrue());
}

export function setLearnGroupPersonalNextLoadingFalseDispatch() {
  store.dispatch(setLearnGroupPersonalNextLoadingFalse());
}
