import React, { useState } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { storeInterface } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import {
  ROUTE_GROUPS,
  ROUTE_GROUPS_AVAILABLE,
  ROUTE_GROUPS_PERSONAL,
  ROUTE_HOME,
  ROUTE_LEARN,
  ROUTE_PAY,
  ROUTE_PROFILE,
  ROUTE_SETTINGS,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_SUPPORT,
  ROUTE_WORKOUT,
  routeLearnGroupNavigate,
} from "../../constants/routesConstants";
import {
  Box,
  IconButton,
  MenuItem,
  Drawer,
  Divider,
  Grid,
} from "@mui/material";
import logoutAction from "../../api/logoutApi";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import userDataSelectors from "../../redux/userData/userDataSelectors";

function AppMenu() {
  const [show, setShow] = useState(false);
  const token = useSelector((state: storeInterface) => state.app.token);
  const navigate = useNavigate();
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const lastLearningGroupId = useSelector(
    userDataSelectors.userData,
  ).lastLearningGroupId;
  const userData = useSelector(userDataSelectors.userData);

  const linkStyle = {
    cursor: "pointer",
    fontSize: "14px",
  };

  const toWorkout = () => {
    navigate(ROUTE_WORKOUT);
  };

  const toSearchGroup = () => {
    navigate(ROUTE_GROUPS);
  };

  const toSignIn = () => {
    navigate(ROUTE_SIGN_IN);
  };

  const toSignUp = () => {
    navigate(ROUTE_SIGN_UP);
  };

  const toLearn = () => {
    navigate(ROUTE_LEARN);
  };

  const toGroupsAvailable = () => {
    navigate(ROUTE_GROUPS_AVAILABLE);
  };

  const toGroupsPersonal = () => {
    navigate(ROUTE_GROUPS_PERSONAL);
  };

  const toSettings = () => {
    navigate(ROUTE_SETTINGS);
  };

  const toPay = () => {
    navigate(ROUTE_PAY);
  };

  // const toProfile = () => {
  //   navigate(ROUTE_PROFILE);
  // };

  const toSupport = () => {
    navigate(ROUTE_SUPPORT);
  };

  const logout = () => {
    logoutAction();
  };

  const toggle = () => {
    setShow(!show);
  };

  function StatisticsLayout() {
    return (
      <>
        <Grid container sx={{ fontSize: "12px", width: "100%" }}>
          <Grid container>
            <Grid
              xs={10}
              item
              sx={{ textAlign: "left", pl: "25px", fontWeight: "bold" }}
            >
              {"Всего изучено слов:"}
            </Grid>
            <Grid xs={2} item sx={{ textAlign: "left" }}>
              {userData.countLearningWord}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  }

  function LoginMenuLayout() {
    return (
      <>
        <MenuItem onClick={toLearn} sx={{ ...linkStyle, fontWeight: "bold" }}>
          {"Продолжить изучение"}
        </MenuItem>
        <MenuItem onClick={toGroupsPersonal} sx={linkStyle}>
          {"Мои словари"}
        </MenuItem>
        <Divider />
        <Box sx={{ mb: "15px" }}>
          <StatisticsLayout />
        </Box>
        <Divider />
        <MenuItem onClick={toPay} sx={linkStyle}>
          {"Оплата подписки"}
        </MenuItem>
        <MenuItem onClick={toSettings} sx={linkStyle}>
          {"Настройки"}
        </MenuItem>
        <MenuItem onClick={logout} sx={{ ...linkStyle, color: "error.main" }}>
          {"Выход"}
        </MenuItem>
      </>
    );
  }

  function GuestMenuLayout() {
    return (
      <>
        <MenuItem onClick={toSignIn} sx={linkStyle}>
          {"Вход"}
        </MenuItem>
        <MenuItem onClick={toSignUp} sx={linkStyle}>
          {"Регистрация"}
        </MenuItem>
      </>
    );
  }

  function StableBottomLayout() {
    return (
      <>
        <Divider />
        <MenuItem onClick={toSupport} sx={linkStyle}>
          {"Служба поддержки"}
        </MenuItem>
      </>
    );
  }

  return (
    <>
      <Box display={{ xs: "flex", md: "flex" }}>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggle}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Box component="nav">
          <Drawer
            anchor="right"
            container={container}
            variant="temporary"
            open={show}
            onClose={toggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                minWidth: "250px",
              },
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Box
                onClick={toggle}
                sx={{
                  textAlign: "left",
                  cursor: "pointer",
                  margin: " 5px 10px",
                }}
              >
                <CloseIcon />
              </Box>
              {token.length > 0 ? <LoginMenuLayout /> : <GuestMenuLayout />}
              <StableBottomLayout />
            </Box>
          </Drawer>
        </Box>
      </Box>
    </>
  );
}

export default AppMenu;
