import { storeInterface } from "../store";

const lastNewLearningWord = (state: storeInterface) =>
  state.learnGroupPersonalData.lastNewLearningWord;
const learnGroupPersonalDataLoading = (state: storeInterface) =>
  state.learnGroupPersonalData.learnGroupPersonalDataLoading;
const learnGroupPersonalDataAccess = (state: storeInterface) =>
  state.learnGroupPersonalData.learnGroupPersonalDataAccess;
const updateNewWord = (state: storeInterface) =>
  state.learnGroupPersonalData.updateNewWord;
const complete = (state: storeInterface) =>
  state.learnGroupPersonalData.complete;
const progress = (state: storeInterface) =>
  state.learnGroupPersonalData.progress;
const paidAccess = (state: storeInterface) =>
  state.learnGroupPersonalData.paidAccess;
const learningWordSoundTypes = (state: storeInterface) =>
  state.learnGroupPersonalData.learningWordSoundTypes;
const learningWordTranscriptionTypes = (state: storeInterface) =>
  state.learnGroupPersonalData.learningWordTranscriptionTypes;
const countLearningWord = (state: storeInterface) =>
  state.learnGroupPersonalData.countLearningWord;
const countWords = (state: storeInterface) =>
  state.learnGroupPersonalData.countWords;
const title = (state: storeInterface) => state.learnGroupPersonalData.title;
const issetNotPublicWord = (state: storeInterface) =>
  state.learnGroupPersonalData.issetNotPublicWord;

export default {
  lastNewLearningWord,
  learnGroupPersonalDataLoading,
  learnGroupPersonalDataAccess,
  updateNewWord,
  complete,
  progress,
  paidAccess,
  learningWordSoundTypes,
  learningWordTranscriptionTypes,
  countLearningWord,
  countWords,
  title,
  issetNotPublicWord,
};
