import { alertAppError } from "../../constants/alertConstants";
import { API_USER_UNSUBSCRIBE } from "../../constants/apiRoutConstants";
import { STATUS_OK } from "../../constants/errorCodes";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setUserUnsubscribeCompleteDispatch,
  setUserUnsubscribeLoadingFalseDispatch,
  setUserUnsubscribeLoadingTrueDispatch,
} from "../../redux/userUnsubscribe/userUnsubscribeDispatcher";
import axios, { responseInterface } from "../axios";
import userUnsubscribeRequest from "./userUnsubscribeRequest";

export default function userUnsubscribeApi(data: userUnsubscribeRequest) {
  startRequest();

  axios
    .post(API_USER_UNSUBSCRIBE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setUserUnsubscribeCompleteDispatch(null);
  setUserUnsubscribeLoadingTrueDispatch();
};

const successful = (response: responseInterface<null>) => {
  if (response.statusCode !== STATUS_OK) {
    alertWarningDispatch(alertAppError);
    setUserUnsubscribeLoadingFalseDispatch();
    return;
  }
  setUserUnsubscribeCompleteDispatch(true);
  setUserUnsubscribeLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
