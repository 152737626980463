import React from "react";
import newLearningWord from "../../../models/newLearningWord";
import { Box } from "@mui/material";
import TranslatesComponent from "../../translates/TranslatesComponent";

interface lastNewLearningWordPropsInterface {
  newLearningWord: newLearningWord;
}

export default function LastNewLearningWordLayout(
  props: lastNewLearningWordPropsInterface,
) {
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Box sx={{ fontSize: "14px", fontWeight: "bold" }}>
          {props.newLearningWord.learningWord.word}
        </Box>
        <Box sx={{ ml: "10px", mr: "10px" }}>{"-"}</Box>
        <Box sx={{ fontSize: "14px" }}>
          <TranslatesComponent translates={props.newLearningWord.translates} />
        </Box>
      </Box>
    </>
  );
}
