import { createSlice } from "@reduxjs/toolkit";
import newLearningWord from "../../models/newLearningWord";
import learningWordSoundType from "../../models/learningWordSoundType";
import learningWordTranscriptionType from "../../models/learningWordTranscriptionType";

export interface learnDataStoreInterface {
  lastNewLearningWord: newLearningWord | null;
  learnDataLoading: boolean;
  learnDataAccess: boolean;
  updateNewWord: boolean;
  complete: boolean;
  progress: number;
  paidAccess: boolean;
  learningWordSoundTypes: learningWordSoundType[];
  learningWordTranscriptionTypes: learningWordTranscriptionType[];
}

const initReduce: learnDataStoreInterface = {
  lastNewLearningWord: null,
  learnDataLoading: true,
  learnDataAccess: true,
  updateNewWord: false,
  complete: false,
  progress: 0,
  paidAccess: true,
  learningWordSoundTypes: [],
  learningWordTranscriptionTypes: [],
};

export const learnDataReduceSlice = createSlice({
  name: "learnDataReduce",
  initialState: initReduce,
  reducers: {
    clearLearnData: (state: learnDataStoreInterface) => {
      state.lastNewLearningWord = initReduce.lastNewLearningWord;
      state.learnDataLoading = initReduce.learnDataLoading;
      state.learnDataAccess = initReduce.learnDataAccess;
      state.updateNewWord = initReduce.updateNewWord;
      state.complete = initReduce.complete;
      state.progress = initReduce.progress;
      state.paidAccess = initReduce.paidAccess;
      state.learningWordSoundTypes = initReduce.learningWordSoundTypes;
      state.learningWordTranscriptionTypes =
        initReduce.learningWordTranscriptionTypes;
    },
    setLastNewLearningWord: (
      state: learnDataStoreInterface,
      actions: { payload: newLearningWord; type: string },
    ) => {
      state.lastNewLearningWord = actions.payload;
    },
    setLearnDataLoadingTrue: (state: learnDataStoreInterface) => {
      state.learnDataLoading = true;
    },
    setLearnDataLoadingFalse: (state: learnDataStoreInterface) => {
      state.learnDataLoading = false;
    },
    setLearnDataAccessTrue: (state: learnDataStoreInterface) => {
      state.learnDataAccess = true;
    },
    setLearnDataAccessFalse: (state: learnDataStoreInterface) => {
      state.learnDataAccess = false;
    },
    setUpdateNewWord: (
      state: learnDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.updateNewWord = actions.payload;
    },
    setComplete: (
      state: learnDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.complete = actions.payload;
    },
    setProgress: (
      state: learnDataStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.progress = actions.payload;
    },
    setPaidAccess: (
      state: learnDataStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.paidAccess = actions.payload;
    },
    setLearningWordSoundTypes: (
      state: learnDataStoreInterface,
      actions: { payload: learningWordSoundType[]; type: string },
    ) => {
      state.learningWordSoundTypes = actions.payload;
    },
    setLearningWordTranscriptionTypes: (
      state: learnDataStoreInterface,
      actions: { payload: learningWordTranscriptionType[]; type: string },
    ) => {
      state.learningWordTranscriptionTypes = actions.payload;
    },
  },
});

export const {
  clearLearnData,
  setLastNewLearningWord,
  setLearnDataLoadingTrue,
  setLearnDataLoadingFalse,
  setLearnDataAccessTrue,
  setLearnDataAccessFalse,
  setUpdateNewWord,
  setComplete,
  setProgress,
  setPaidAccess,
  setLearningWordSoundTypes,
  setLearningWordTranscriptionTypes,
} = learnDataReduceSlice.actions;

export default learnDataReduceSlice.reducer;
