import { createSlice } from "@reduxjs/toolkit";

export interface groupPersonalCreateStoreInterface {
  groupPersonalCreateLoading: boolean;
  learningWordGroupPersonalId: number | null;
}

export const initState: groupPersonalCreateStoreInterface = {
  groupPersonalCreateLoading: false,
  learningWordGroupPersonalId: null,
};

export const groupPersonalCreateReduceSlice = createSlice({
  name: "GroupPersonalCreateReduce",
  initialState: initState,
  reducers: {
    clearGroupPersonalCreate: (state: groupPersonalCreateStoreInterface) => {
      state.groupPersonalCreateLoading = initState.groupPersonalCreateLoading;
      state.learningWordGroupPersonalId = initState.learningWordGroupPersonalId;
    },
    setGroupPersonalCreateLoadingTrue: (
      state: groupPersonalCreateStoreInterface,
    ) => {
      state.groupPersonalCreateLoading = true;
    },
    setGroupPersonalCreateLoadingFalse: (
      state: groupPersonalCreateStoreInterface,
    ) => {
      state.groupPersonalCreateLoading = false;
    },
    setLearningWordGroupPersonalId: (
      state: groupPersonalCreateStoreInterface,
      action: { payload: number; type: string },
    ) => {
      state.learningWordGroupPersonalId = action.payload;
    },
  },
});

export const {
  clearGroupPersonalCreate,
  setGroupPersonalCreateLoadingTrue,
  setGroupPersonalCreateLoadingFalse,
  setLearningWordGroupPersonalId,
} = groupPersonalCreateReduceSlice.actions;

export default groupPersonalCreateReduceSlice.reducer;
