import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailInput from "../../components/forms/inputs/EmailInput";
import { clearFormError } from "../../services/formErrors/formErrorsService";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { LoadingButton } from "@mui/lab";
import { resetPasswordHandle } from "../../services/resetPassword/resetPasswordService";

export default function ResetPasswordContainer() {
  const [email, setEmail] = useState("");
  const authLoading = useSelector(userDataSelectors.authLoading);
  const sendResetPasswordSuccess = useSelector(
    userDataSelectors.sendResetPasswordSuccess,
  );

  useEffect(() => {
    clearFormError();

    return () => {
      clearFormError();
    };
  }, []);

  function changeEmailHandle(email: string) {
    setEmail(email);
  }

  function handle() {
    resetPasswordHandle(email);
  }

  return (
    <>
      <Box sx={{ pb: "25px" }}>
        {
          "Введите Email-адрес, который вы указывали при регистрации. На него будте отправлена инструкция для восстановлению доступа."
        }
      </Box>
      <Box>
        <Grid container>
          <Grid
            xs={2}
            item
            sx={{
              placeSelf: "top",
              pt: "15px",
              pr: "10px",
              textAlign: "right",
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {"Email:"}
          </Grid>
          <Grid xs={8} item>
            <EmailInput
              email={email}
              loading={authLoading}
              valueChangeHandle={changeEmailHandle}
            />
          </Grid>
        </Grid>
      </Box>
      {sendResetPasswordSuccess === true ? (
        <Box sx={{ color: "success.main", textAlign: "center", p: "10px 0" }}>
          {"Сообщение отправлено"}
        </Box>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <LoadingButton
            loading={authLoading}
            variant="contained"
            onClick={handle}
          >
            {"Отправить"}
          </LoadingButton>
        </Box>
      )}
    </>
  );
}
