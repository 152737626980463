import axios, { responseInterface } from "../axios";
import store from "../../redux/store";
import { setToken } from "../../redux/app/appReduce";
import signInRequest from "./signUpRequest";
import { API_AUTH_CREATE } from "../../constants/apiRoutConstants";
import {
  authLoadingFalse,
  authLoadingTrue,
} from "../../redux/userData/userDataReduce";
import signUpResponse from "./signUpResponse";
import { successApiResponseHandle } from "../../services/formErrors/formErrorsService";

export const signUpApi = (data: signInRequest) => {
  startRequest();

  axios
    .post(API_AUTH_CREATE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: responseInterface) => {
      unsuccessful();
    });
};

const startRequest = () => {
  store.dispatch(authLoadingTrue());
};

export const successful = (response: responseInterface<signUpResponse>) => {
  const stopLoadingHandler = () => {
    store.dispatch(authLoadingFalse());
  };
  const handler = () => {
    store.dispatch(authLoadingTrue());
    store.dispatch(setToken(response.data.token));
  };

  successApiResponseHandle(response, stopLoadingHandler, handler);
};

export const unsuccessful = () => {
  store.dispatch(authLoadingFalse());
};
