import axios from "./axios";
import store from "../redux/store";
import { clearToken } from "../redux/app/appReduce";
import { toHome } from "../helpers/redirectHelper";
import { API_LOGOUT } from "../constants/apiRoutConstants";

export default function logoutApi() {
  axios
    .get(API_LOGOUT)
    .then(() => {
      successful();
    })
    .catch(() => {
      unsuccessful();
    });
}

const successful = () => {
  store.dispatch(clearToken());
  toHome();
};

const unsuccessful = () => {
  store.dispatch(clearToken());
};
