import { Box, Container, CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { TITLE_PAGE_FILL_USER_DATA } from "../../constants/titlesConstants";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import EnterAccessLayout from "../../components/layouts/fillData/EnterAccessLayout";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import LogoutHeadLine from "../../components/headLine/LogoutHeadLine";
import { toLearn } from "../../helpers/redirectHelper";

export default function FillUserDataPage() {
  const userDataEmail = useSelector(userDataSelectors.userData).email;

  useEffect(() => {
    if (userDataEmail !== "") {
      toLearn();
    }
  }, [userDataEmail]);

  return (
    <>
      <LogoutHeadLine />
      <CssBaseline />
      <Container maxWidth="md" sx={{ mt: "50px" }}>
        <TitleLineComponent title={TITLE_PAGE_FILL_USER_DATA} />
      </Container>
      <Container maxWidth="xs">
        <Box sx={{ fontSize: "16px" }}>
          {"Введите недостающие данные профиля"}
        </Box>
        <EnterAccessLayout />
      </Container>
    </>
  );
}
