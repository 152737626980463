import React from "react";
import logo from "../../assets/img/logo.webp";
import { Button, Container, Typography } from "@mui/material";
import logoutAction from "../../api/logoutApi";

export default function LogoutHeadLine() {
  const logout = () => {
    logoutAction();
  };

  return (
    <>
      <Container
        sx={{
          paddingTop: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <img src={logo} style={{ width: "40px", cursor: "pointer" }} />
        <Typography sx={{ cursor: "pointer", fontSize: "24px", pl: "25px" }}>
          {"Easye"}
        </Typography>
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={logout}>{"Выход"}</Button>
        </Container>
      </Container>
    </>
  );
}
