import { createSlice } from "@reduxjs/toolkit";

export interface userUnsubscribeStoreInterface {
  userUnsubscribeLoading: boolean;
  userUnsubscribeComplete: boolean | null;
}

export const initState: userUnsubscribeStoreInterface = {
  userUnsubscribeLoading: false,
  userUnsubscribeComplete: null,
};

export const userUnsubscribeReduceSlice = createSlice({
  name: "userUnsubscribeReduce",
  initialState: initState,
  reducers: {
    clearUserUnsubscribe: (state: userUnsubscribeStoreInterface) => {
      state.userUnsubscribeLoading = initState.userUnsubscribeLoading;
      state.userUnsubscribeComplete = initState.userUnsubscribeComplete;
    },
    setUserUnsubscribeLoadingTrue: (state: userUnsubscribeStoreInterface) => {
      state.userUnsubscribeLoading = true;
    },
    setUserUnsubscribeLoadingFalse: (state: userUnsubscribeStoreInterface) => {
      state.userUnsubscribeLoading = false;
    },
    setUserUnsubscribeComplete: (
      state: userUnsubscribeStoreInterface,
      action: { payload: boolean | null; type: string },
    ) => {
      state.userUnsubscribeComplete = action.payload;
    },
  },
});

export const {
  clearUserUnsubscribe,
  setUserUnsubscribeLoadingTrue,
  setUserUnsubscribeLoadingFalse,
  setUserUnsubscribeComplete,
} = userUnsubscribeReduceSlice.actions;

export default userUnsubscribeReduceSlice.reducer;
