import { updatePasswordRequest } from "./updatePasswordRequest";

export default function updatePasswordData(
  currentPassword: string,
  newPassword: string,
): updatePasswordRequest {
  return {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };
}
