import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupAvailable from "../../models/learningWordGroupAvailable";

export interface groupAvailableListStoreInterface {
  groupAvailableListLoading: boolean;
  learningWordGroups: learningWordGroupAvailable[];
}

export const initState: groupAvailableListStoreInterface = {
  groupAvailableListLoading: false,
  learningWordGroups: [],
};

export const groupAvailableListReduceSlice = createSlice({
  name: "groupAvailableListReduce",
  initialState: initState,
  reducers: {
    clearGroupAvailableList: (state: groupAvailableListStoreInterface) => {
      state.groupAvailableListLoading = initState.groupAvailableListLoading;
      state.learningWordGroups = initState.learningWordGroups;
    },
    setGroupAvailableListLoadingTrue: (
      state: groupAvailableListStoreInterface,
    ) => {
      state.groupAvailableListLoading = true;
    },
    setGroupAvailableListLoadingFalse: (
      state: groupAvailableListStoreInterface,
    ) => {
      state.groupAvailableListLoading = false;
    },
    setLearningWordGroups: (
      state: groupAvailableListStoreInterface,
      action: { payload: learningWordGroupAvailable[]; type: string },
    ) => {
      state.learningWordGroups = action.payload;
    },
  },
});

export const {
  clearGroupAvailableList,
  setGroupAvailableListLoadingTrue,
  setGroupAvailableListLoadingFalse,
  setLearningWordGroups,
} = groupAvailableListReduceSlice.actions;

export default groupAvailableListReduceSlice.reducer;
