import { storeInterface } from "../store";

const groupPersonalUpdateLoading = (state: storeInterface) =>
  state.groupPersonalUpdate.groupPersonalUpdateLoading;
const groupPersonalUpdateComplete = (state: storeInterface) =>
  state.groupPersonalUpdate.groupPersonalUpdateComplete;

export default {
  groupPersonalUpdateLoading,
  groupPersonalUpdateComplete,
};
