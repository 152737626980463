import chooseValueWord from "../../models/chooseValueWord";
import store from "../store";
import {
  clearLearnNext,
  setLearnNext,
  setLearnNextAccessFalse,
  setLearnNextAccessTrue,
  setLearnNextLoadingFalse,
  setLearnNextLoadingTrue,
} from "./learnNextReduce";

export function clearLearnNextDispatch() {
  store.dispatch(clearLearnNext());
}

export function setLearnNextDispatch(learnNext: chooseValueWord) {
  store.dispatch(setLearnNext(learnNext));
}

export function setLearnNextAccessTrueDispatch() {
  store.dispatch(setLearnNextAccessTrue());
}

export function setLearnNextAccessFalseDispatch() {
  store.dispatch(setLearnNextAccessFalse());
}

export function setLearnNextLoadingTrueDispatch() {
  store.dispatch(setLearnNextLoadingTrue());
}

export function setLearnNextLoadingFalseDispatch() {
  store.dispatch(setLearnNextLoadingFalse());
}
