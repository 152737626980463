import React from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUP_ADD } from "../../constants/titlesConstants";
import { ROUTE_GROUPS_PERSONAL } from "../../constants/routesConstants";
import BackComponent from "../../components/back/BackComponent";
import GroupPersonalAdd from "../groupPersonal/GroupPersonalAdd";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";

function GroupPersonalAddPage() {
  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUP_ADD} />
        <BackComponent routUrl={ROUTE_GROUPS_PERSONAL} />
      </Container>
      <Container maxWidth="sm">
        <GroupPersonalAdd />
      </Container>
      <Footer />
    </>
  );
}

export default GroupPersonalAddPage;
