import { storeInterface } from "../store";

const actionWordGroupAccess = (state: storeInterface) =>
  state.actionWordGroup.actionWordGroupAccess;
const isEdit = (state: storeInterface) => state.actionWordGroup.isEdit;
const wordList = (state: storeInterface) => state.actionWordGroup.wordList;
const isEditWordList = (state: storeInterface) =>
  state.actionWordGroup.isEditWordList;
const title = (state: storeInterface) => state.actionWordGroup.title;
const description = (state: storeInterface) =>
  state.actionWordGroup.description;
const learningWordGroupPartition = (state: storeInterface) =>
  state.actionWordGroup.learningWordGroupPartition;
const learningWordGroupEnvironment = (state: storeInterface) =>
  state.actionWordGroup.learningWordGroupEnvironment;
const actionWordGroupLoading = (state: storeInterface) =>
  state.actionWordGroup.actionWordGroupLoading;
const actionWordGroupError = (state: storeInterface) =>
  state.actionWordGroup.actionWordGroupError;
const actionWordGroupSuccess = (state: storeInterface) =>
  state.actionWordGroup.actionWordGroupSuccess;

export default {
  actionWordGroupAccess,
  isEdit,
  wordList,
  isEditWordList,
  title,
  description,
  learningWordGroupPartition,
  learningWordGroupEnvironment,
  actionWordGroupLoading,
  actionWordGroupError,
  actionWordGroupSuccess,
};
