import React, { useEffect } from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_SETTINGS } from "../../constants/titlesConstants";
import SettingsContainer from "../settings/SettingsContainer";
import { useSelector } from "react-redux";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import AppLoader from "../../components/loader/AppLoader";
import { clearFormError } from "../../services/formErrors/formErrorsService";
import { updatePasswordSuccessFalse } from "../../redux/userData/userDataReduce";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";

function SettingsPage() {
  const userDataLoading = useSelector(userDataSelectors.userDataLoading);

  useEffect(() => {
    clearFormError();

    return () => {
      clearFormError();
      updatePasswordSuccessFalse();
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_SETTINGS} />
      </Container>
      <Container maxWidth="sm">
        {userDataLoading === true ? <AppLoader /> : <SettingsContainer />}
      </Container>
      <Footer />
    </>
  );
}

export default SettingsPage;
