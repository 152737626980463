import {
  alertAppError,
  alertWordCanNotCreated,
} from "../../constants/alertConstants";
import { API_WORD_CREATE } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setLearningWordDispatch,
  setWordCreateLoadingFalseDispatch,
  setWordCreateLoadingTrueDispatch,
} from "../../redux/wordCreate/wordCreateDispatcher";
import axios, { responseInterface } from "../axios";
import { wordCreateRequest } from "./wordCreateRequest";
import { wordCreateResponse } from "./wordCreateResponse";

export default function wordCreateApi(data: wordCreateRequest) {
  startRequest();

  axios
    .post(API_WORD_CREATE, data)
    .then((res: any) => {
      successful(res);
    })
    .catch((res: any) => {
      unsuccessful(res);
    });
}

const startRequest = () => {
  setLearningWordDispatch(null);
  setWordCreateLoadingTrueDispatch();
};

const successful = (response: responseInterface<wordCreateResponse>) => {
  setLearningWordDispatch(response.data.learningWord);

  if (response.data.learningWord === null) {
    alertWarningDispatch(alertWordCanNotCreated);
  }

  setWordCreateLoadingFalseDispatch();
};

const unsuccessful = (response: responseInterface<any>) => {
  alertWarningDispatch(alertAppError);
};
