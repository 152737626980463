import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_PAY } from "../../constants/titlesConstants";
import userSubscribeDataApi from "../../api/userSubscribeData/userSubscribeDataApi";
import { clearUserSubscribeDataDispatch } from "../../redux/userSubscribeData/userSubscribeDataDispatcher";
import { useSelector } from "react-redux";
import userSubscribeDataSelectors from "../../redux/userSubscribeData/userSubscribeDataSelectors";
import Loader from "../../components/loader/Loader";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";
import userDataSelectors from "../../redux/userData/userDataSelectors";
import { Link } from "react-router-dom";
import { ROUTE_SETTINGS } from "../../constants/routesConstants";
import appSelectors from "../../redux/app/appSelectors";
import userGeneratePayApi from "../../api/userGeneratePay/userGeneratePayApi";
import { LoadingButton } from "@mui/lab";
import userGeneratePaySelectors from "../../redux/userGeneratePay/userGeneratePaySelectors";
import { redirect } from "../../helpers/redirectHelper";
import payment from "../../models/payment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { elementsForPage } from "../../helpers/paginateHelper";

export default function PayPage() {
  const userGeneratePayLoading = useSelector(
    userGeneratePaySelectors.userGeneratePayLoading,
  );
  const payUrl = useSelector(userGeneratePaySelectors.payUrl);
  const userSubscribeDataLoading = useSelector(
    userSubscribeDataSelectors.userSubscribeDataLoading,
  );
  const subscribeAccess = useSelector(
    userSubscribeDataSelectors.subscribeAccess,
  );
  const freeAccess = useSelector(userSubscribeDataSelectors.freeAccess);
  const endPrimeTime = useSelector(userSubscribeDataSelectors.endPrimeTime);
  const freeWordCount = useSelector(userSubscribeDataSelectors.freeWordCount);
  const cost = useSelector(userSubscribeDataSelectors.cost);
  const subscribeCost = useSelector(appSelectors.subscribeCost);
  // const subscribeCost = 481;
  const extendTo = useSelector(userSubscribeDataSelectors.extendTo);
  const payments = useSelector(userSubscribeDataSelectors.payments);

  const userData = useSelector(userDataSelectors.userData);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    userSubscribeDataApi();

    return () => {
      clearUserSubscribeDataDispatch();
    };
  }, []);

  useEffect(() => {
    if (payUrl !== null && payUrl.length > 0) {
      redirect(payUrl);
    }
  }, [payUrl]);

  function payHandle() {
    userGeneratePayApi();
  }

  function isLoading() {
    return userSubscribeDataLoading === true;
  }

  function dateFormat(dateString: string) {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19).replace("T", " ");
  }

  function dateEndPrimeTime(dateString: string) {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 10);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function LoadingLayout() {
    return (
      <>
        <Loader />
      </>
    );
  }

  function FreeAccessLayout() {
    return (
      <>
        <Box
          sx={{
            p: 3,
            textTransform: "uppercase",
            backgroundColor: "rgba(86, 176, 66, 1)",
            textAlign: "center",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          {"Бесплатный период"}
        </Box>
        <Box sx={{ fontSize: 12, color: "text.secondary" }}>
          {"* Действует до " + freeWordCount + " изученных слов"}
        </Box>
      </>
    );
  }

  function SubscribeAccessLayout() {
    return (
      <>
        <Box
          sx={{
            p: 3,
            textTransform: "uppercase",
            backgroundColor: "rgba(41, 135, 20, 1)",
            textAlign: "center",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          <Box>{"Подписка оформлена"}</Box>
          <Box>{"до " + endPrimeTime}</Box>
        </Box>
      </>
    );
  }

  function NotSubscribeAccessLayout() {
    return (
      <>
        <Box
          sx={{
            p: 3,
            textTransform: "uppercase",
            backgroundColor: "rgba(191, 138, 15)",
            textAlign: "center",
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          {"Необходимо продлить подписку"}
        </Box>
      </>
    );
  }

  function MainLayout() {
    return (
      <>
        <Divider textAlign="left" variant="fullWidth" sx={{ mt: 1, mb: 3 }}>
          {"Статус подписки"}
        </Divider>
        <Container maxWidth="sm">
          {freeAccess === true && subscribeAccess === false ? (
            <FreeAccessLayout />
          ) : (
            ""
          )}
          {subscribeAccess === true ? <SubscribeAccessLayout /> : ""}
          {freeAccess === false && subscribeAccess === false ? (
            <NotSubscribeAccessLayout />
          ) : (
            ""
          )}
        </Container>
        <Box sx={{ mt: 3 }}>
          <Divider textAlign="left" variant="fullWidth" sx={{ mt: 1, mb: 3 }}>
            {"Продление подписки"}
          </Divider>
          <Container maxWidth="sm">
            <Grid
              container
              sx={{
                borderBottom: "1px rgba(0, 0, 0, 0.15) solid",
                bgcolor: "rgba(0, 0, 0, 0.05)",
                fontWeight: "bold",
              }}
            >
              <Grid item xs={9} sx={{ p: 1 }}>
                {"Описание"}
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "center", p: 1 }}>
                {"Сумма"}
              </Grid>
            </Grid>
            <Grid container>
              <Grid
                item
                xs={9}
                sx={{
                  p: 1,
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {"Продление подписки до " + extendTo}
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  textAlign: "center",
                  p: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {subscribeCost !== cost ? (
                  <Box>
                    <Box sx={{ fontSize: 14, pr: 1 }}>
                      <s>{subscribeCost + " руб."}</s>
                    </Box>
                    <Box sx={{ fontSize: 20, fontWeight: "bold" }}>
                      {cost + " руб."}
                    </Box>
                  </Box>
                ) : (
                  <>{cost + " руб."}</>
                )}
              </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mb: 1 }} />
            <Box sx={{ textAlign: "right", mt: 4 }}>
              {userData.confirmEmail === false ? (
                <Box sx={{ color: "text.secondary" }}>
                  {"Подтвердите E-mail адрес в "}
                  <Link to={ROUTE_SETTINGS}>{"настройках"}</Link>
                </Box>
              ) : (
                <LoadingButton
                  loading={userGeneratePayLoading}
                  variant="contained"
                  size="large"
                  onClick={payHandle}
                >
                  {subscribeCost !== cost ? (
                    <>
                      <Box>
                        {"Продлить за "}
                        <Box component="span" sx={{ fontSize: 14, pr: 1 }}>
                          <s>{subscribeCost}</s>
                        </Box>
                        <Box
                          component="span"
                          sx={{ fontSize: 18, fontWeight: "bold" }}
                        >
                          {cost + " руб."}
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box>{"Продлить за " + cost + " руб."}</Box>
                  )}
                </LoadingButton>
              )}
            </Box>
          </Container>
          <Divider textAlign="left" variant="fullWidth" sx={{ mt: 5, mb: 3 }}>
            {"История платежей"}
          </Divider>
          <Container maxWidth="sm">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 350 }}
                size="small"
                aria-label="История платежей"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {"Дата окончания подписки"}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {"Сумма, РУБ"}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {"Статус"}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="center">
                      {"Дата платежа"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {elementsForPage({
                    array: payments,
                    pageSize: rowsPerPage,
                    page: page + 1,
                  }).map((row: payment, index: number) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {dateEndPrimeTime(row.end_prime_time)}
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">
                        {row.complete ? (
                          <Tooltip title={"Платеж прошел успешно"}>
                            <CheckCircleIcon sx={{ color: "success.main" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Платеж в обработке"}>
                            <HourglassEmptyIcon />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {dateFormat(row.created_at)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={payments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              getItemAriaLabel={(type) => {
                return `Перейти к ${type} странице`;
              }}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} из ${
                  count !== -1 ? count : `больше чем ${to}`
                }`;
              }}
              labelRowsPerPage={"Результатов на странице:"}
            />
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_PAY} />
      </Container>
      <Container maxWidth="md">
        {isLoading() ? <LoadingLayout /> : <MainLayout />}
      </Container>
      <Footer />
    </>
  );
}
