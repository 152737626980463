import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE_LEARN, ROUTE_SIGN_IN } from "../../constants/routesConstants";
import { useSelector } from "react-redux";
import { storeInterface } from "../../redux/store";
import picOne from "../../assets/img/picOne.webp";

const SectionOneTitle = () => {
  const mediaSmall = useMediaQuery("(max-width:780px)");
  const navigate = useNavigate();
  const token = useSelector((state: storeInterface) => state.app.token);

  const toLearn = () => {
    navigate(ROUTE_LEARN);
  };

  const toSignIn = () => {
    navigate(ROUTE_SIGN_IN);
  };

  const ButtonToLearn = () => {
    return (
      <Button variant="contained" onClick={toLearn}>
        {"Продолжить"}
      </Button>
    );
  };

  const ButtonToSignIn = () => {
    return (
      <Button variant="contained" onClick={toSignIn}>
        {"Начать бесплатно"}
      </Button>
    );
  };

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 15,
          pb: 20,
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            backgroundImage: "url(" + picOne + ")",
            backgroundSize: mediaSmall ? "190px auto" : "320px auto", // или 'contain', 'auto'
            backgroundPosition: mediaSmall ? "bottom right" : "center right", // или 'top', 'bottom', 'left', 'right'
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              fontSize: mediaSmall ? 30 : 42,
              lineHeight: 1.2,
              fontWeight: "bold",
            }}
          >
            {"Повышай словарный"}
            <br />
            {"запас английского"}
            <br />
            {"языка"}
          </Box>
          <Box sx={{ fontSize: 18, color: "text.secondary", pt: 2 }}>
            {"Просто и доступно"}
          </Box>
          <Box sx={{ pt: 6 }}>
            {token ? <ButtonToLearn /> : <ButtonToSignIn />}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SectionOneTitle;
