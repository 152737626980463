import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupPersonalData from "../../models/learningWordGroupPersonalData";

export interface groupPersonalDataStoreInterface {
  groupPersonalDataLoading: boolean;
  learningWordGroupPersonalData: learningWordGroupPersonalData | null;
}

export const initState: groupPersonalDataStoreInterface = {
  groupPersonalDataLoading: true,
  learningWordGroupPersonalData: null,
};

export const groupPersonalDataReduceSlice = createSlice({
  name: "GroupPersonalDataReduce",
  initialState: initState,
  reducers: {
    clearGroupPersonalData: (state: groupPersonalDataStoreInterface) => {
      state.groupPersonalDataLoading = initState.groupPersonalDataLoading;
      state.learningWordGroupPersonalData =
        initState.learningWordGroupPersonalData;
    },
    setGroupPersonalDataLoadingTrue: (
      state: groupPersonalDataStoreInterface,
    ) => {
      state.groupPersonalDataLoading = true;
    },
    setGroupPersonalDataLoadingFalse: (
      state: groupPersonalDataStoreInterface,
    ) => {
      state.groupPersonalDataLoading = false;
    },
    setLearningWordGroupPersonalData: (
      state: groupPersonalDataStoreInterface,
      action: { payload: learningWordGroupPersonalData; type: string },
    ) => {
      state.learningWordGroupPersonalData = action.payload;
    },
  },
});

export const {
  clearGroupPersonalData,
  setGroupPersonalDataLoadingTrue,
  setGroupPersonalDataLoadingFalse,
  setLearningWordGroupPersonalData,
} = groupPersonalDataReduceSlice.actions;

export default groupPersonalDataReduceSlice.reducer;
