import { storeInterface } from "../store";

const groupPersonalDeleteLoading = (state: storeInterface) =>
  state.groupPersonalDelete.groupPersonalDeleteLoading;
const groupPersonalDeleteComplete = (state: storeInterface) =>
  state.groupPersonalDelete.groupPersonalDeleteComplete;

export default {
  groupPersonalDeleteLoading,
  groupPersonalDeleteComplete,
};
