import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_PERSONAL_DATA } from "../../constants/apiRoutConstants";
import learningWordGroupPersonalData from "../../models/learningWordGroupPersonalData";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setGroupPersonalDataLoadingFalseDispatch,
  setGroupPersonalDataLoadingTrueDispatch,
  setLearningWordGroupPersonalDataDispatch,
} from "../../redux/groupPersonalData/groupPersonalDataDispatcher";
import axios, { responseInterface } from "../axios";
import groupPersonalDeleteRequest from "./groupPersonalDataRequest";

export default function groupPersonalDataApi(data: groupPersonalDeleteRequest) {
  startRequest();

  axios
    .get(API_GROUP_PERSONAL_DATA, {
      params: data,
    })
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setGroupPersonalDataLoadingTrueDispatch();
};

const successful = (
  response: responseInterface<learningWordGroupPersonalData>,
) => {
  setLearningWordGroupPersonalDataDispatch(response.data);
  setGroupPersonalDataLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
