import store from "../store";
import {
  clearUserConfirmEmail,
  setUserConfirmEmailCompleteFalse,
  setUserConfirmEmailCompleteNull,
  setUserConfirmEmailCompleteTrue,
  setUserConfirmEmailLoadingFalse,
  setUserConfirmEmailLoadingTrue,
} from "./userConfirmEmailReduce";

export function clearUserConfirmEmailDispatch() {
  store.dispatch(clearUserConfirmEmail());
}
export function setUserConfirmEmailLoadingTrueDispatch() {
  store.dispatch(setUserConfirmEmailLoadingTrue());
}
export function setUserConfirmEmailLoadingFalseDispatch() {
  store.dispatch(setUserConfirmEmailLoadingFalse());
}
export function setUserConfirmEmailCompleteTrueDispatch() {
  store.dispatch(setUserConfirmEmailCompleteTrue());
}
export function setUserConfirmEmailCompleteFalseDispatch() {
  store.dispatch(setUserConfirmEmailCompleteFalse());
}
export function setUserConfirmEmailCompleteNullDispatch() {
  store.dispatch(setUserConfirmEmailCompleteNull());
}
