import { createSlice } from "@reduxjs/toolkit";
import learningWord from "../../models/learningWord";

export interface wordCreateStoreInterface {
  learningWord: learningWord | null;
  wordCreateLoading: boolean;
}

export const initState: wordCreateStoreInterface = {
  learningWord: null,
  wordCreateLoading: false,
};

export const wordCreateReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initState,
  reducers: {
    clearWordCreate: (state: wordCreateStoreInterface) => {
      state.learningWord = initState.learningWord;
      state.wordCreateLoading = initState.wordCreateLoading;
    },
    setLearningWord: (
      state: wordCreateStoreInterface,
      action: {
        type: string;
        payload: learningWord | null;
      },
    ) => {
      state.learningWord = action.payload;
    },
    setWordCreateLoadingTrue: (state: wordCreateStoreInterface) => {
      state.wordCreateLoading = true;
    },
    setWordCreateLoadingFalse: (state: wordCreateStoreInterface) => {
      state.wordCreateLoading = false;
    },
  },
});

export const {
  clearWordCreate,
  setLearningWord,
  setWordCreateLoadingTrue,
  setWordCreateLoadingFalse,
} = wordCreateReduceSlice.actions;

export default wordCreateReduceSlice.reducer;
