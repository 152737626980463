import { createSlice } from "@reduxjs/toolkit";

export interface userGeneratePayStoreInterface {
  userGeneratePayLoading: boolean;
  payUrl: string | null;
}

export const initState: userGeneratePayStoreInterface = {
  userGeneratePayLoading: false,
  payUrl: null,
};

const userGeneratePayReduceSlice = createSlice({
  name: "userGeneratePayReduce",
  initialState: initState,
  reducers: {
    clearUserGeneratePay: (state: userGeneratePayStoreInterface) => {
      state.userGeneratePayLoading = initState.userGeneratePayLoading;
      state.payUrl = initState.payUrl;
    },
    setUserGeneratePayLoadingTrue: (state: userGeneratePayStoreInterface) => {
      state.userGeneratePayLoading = true;
    },
    setUserGeneratePayLoadingFalse: (state: userGeneratePayStoreInterface) => {
      state.userGeneratePayLoading = false;
    },
    setPayUrl: (
      state: userGeneratePayStoreInterface,
      action: {
        type: string;
        payload: string;
      },
    ) => {
      state.payUrl = action.payload;
    },
  },
});

export const {
  clearUserGeneratePay,
  setUserGeneratePayLoadingTrue,
  setUserGeneratePayLoadingFalse,
  setPayUrl,
} = userGeneratePayReduceSlice.actions;

export default userGeneratePayReduceSlice.reducer;
