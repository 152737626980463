import { signInApi } from "../../api/signIn/signInApi";
import signIdData from "../../api/signIn/signInData";
import {
  isEmailValueEmpty,
  isPasswordValueEmpty,
} from "../formCheckService/formCheckService";
import { clearFormError } from "../formErrors/formErrorsService";

function isEmpty(email: string, password: string) {
  const emailEmpty = isEmailValueEmpty(email);
  const passwordEmpty = isPasswordValueEmpty(password);

  return emailEmpty || passwordEmpty;
}

export function signInHandle(email: string, password: string) {
  clearFormError();

  if (isEmpty(email, password)) {
    return;
  }

  const data = signIdData(email, password);

  signInApi(data);
}
