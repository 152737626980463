import store from "../store";
import {
  clearUserUnsubscribe,
  setUserUnsubscribeLoadingFalse,
  setUserUnsubscribeLoadingTrue,
  setUserUnsubscribeComplete,
} from "./userUnsubscribeReduce";

export function clearUserUnsubscribeDispatch() {
  store.dispatch(clearUserUnsubscribe());
}

export function setUserUnsubscribeLoadingTrueDispatch() {
  store.dispatch(setUserUnsubscribeLoadingTrue());
}

export function setUserUnsubscribeLoadingFalseDispatch() {
  store.dispatch(setUserUnsubscribeLoadingFalse());
}

export function setUserUnsubscribeCompleteDispatch(value: boolean | null) {
  store.dispatch(setUserUnsubscribeComplete(value));
}
