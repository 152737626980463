import { storeInterface } from "../store";

const groupPersonalDataLoading = (state: storeInterface) =>
  state.groupPersonalData.groupPersonalDataLoading;
const learningWordGroupPersonalData = (state: storeInterface) =>
  state.groupPersonalData.learningWordGroupPersonalData;

export default {
  groupPersonalDataLoading,
  learningWordGroupPersonalData,
};
