import wordListItem from "../../models/wordListItem";
import store from "../store";
import { clearActionWordGroup, setWordList } from "./actionWordGroupReduce";

export function clearActionWordGroupDispatch() {
  store.dispatch(clearActionWordGroup());
}

export function setWordListDispatch(value: wordListItem[]) {
  store.dispatch(setWordList(value));
}
