import React from "react";
import { Alert, Box, Collapse, Container, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import store, { storeInterface } from "../../redux/store";
import { alertClose } from "../../redux/alert/alertReduce";

const AlertComponent = function () {
  const visible = useSelector((state: storeInterface) => state.alert.visible);
  const text = useSelector((state: storeInterface) => state.alert.text);
  const severity = useSelector((state: storeInterface) => state.alert.severity);

  return (
    <Box
      sx={{
        display: "box",
        width: "100%",
        position: "fixed",
        bottom: "10px",
        left: 0,
        zIndex: 1,
      }}
    >
      <Collapse in={visible}>
        <Container maxWidth="lg">
          <Alert
            variant="filled"
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  store.dispatch(alertClose());
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {text}
          </Alert>
        </Container>
      </Collapse>
    </Box>
  );
};

export default AlertComponent;
