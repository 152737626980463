import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  TextField,
} from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUP_PERSONAL_EDIT } from "../../constants/titlesConstants";
import {
  ROUTE_GROUPS_PERSONAL,
  routeLearnGroupPersonalNavigate,
} from "../../constants/routesConstants";
import BackComponent from "../../components/back/BackComponent";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import GroupPersonalWordListComponent from "../../components/layouts/groupPersonalEdit/GroupPersonalWordListComponent";
import groupPersonalDataApi from "../../api/groupPersonalData/groupPersonalDataApi";
import groupPersonalDataData from "../../api/groupPersonalData/groupPersonalDataData";
import { useSelector } from "react-redux";
import groupPersonalDataSelectors from "../../redux/groupPersonalData/groupPersonalDataSelectors";
import AppLoader from "../../components/loader/AppLoader";
import { setWordList } from "../../redux/actionWordGroup/actionWordGroupReduce";
import wordListItem from "../../models/wordListItem";
import learningWord from "../../models/learningWord";
import store from "../../redux/store";
import groupPersonalUpdateData from "../../api/groupPersonalUpdate/groupPersonalUpdateData";
import groupPersonalUpdateApi from "../../api/groupPersonalUpdate/groupPersonalUpdateApi";
import groupPersonalUpdateSelectors from "../../redux/groupPersonalUpdate/groupPersonalUpdateSelectors";
import { clearGroupPersonalUpdateDispatch } from "../../redux/groupPersonalUpdate/groupPersonalUpdateDispatcher";
import { clearGroupPersonalDataDispatch } from "../../redux/groupPersonalData/groupPersonalDataDispatcher";
import { learningWordIdsFromWordList } from "../../services/learningWordIds/learningWordIdsService";
import actionWordGroupSelectors from "../../redux/actionWordGroup/actionWordGroupSelectors";
import { clearActionWordGroupDispatch } from "../../redux/actionWordGroup/actionWordGroupDispatcher";
import GroupActionFillWordListFromTextButton from "../../components/groupActionFillWirdListFromText/GroupActionFillWordListFromTextButton";
import {
  alertCloseDispatch,
  alertErrorDispatch,
} from "../../redux/alert/alertDispatcher";
import {
  alertEmptyTitlePersonalGroup,
  alertEmptyWordPersonalGroup,
} from "../../constants/alertConstants";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";
import wordFromTextSelectors from "../../redux/wordFromText/wordFromTextSelectors";

function GroupPersonalEditPage() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const learningWordGroupPersonalId = Number(
    useParams().learningWordGroupPersonalId,
  );
  const learningWordGroupPersonalData = useSelector(
    groupPersonalDataSelectors.learningWordGroupPersonalData,
  );
  const groupPersonalDataLoading = useSelector(
    groupPersonalDataSelectors.groupPersonalDataLoading,
  );
  const groupPersonalUpdateLoading = useSelector(
    groupPersonalUpdateSelectors.groupPersonalUpdateLoading,
  );
  const groupPersonalUpdateComplete = useSelector(
    groupPersonalUpdateSelectors.groupPersonalUpdateComplete,
  );
  const wordList = useSelector(actionWordGroupSelectors.wordList);
  const wordFromTextLoading = useSelector(
    wordFromTextSelectors.wordFromTextLoading,
  );

  useEffect(() => {
    uploadData();

    return () => {
      clearGroupPersonalUpdateDispatch();
      clearGroupPersonalDataDispatch();
      clearActionWordGroupDispatch();
    };
  }, []);

  useEffect(() => {
    if (learningWordGroupPersonalData !== null) {
      setTitle(learningWordGroupPersonalData.learningWordGroupPersonal.title);

      const wordListItems: wordListItem[] =
        learningWordGroupPersonalData.learningWords.map(
          (item: learningWord) => {
            return {
              learningWord: item,
              remove: false,
            };
          },
        );

      store.dispatch(setWordList(wordListItems));
    }
  }, [learningWordGroupPersonalData]);

  useEffect(() => {
    if (groupPersonalUpdateComplete === true) {
      uploadData();
    }
  }, [groupPersonalUpdateComplete]);

  function uploadData() {
    const data = groupPersonalDataData(learningWordGroupPersonalId);
    groupPersonalDataApi(data);
  }

  function toLearnPersonalGroup() {
    navigate(
      routeLearnGroupPersonalNavigate(String(learningWordGroupPersonalId)),
    );
  }

  function updateHandle() {
    alertCloseDispatch();
    const learningWordIds = learningWordIdsFromWordList(wordList);

    if (title.length === 0) {
      alertErrorDispatch(alertEmptyTitlePersonalGroup);
      return;
    }

    // if (learningWordIds.length === 0) {
    //   alertErrorDispatch(alertEmptyWordPersonalGroup);
    //   return;
    // }

    const data = groupPersonalUpdateData(
      learningWordGroupPersonalId,
      title,
      learningWordIds,
    );
    groupPersonalUpdateApi(data);
  }

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUP_PERSONAL_EDIT} />
        <BackComponent routUrl={ROUTE_GROUPS_PERSONAL} />
      </Container>
      <Container maxWidth="sm">
        {groupPersonalDataLoading === true ? (
          <>
            <AppLoader />
          </>
        ) : (
          <>
            <Box sx={{ p: "5px 0", display: "flex", justifyContent: "right" }}>
              <Button
                onClick={toLearnPersonalGroup}
                variant="contained"
                sx={{ ml: 1 }}
              >
                {"К изучению"}
              </Button>
            </Box>
            <Box>
              <Divider textAlign="left">{"Название словаря"}</Divider>
              <Box sx={{ mt: 3, mb: 3 }}>
                <TextField
                  value={title}
                  onChange={(e: any) => setTitle(e.target.value)}
                  variant="outlined"
                  sx={{ width: "100%" }}
                  size="small"
                  disabled={groupPersonalUpdateLoading}
                />
              </Box>
            </Box>
            <Box>
              <Divider textAlign="left">{"Состав"}</Divider>
            </Box>
            <Box sx={{ pt: 1 }}>
              <GroupActionFillWordListFromTextButton />
            </Box>
            <Box>
              <GroupPersonalWordListComponent
                disabled={groupPersonalUpdateLoading || wordFromTextLoading}
              />
            </Box>
            <Box
              sx={{ p: "25px 0 0", display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                loading={groupPersonalUpdateLoading || wordFromTextLoading}
                onClick={updateHandle}
                variant="contained"
              >
                {"Сохранить"}
              </LoadingButton>
            </Box>
          </>
        )}
      </Container>
      <Footer />
    </>
  );
}

export default GroupPersonalEditPage;
