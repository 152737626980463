import {
  ROUTE_HOME,
  ROUTE_LEARN,
  ROUTE_SIGN_IN,
} from "../constants/routesConstants";

export const toHome = () => {
  window.location.href = ROUTE_HOME;
};

export const toLogin = () => {
  window.location.href = ROUTE_SIGN_IN;
};

export const toLearn = () => {
  window.location.href = ROUTE_LEARN;
};

export const reload = () => {
  location.reload();
};

export const redirect = (url: string) => {
  window.location.href = url;
};
