import store from "../store";
import {
  clearGroupPersonalUpdate,
  setGroupPersonalUpdateLoadingFalse,
  setGroupPersonalUpdateLoadingTrue,
  setGroupPersonalUpdateComplete,
} from "./groupPersonalUpdateReduce";

export function clearGroupPersonalUpdateDispatch() {
  store.dispatch(clearGroupPersonalUpdate());
}

export function setGroupPersonalUpdateLoadingTrueDispatch() {
  store.dispatch(setGroupPersonalUpdateLoadingTrue());
}

export function setGroupPersonalUpdateLoadingFalseDispatch() {
  store.dispatch(setGroupPersonalUpdateLoadingFalse());
}

export function setGroupPersonalUpdateCompleteDispatch(value: boolean | null) {
  store.dispatch(setGroupPersonalUpdateComplete(value));
}
