import { alertAppError } from "../../constants/alertConstants";
import { API_GROUP_PERSONAL_LIST } from "../../constants/apiRoutConstants";
import { alertWarningDispatch } from "../../redux/alert/alertDispatcher";
import {
  setGroupPersonalListLoadingFalseDispatch,
  setGroupPersonalListLoadingTrueDispatch,
  setLearningWordGroupPersonalsDispatch,
} from "../../redux/groupPersonalList/groupPersonalListDispatcher";
import store from "../../redux/store";
import axios, { responseInterface } from "../axios";
import groupPersonalListResponse from "./groupPersonalListResponse";

export default function groupPersonalListApi() {
  startRequest();

  axios
    .get(API_GROUP_PERSONAL_LIST)
    .then((res: any) => {
      successful(res);
    })
    .catch(() => {
      unsuccessful();
    });
}

const startRequest = () => {
  setGroupPersonalListLoadingTrueDispatch();
};

const successful = (response: responseInterface<groupPersonalListResponse>) => {
  setLearningWordGroupPersonalsDispatch(
    response.data.learningWordGroupPersonals,
  );

  setGroupPersonalListLoadingFalseDispatch();
};

const unsuccessful = () => {
  alertWarningDispatch(alertAppError);
};
