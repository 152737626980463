import React, { useEffect } from "react";
import { Container, CssBaseline } from "@mui/material";
import Footer from "../../components/footer/Footer";
import AppHeadLine from "../../components/headLine/AppHeadLine";
import TitleLineComponent from "../../components/titleLine/TitleLineComponent";
import { TITLE_PAGE_GROUPS_PERSONAL } from "../../constants/titlesConstants";
import PersonalGroupsContainer from "../groupsPage/PersonalGroupsContainer";
import groupPersonalListApi from "../../api/groupPersonalList/groupPersonalListApi";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import store from "../../redux/store";
import { clearGroupList } from "../../redux/groupList/groupListReduce";
import groupPersonalListSelectors from "../../redux/groupPersonalList/groupPersonalListSelectors";
import groupPersonalDeleteSelectors from "../../redux/groupPersonalDelete/groupPersonalDeleteSelectors";
import ConfirmEmailInfoComponent from "../../components/confirmEmailInfo/ConfirmEmailInfoComponent";

export default function GroupsPersonalPage() {
  const groupPersonalListLoading = useSelector(
    groupPersonalListSelectors.groupPersonalListLoading,
  );
  const groupPersonalDeleteComplete = useSelector(
    groupPersonalDeleteSelectors.groupPersonalDeleteComplete,
  );

  useEffect(() => {
    groupPersonalListApi();

    return () => {
      store.dispatch(clearGroupList());
    };
  }, []);

  useEffect(() => {
    if (groupPersonalDeleteComplete === true) {
      groupPersonalListApi();
    }
  }, [groupPersonalDeleteComplete]);

  return (
    <>
      <CssBaseline />
      <ConfirmEmailInfoComponent />
      <AppHeadLine />
      <Container maxWidth="md">
        <TitleLineComponent title={TITLE_PAGE_GROUPS_PERSONAL} />
        {groupPersonalListLoading ? <Loader /> : <PersonalGroupsContainer />}
      </Container>
      <Footer />
    </>
  );
}
