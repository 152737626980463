import learnGroupPersonalCheckRequest from "./learnGroupPersonalCheckRequest";

export default function learnGroupPersonalCheckData(
  learningWordGroupPersonalId: number,
  learningWordId: number,
  learningWordTranslateId: number,
): learnGroupPersonalCheckRequest {
  return {
    learningWordGroupPersonalId: learningWordGroupPersonalId,
    learningWordId: learningWordId,
    learningWordTranslateId: learningWordTranslateId,
  };
}
