import React from "react";
import { Paper } from "@mui/material";
import Loader from "../loader/Loader";

export default function LoadingComponent() {
  return (
    <Paper
      sx={{
        position: "absolute",
        top: "50px",
        width: "100%",
        zIndex: 1,
        p: "5px 0",
      }}
    >
      <Loader />
    </Paper>
  );
}
