import { storeInterface } from "../store";

const learnGroupPersonalNextAccess = (state: storeInterface) =>
  state.learnGroupPersonalNext.learnGroupPersonalNextAccess;
const learnGroupPersonalNext = (state: storeInterface) =>
  state.learnGroupPersonalNext.learnGroupPersonalNext;
const learnGroupPersonalNextLoading = (state: storeInterface) =>
  state.learnGroupPersonalNext.learnGroupPersonalNextLoading;

export default {
  learnGroupPersonalNextAccess,
  learnGroupPersonalNext,
  learnGroupPersonalNextLoading,
};
