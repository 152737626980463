import { storeInterface } from "../store";

const learnNextAccess = (state: storeInterface) =>
  state.learnNext.learnNextAccess;
const learnNext = (state: storeInterface) => state.learnNext.learnNext;
const learnNextLoading = (state: storeInterface) =>
  state.learnNext.learnNextLoading;

export default {
  learnNextAccess,
  learnNext,
  learnNextLoading,
};
