import React, { useEffect, useState } from "react";
import { Box, Divider, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import groupPersonalCreateData from "../../api/groupPersonalCreate/groupPersonalCreateData";
import groupPersonalCreateApi from "../../api/groupPersonalCreate/groupPersonalCreateApi";
import { LoadingButton } from "@mui/lab";
import groupPersonalCreateSelectors from "../../redux/groupPersonalCreate/groupPersonalCreateSelectors";
import { clearGroupPersonalCreateDispatch } from "../../redux/groupPersonalCreate/groupPersonalCreateDispatcher";
import { useNavigate } from "react-router-dom";
import { routeGroupsPersonalEditNavigate } from "../../constants/routesConstants";
import GroupPersonalWordListComponent from "../../components/layouts/groupPersonalEdit/GroupPersonalWordListComponent";
import { learningWordIdsFromWordList } from "../../services/learningWordIds/learningWordIdsService";
import actionWordGroupSelectors from "../../redux/actionWordGroup/actionWordGroupSelectors";
import { clearActionWordGroupDispatch } from "../../redux/actionWordGroup/actionWordGroupDispatcher";
import GroupActionFillWordListFromTextButton from "../../components/groupActionFillWirdListFromText/GroupActionFillWordListFromTextButton";
import {
  alertCloseDispatch,
  alertErrorDispatch,
} from "../../redux/alert/alertDispatcher";
import {
  alertEmptyTitlePersonalGroup,
  alertEmptyWordPersonalGroup,
} from "../../constants/alertConstants";

export default function GroupPersonalAdd() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const wordList = useSelector(actionWordGroupSelectors.wordList);

  const groupPersonalCreateLoading = useSelector(
    groupPersonalCreateSelectors.groupPersonalCreateLoading,
  );
  const learningWordGroupPersonalId = useSelector(
    groupPersonalCreateSelectors.learningWordGroupPersonalId,
  );

  useEffect(() => {
    return () => {
      clearGroupPersonalCreateDispatch();
      clearActionWordGroupDispatch();
    };
  }, []);

  useEffect(() => {
    if (learningWordGroupPersonalId !== null) {
      navigate(
        routeGroupsPersonalEditNavigate(String(learningWordGroupPersonalId)),
      );
    }
  }, [learningWordGroupPersonalId]);

  function createHandle() {
    alertCloseDispatch();
    // const learningWordIds = learningWordIdsFromWordList(wordList);

    if (title.length === 0) {
      alertErrorDispatch(alertEmptyTitlePersonalGroup);
      return;
    }

    // if (learningWordIds.length === 0) {
    //   alertErrorDispatch(alertEmptyWordPersonalGroup);
    //   return;
    // }

    const data = groupPersonalCreateData(title, []);

    groupPersonalCreateApi(data);
  }

  return (
    <>
      <Box>
        <Divider textAlign="left">{"Название словаря"}</Divider>
        <Box sx={{ mt: 1, mb: 3 }}>
          <TextField
            value={title}
            onChange={(e: any) => setTitle(e.target.value)}
            variant="outlined"
            sx={{ width: "100%" }}
            size="small"
            disabled={groupPersonalCreateLoading}
          />
        </Box>
      </Box>
      {/* <Box>
        <Divider textAlign="left">{"Состав"}</Divider>
      </Box>
      <Box sx={{ pt: 1 }}>
        <GroupActionFillWordListFromTextButton />
      </Box>
      <Box>
        <GroupPersonalWordListComponent disabled={groupPersonalCreateLoading} />
      </Box> */}
      <Box sx={{ p: "25px 0 0", display: "flex", justifyContent: "center" }}>
        <LoadingButton
          loading={groupPersonalCreateLoading}
          onClick={createHandle}
          variant="contained"
        >
          {"Создать словарь"}
        </LoadingButton>
      </Box>
    </>
  );
}
