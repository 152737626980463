import { createSlice } from "@reduxjs/toolkit";
import chooseValueWord from "../../models/chooseValueWord";

export interface learnNextWordStoreInterface {
  nextLearningWord: chooseValueWord | null;
  nextLearningWordLoading: boolean;
  nextLearningWordAccess: boolean;
}

const initStore: learnNextWordStoreInterface = {
  nextLearningWord: null,
  nextLearningWordLoading: true,
  nextLearningWordAccess: true,
};

export const learnNextWordReduceSlice = createSlice({
  name: "learnNextWordReduce",
  initialState: initStore,
  reducers: {
    clearLearnNextWord: (state: learnNextWordStoreInterface) => {
      state.nextLearningWord = initStore.nextLearningWord;
      state.nextLearningWordLoading = initStore.nextLearningWordLoading;
      state.nextLearningWordAccess = initStore.nextLearningWordAccess;
    },
    setNextLearningWordAccessTrue: (state: learnNextWordStoreInterface) => {
      state.nextLearningWordAccess = true;
    },
    setNextLearningWordAccessFalse: (state: learnNextWordStoreInterface) => {
      state.nextLearningWordAccess = false;
    },
    setNextLearningWord: (
      state: learnNextWordStoreInterface,
      actions: { payload: chooseValueWord; type: string },
    ) => {
      state.nextLearningWord = actions.payload;
    },
    nextLearningWordLoadingTrue: (state: learnNextWordStoreInterface) => {
      state.nextLearningWordLoading = true;
    },
    nextLearningWordLoadingFalse: (state: learnNextWordStoreInterface) => {
      state.nextLearningWordLoading = false;
    },
  },
});

export const {
  clearLearnNextWord,
  setNextLearningWordAccessTrue,
  setNextLearningWordAccessFalse,
  setNextLearningWord,
  nextLearningWordLoadingTrue,
  nextLearningWordLoadingFalse,
} = learnNextWordReduceSlice.actions;

export default learnNextWordReduceSlice.reducer;
