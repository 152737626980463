import { updatePasswordApi } from "../../api/updatePassword/updatePasswordApi";
import updatePasswordData from "../../api/updatePassword/updatePasswordData";
import {
  isPasswordIncorrectFormat,
  isPasswordNotSame,
  isCurrentPasswordValueEmpty,
  isPasswordRepeatValueEmpty,
  isPasswordValueEmpty,
} from "../formCheckService/formCheckService";
import { clearFormError } from "../formErrors/formErrorsService";

export const updatePasswordHandle = (
  currentPassword: string,
  password: string,
  passwordRepeat: string,
) => {
  clearFormError();

  if (isCurrentPasswordValueEmpty(currentPassword)) {
    return;
  }

  if (isPasswordValueEmpty(password)) {
    return;
  }

  if (isPasswordRepeatValueEmpty(passwordRepeat)) {
    return;
  }

  if (isPasswordIncorrectFormat(password)) {
    return;
  }

  if (isPasswordNotSame(password, passwordRepeat)) {
    return;
  }

  const data = updatePasswordData(currentPassword, password);
  updatePasswordApi(data);
};
