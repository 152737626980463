import { storeInterface } from "../store";

const groupPersonalListLoading = (state: storeInterface) =>
  state.groupPersonalList.groupPersonalListLoading;
const learningWordGroupPersonals = (state: storeInterface) =>
  state.groupPersonalList.learningWordGroupPersonals;

export default {
  groupPersonalListLoading,
  learningWordGroupPersonals,
};
