import { createSlice } from "@reduxjs/toolkit";
import learningWordGroupPartition from "../../models/learningWordGroupPartition";

export interface learningWordGroupPartitionsStoreInterface {
  learningWordGroupPartitions: learningWordGroupPartition[];
  learningWordGroupPartitionsLoading: boolean;
}

export const initLearningWordGroupPartitions: learningWordGroupPartitionsStoreInterface =
  {
    learningWordGroupPartitions: [],
    learningWordGroupPartitionsLoading: true,
  };

export const learningWordGroupPartitionsReduceSlice = createSlice({
  name: "learnStartReduce",
  initialState: initLearningWordGroupPartitions,
  reducers: {
    clearLearningWordGroupPartitions: (
      state: learningWordGroupPartitionsStoreInterface,
    ) => {
      state.learningWordGroupPartitions = [];
      state.learningWordGroupPartitionsLoading = true;
    },
    setLearningWordGroupPartitions: (
      state: learningWordGroupPartitionsStoreInterface,
      action: {
        type: string;
        payload: learningWordGroupPartition[];
      },
    ) => {
      state.learningWordGroupPartitions = action.payload;
    },
    setLearningWordGroupPartitionsLoadingTrue: (
      state: learningWordGroupPartitionsStoreInterface,
    ) => {
      state.learningWordGroupPartitionsLoading = true;
    },
    setLearningWordGroupPartitionsLoadingFalse: (
      state: learningWordGroupPartitionsStoreInterface,
    ) => {
      state.learningWordGroupPartitionsLoading = false;
    },
  },
});

export const {
  clearLearningWordGroupPartitions,
  setLearningWordGroupPartitions,
  setLearningWordGroupPartitionsLoadingTrue,
  setLearningWordGroupPartitionsLoadingFalse,
} = learningWordGroupPartitionsReduceSlice.actions;

export default learningWordGroupPartitionsReduceSlice.reducer;
