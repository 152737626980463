import {
  CODE_NOT_SAME,
  CODE_REQUIRE,
  CODE_REQUIRE_ADDITIONAL_AGREEMENTS,
  CODE_STRING_REGEX,
  CODE_STRING_REGEX_PASSWORD,
} from "../../constants/errorCodes";
import {
  emailFormErrorHandle,
  nameFormErrorHandle,
  passwordFormErrorHandle,
  currentPasswordFormErrorHandle,
  passwordRepeatFormErrorHandle,
  userAgreementCheckboxFormErrorHandle,
  privacyPolicyCheckboxFormErrorHandle,
  titleFormErrorHandle,
} from "../formErrors/formErrorsService";

export const isNameValueEmpty = (value: string) => {
  return valueEmptyHandle(value, () => {
    nameFormErrorHandle([CODE_REQUIRE]);
  });
};

export const isEmailValueEmpty = (value: string) => {
  return valueEmptyHandle(value, () => {
    emailFormErrorHandle([CODE_REQUIRE]);
  });
};

export const isCurrentPasswordValueEmpty = (value: string) => {
  return valueEmptyHandle(value, () => {
    currentPasswordFormErrorHandle([CODE_REQUIRE]);
  });
};

export const isPasswordValueEmpty = (value: string) => {
  return valueEmptyHandle(value, () => {
    passwordFormErrorHandle([CODE_REQUIRE]);
  });
};

export const isPasswordRepeatValueEmpty = (value: string) => {
  return valueEmptyHandle(value, () => {
    passwordRepeatFormErrorHandle([CODE_REQUIRE]);
  });
};

export const isUserAgreementCheckboxValueEmpty = (value: boolean) => {
  return valueCheckboxEmptyHandle(value, () => {
    userAgreementCheckboxFormErrorHandle([CODE_REQUIRE_ADDITIONAL_AGREEMENTS]);
  });
};

export const isPrivacyPolicyCheckboxValueEmpty = (value: boolean) => {
  return valueCheckboxEmptyHandle(value, () => {
    privacyPolicyCheckboxFormErrorHandle([CODE_REQUIRE_ADDITIONAL_AGREEMENTS]);
  });
};

export const isTitleValueEmpty = (value: string) => {
  return valueEmptyHandle(value, () => {
    titleFormErrorHandle([CODE_REQUIRE]);
  });
};

const valueEmptyHandle = (value: string, handler: CallableFunction) => {
  if (isEmpty(value) === false) {
    return false;
  }

  handler();

  return true;
};

const valueCheckboxEmptyHandle = (
  value: boolean,
  handler: CallableFunction,
) => {
  if (value === true) {
    return false;
  }

  handler();

  return true;
};

const isEmpty = (value: string) => {
  return value.length === 0;
};

export const isPasswordNotSame = (password: string, passwordRepeat: string) => {
  if (isNotSameHandle(password, passwordRepeat) === false) {
    return false;
  }

  passwordRepeatFormErrorHandle([CODE_NOT_SAME]);

  return true;
};

const isNotSameHandle: (valueOne: string, valueTwo: string) => boolean = (
  valueOne: string,
  valueTwo: string,
) => {
  return valueOne !== valueTwo;
};

export const isNameIncorrectFormat: (name: string) => boolean = (
  name: string,
) => {
  const nameRegex = /^.*(?=.{3,20})[A-zА-я0-9-]+$/;
  return isIncorrectFormat(name, nameRegex, () => {
    nameFormErrorHandle([CODE_STRING_REGEX]);
  });
};

export const isEmailIncorrectFormat: (email: string) => boolean = (
  email: string,
) => {
  const emailRegex =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  return isIncorrectFormat(email, emailRegex, () => {
    emailFormErrorHandle([CODE_STRING_REGEX]);
  });
};

export const isPasswordIncorrectFormat: (name: string) => boolean = (
  name: string,
) => {
  const passwordRegex = /^.*(?=.{6,}).*$/;
  // /^.*(?=.{6,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\x])(?=.*[!$#%]).*$/;
  return isIncorrectFormat(name, passwordRegex, () => {
    passwordFormErrorHandle([CODE_STRING_REGEX], [CODE_STRING_REGEX_PASSWORD]);
  });
};

const isIncorrectFormat = (
  value: string,
  regExp: RegExp,
  handler: CallableFunction,
) => {
  if (isNotRegex(value, regExp) === false) {
    return false;
  }

  handler();

  return true;
};

const isNotRegex = (str: string, regExp: RegExp) => {
  return str.search(regExp) === -1;
};
