import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import wordListItem from "../../../models/wordListItem";

interface wordListPropsInterface {
  items: wordListItem[];
  setItems: CallableFunction;
  disabled: boolean;
}

export default function WordsListComponent(props: wordListPropsInterface) {
  const removeWord = (index: number) => {
    const newItems = Array.from(props.items);

    // newItems[index] = {
    //   ...newItems[index],
    //   remove: !newItems[index].remove,
    // };

    props.setItems(
      newItems.filter((wordListItem: wordListItem, key: number) => {
        return key !== index;
      }),
    );
  };

  function removeAll() {
    props.setItems([]);
  }

  const tableCellStyle = (index: number) => {
    return props.items[index].remove
      ? {
          textDecoration: "line-through",
          color: "#9c9c9c",
          backgroundColor: "#f2f2f2",
        }
      : {};
  };

  function OnlyTable() {
    return (
      <>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" width={"120px"}>
                <Button
                  onClick={() => {
                    removeAll();
                  }}
                >
                  {"Очистить"}
                </Button>
              </TableCell>
              <TableCell width={"30px"}>{"№"}</TableCell>
              <TableCell align="left">{"Слово"}</TableCell>
              <TableCell align="left">{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((item: wordListItem, index: number) => (
              <TableRow key={index} sx={tableCellStyle(index)}>
                <TableCell align="center">
                  <Button
                    onClick={() => {
                      removeWord(index);
                    }}
                  >
                    {item.remove ? "Вернуть" : "Убрать"}
                  </Button>
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell align="left">{item.learningWord.word}</TableCell>
                <TableCell align="center">
                  {item.learningWord.public === 1 ? (
                    ""
                  ) : (
                    <Tooltip
                      title="Слово станет доступным после подтверждения администратором"
                      placement="top-end"
                      enterTouchDelay={0}
                    >
                      <WarningAmberIcon sx={{ color: "error.main" }} />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "500px" }}>
          <OnlyTable />
        </TableContainer>
      </Paper>
    </>
  );
}
