export const alertErrorBalance = "Недостаточно баллов на балансе";
export const alertLearnStart = "Словарь преобретён";
export const alertLearnNotTimeNewWord = "Новое слово недоступно";
export const alertLearnNotNextNewWord = "Вы изучили все слова словаря";
export const alertAppError = "Ошибка приложения";
export const alertSuccessUpdate = "Обновлено успешно!";
export const alertFormInputError = "Ошибка ввода данных";
export const alertInTimeUpdate = "Ошибка при сохранении";
export const alertInTimeCreate = "Ошибка при создании";
export const alertEmptyWordPersonalGroup = "Словарь не может быть пустым";
export const alertEmptyTitlePersonalGroup = "Укажите заголовок";
export const alertWordCanNotCreated = "Данное слово нельзя добавить";
