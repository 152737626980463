import signInRequest from "./signInRequest";

export default function signIdData(
  email: string,
  password: string,
): signInRequest {
  return {
    email: email,
    password: password,
  };
}
