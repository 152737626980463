import { createSlice } from "@reduxjs/toolkit";
import newLearningWord from "../../models/newLearningWord";

export interface learnGroupPersonalCheckStoreInterface {
  newLearningWord: newLearningWord | null;
  learnGroupPersonalCheckLoading: boolean;
  learnGroupPersonalCheckAccess: boolean;
  success: boolean | null;
  complete: boolean;
  progress: number;
}

const initStore: learnGroupPersonalCheckStoreInterface = {
  newLearningWord: null,
  learnGroupPersonalCheckLoading: false,
  learnGroupPersonalCheckAccess: true,
  success: null,
  complete: false,
  progress: 0,
};

export const learnGroupPersonalCheckReduceSlice = createSlice({
  name: "learnGroupPersonalCheckReduce",
  initialState: initStore,
  reducers: {
    clearLearnGroupPersonalCheck: (
      state: learnGroupPersonalCheckStoreInterface,
    ) => {
      state.newLearningWord = initStore.newLearningWord;
      state.learnGroupPersonalCheckLoading =
        initStore.learnGroupPersonalCheckLoading;
      state.learnGroupPersonalCheckAccess =
        initStore.learnGroupPersonalCheckAccess;
      state.success = initStore.success;
      state.complete = initStore.complete;
      state.progress = initStore.progress;
    },
    setLearnGroupPersonalCheckAccessTrue: (
      state: learnGroupPersonalCheckStoreInterface,
    ) => {
      state.learnGroupPersonalCheckAccess = true;
    },
    setLearnGroupPersonalCheckAccessFalse: (
      state: learnGroupPersonalCheckStoreInterface,
    ) => {
      state.learnGroupPersonalCheckAccess = false;
    },
    setNewLearningWord: (
      state: learnGroupPersonalCheckStoreInterface,
      actions: { payload: newLearningWord | null; type: string },
    ) => {
      state.newLearningWord = actions.payload;
    },
    setLearnGroupPersonalCheckLoadingTrue: (
      state: learnGroupPersonalCheckStoreInterface,
    ) => {
      state.learnGroupPersonalCheckLoading = true;
    },
    setLearnGroupPersonalCheckLoadingFalse: (
      state: learnGroupPersonalCheckStoreInterface,
    ) => {
      state.learnGroupPersonalCheckLoading = false;
    },
    setSuccessNull: (state: learnGroupPersonalCheckStoreInterface) => {
      state.success = null;
    },
    setSuccess: (
      state: learnGroupPersonalCheckStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.success = actions.payload;
    },
    setComplete: (
      state: learnGroupPersonalCheckStoreInterface,
      actions: { payload: boolean; type: string },
    ) => {
      state.complete = actions.payload;
    },
    setProgress: (
      state: learnGroupPersonalCheckStoreInterface,
      actions: { payload: number; type: string },
    ) => {
      state.progress = actions.payload;
    },
  },
});

export const {
  clearLearnGroupPersonalCheck,
  setLearnGroupPersonalCheckAccessTrue,
  setLearnGroupPersonalCheckAccessFalse,
  setNewLearningWord,
  setLearnGroupPersonalCheckLoadingTrue,
  setLearnGroupPersonalCheckLoadingFalse,
  setSuccessNull,
  setSuccess,
  setComplete,
  setProgress,
} = learnGroupPersonalCheckReduceSlice.actions;

export default learnGroupPersonalCheckReduceSlice.reducer;
