import {
  Alert,
  Box,
  Button,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  storageSetUseCookiesTrue,
  storageUseCookies,
} from "../../helpers/localStorageHelper";

export default function UseCookiesComponent() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (storageUseCookies === "") {
      setVisible(true);
    }
  }, []);

  function handleAccept() {
    storageSetUseCookiesTrue();
    setVisible(false);
  }

  return (
    <>
      {visible === true ? (
        <Box
          sx={{
            position: "fixed",
            bottom: 15,
            left: 0,
            width: "100%",
            // backgroundColor: "primary.contrastText",
            backgroundColor: "primary.dark",
            padding: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            color: "primary.contrastText",
          }}
        >
          <Typography variant="body2">
            {"Мы используем файлы cookie."}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={handleAccept}
          >
            {"Принять"}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
